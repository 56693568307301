export default {
  baseStyle: {
    container: {
      borderRadius: 'full',
    },
  },
  sizes: {
    xs: {
      container: {
        px: 2,
        minHeight: 5,
        minWidth: 5,
        fontSize: '12px',
      },
      label: {
        fontSize: '12px',
      },
    },
    sm: {
      container: {},
      label: {},
    },
    md: {
      container: {
        px: 3,
      },
      label: {
        fontSize: '12px',
      },
    },
    lg: {
      label: {
        fontSize: '12px',
      },
    },
  },
  variants: {
    solid: {
      container: {
        bgColor: 'primary.500',
      },
      label: {
        color: 'white',
      },
    },
    subtle: {
      container: {
        bgColor: 'primary.100',
      },
      label: {
        color: 'primary.700',
      },
    },
    promo: {
      container: {
        bgColor: 'promo.500',
      },
      label: {
        color: 'primary.700',
        fontSize: '14px',
      },
    },
    error: {
      container: {
        bgColor: 'error.100',
      },
    },
    imageCta: {
      container: {
        bgColor: 'primary.500',
        '&:nth-of-type(even)': {
          '& > span': {
            color: 'primary.500',
          },
          bgColor: 'white',
        },
        px: 4,
        minWidth: '5em',
        justifyContent: 'center',
      },
      label: {
        color: 'white',
      },
    },
    multiSelect: {
      container: {
        py: '0',
        px: 3,
        bg: 'primary.100',
        borderRadius: 'md',
        height: 8,
        svg: {
          transform: 'scale(0.8)',
          color: 'primary.500',
        },
      },
      label: {
        color: 'primary.500',
        fontSize: 'sm',
        fontWeight: 'semibold',
        lineHeight: 'shorter',
      },
    },
  },

  defaultProps: {
    variant: 'solid',
  },
};
