import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import circleEmptySvg from '../../icons/circleEmptySvg';
import circleFillSvg from '../../icons/circleFillSvg';
import circleHollowSvg from '../../icons/circleHollowSvg';

const svgRating = {
  w: '10px',
  h: '10px',
  mr: '6px',
  backgroundSize: 'contain',
};

export default {
  parts: ['ratingEmpty', 'ratingHalf', 'ratingFill'],
  baseStyle: {
    ratingEmpty: {
      ...svgRating,
      backgroundImage: svgUrl(circleEmptySvg(colors.primary[100])),
    },
    ratingHalf: {
      ...svgRating,
      backgroundImage: svgUrl(circleFillSvg(colors.primary[100])),
    },
    ratingFill: {
      ...svgRating,
      backgroundImage: svgUrl(circleFillSvg(colors.accent[500])),
    },
    tripAdvisorFill: {
      ...svgRating,
      backgroundImage: svgUrl(circleFillSvg(colors.tripAdvisorGreen)),
    },
    tripAdvisorEmpty: {
      ...svgRating,
      backgroundImage: svgUrl(circleHollowSvg(colors.tripAdvisorGreen)),
    },
    tripAdvisorHalf: {
      ...svgRating,
      backgroundImage: svgUrl(circleFillSvg(colors.tripAdvisorGreen)),
    },
  },
};
