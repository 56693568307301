import { SUB_PAGES } from 'inspiretec-booking-journey-ui/components/NavigationController/NavigationController';
import { NextRouter } from 'next/router';
import { ROUTES_BASE_PATH } from '../constants/routes';
import useConfig, { Config } from '../queries/content/useConfig';

export const getRouteNoQueryString = (route?: string) => {
  let routeNoQueryString = route?.split('?').shift() || '';
  // detect navigation to reserved booking routes, if a match resolve paths to the single
  // /booking route, this is so we have a single route that we can target in the CMS
  // other routes like /booking/get-my-quote are not reserved and can be individually
  // overridden in the CMS.

  const reservedRoutes = [
    SUB_PAGES.searchResults.routeName,
    SUB_PAGES.details.routeName,
    SUB_PAGES.extras.routeName,
    SUB_PAGES.infoPayment.routeName,
    SUB_PAGES.confirmation.routeName,
  ];
  // we need to replace - with \- to ensure correct regex syntax.
  const regString = `^/booking/[${reservedRoutes.map((s) => s.replace(/-/gi, '\\-')).join('|')}]`;
  if (new RegExp(regString).test(routeNoQueryString)) {
    routeNoQueryString = '/booking';
  }

  // Home has to be a single trailing slash and we now consistently enforce all urls to end in a
  // trailing slash and the cacheKey
  if (routeNoQueryString.charAt(routeNoQueryString.length - 1) !== '/') {
    routeNoQueryString += '/';
  }

  return routeNoQueryString;
};

export const getRouteFromCmsUrl = (cmsUrl: string): string => {
  return cmsUrl.replace(ROUTES_BASE_PATH, '');
};

export const getCmsUrlFromRoute = (route: string): string => {
  return `${ROUTES_BASE_PATH}/${route}`;
};

export function firstLetterAsCaps(name = '') {
  const words = name.replace('-', ' ').split(' ');

  return words
    .map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      }
      return '';
    })
    .join(' ');
}

export function formatPrettyPageTitle(url = '/') {
  const parts: string[] = url.split('/');
  if (parts && parts.length > 0) {
    const lastPartMinusAnyQuery = (parts?.pop() as string).split('?')[0];
    return firstLetterAsCaps(lastPartMinusAnyQuery);
  }

  return '';
}

interface TitleQueryParams {
  productKey?: string;
  destination?: string;
}

function getCustomCMSTitleBasedOnRoute(router: NextRouter, bookingConfigData: Config) {
  if (!router || !bookingConfigData) {
    return null;
  }

  const firstRouteSegment = router.query?.routeSegments && router.query?.routeSegments[0];

  switch (firstRouteSegment) {
    case SUB_PAGES.searchResults.routeName:
      return bookingConfigData.pageSearchResults?.title;

    case SUB_PAGES.details.routeName:
      return bookingConfigData.pageDetails?.title;

    case SUB_PAGES.extras.routeName:
      return bookingConfigData.pageExtras?.title;

    case SUB_PAGES.infoPayment.routeName:
      return bookingConfigData.pagePayment?.title;

    case SUB_PAGES.confirmation.routeName:
      return bookingConfigData.pageConfirmation?.title;

    default:
      return null;
  }
}

function constructDynamicTitle(
  productName: string | undefined,
  router: NextRouter,
  bookingConfigData: Config,
  destinationLocationOnly: string | undefined
): string {
  const titleParts: string[] = [];

  const customTitleFromCms = getCustomCMSTitleBasedOnRoute(router, bookingConfigData);

  // Add productName, custom cms title, and desintation to the array if they exist
  if (productName) titleParts.push(productName);
  if (customTitleFromCms) titleParts.push(customTitleFromCms ?? '');
  if (destinationLocationOnly) titleParts.push(destinationLocationOnly);

  // Join the parts with a dash if there are multiple parts, otherwise return the single part
  return titleParts.join(' - ');
}

export const getBrowserTitle = (
  router: NextRouter,
  siteName: string,
  nonBookingJourneyCmsPageBrowserTitle: string
): string => {
  // BOOKING JOURNEY
  const { productKey, destination } = router.query
    ? (router.query as TitleQueryParams)
    : { productKey: undefined, destination: undefined };

  const { data: bookingConfigData } = useConfig();

  if (bookingConfigData) {
    const productName = bookingConfigData?.products?.find(
      (product) => product.key === productKey
    )?.title;

    const destinationLocationOnly = destination?.split('.')[0];

    const dynamicTitle = constructDynamicTitle(
      productName,
      router,
      bookingConfigData,
      destinationLocationOnly
    );

    if (dynamicTitle) {
      return dynamicTitle;
    }
  }

  // ALL OTHER PAGES
  return nonBookingJourneyCmsPageBrowserTitle || siteName;
};
