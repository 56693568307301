import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import alertSvg from '../../icons/alertSvg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'alertIcon',
  'alertIconValidation',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    alertIcon: {
      bg: svgUrl(alertSvg(colors.primary[500])),
    },
    alertIconValidation: {
      w: 4,
      h: 4,
      bg: svgUrl(alertSvg(colors.error[500])),
    },
  },
});
