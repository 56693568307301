import { CarouselVariant } from '../../../../components/CarouselBase/constants';

const largeArrowButtonWidth = '48px';
const largeArrowSpacingGap = `calc(${largeArrowButtonWidth} / 2)`;
const ArrowSpacingGap = `calc(${largeArrowButtonWidth} + ${largeArrowSpacingGap})`;

// Hero
const heroTextLeftSpacingMd = `calc(30px + ${ArrowSpacingGap})`;
const heroTextLeftSpacingLg = `calc(70px + ${ArrowSpacingGap})`;
const heroTextLeftSpacingXl = `calc(${ArrowSpacingGap})`;

//ImageCTA
const imageCtaTextRightSpacingMd = `calc(${ArrowSpacingGap} + 15px)`;

export default {
  parts: [
    'wrapper',
    'buttonsWrapper',
    'leftButton',
    'rightButton',
    'text',
    'dotsWrapper',
    'dots',
    'ctaButton',
  ],
  baseStyle: {
    wrapper: {},
    buttonsWrapper: {},
    leftButton: {},
    rightButton: {},
    text: {},
    dotsWrapper: {},
    dots: {},
    ctaButton: {},
  },
  variants: {
    [CarouselVariant.IMAGE]: {
      leftButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: 'calc(100% - 20px)',
        left: '20px',
        transform: 'translateY(-100%)',
      },
      rightButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: 'calc(100% - 20px)',
        left: '120px',
        transform: 'translateY(-100%) rotate(180deg)',
      },
      text: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: 'calc(100% - 49px)',
        left: '74px',
        transform: 'translateY(0px) translateX(-0px)',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      },
      dots: {
        display: { base: 'flex', md: 'none' },
        position: 'absolute',
        bottom: '16px',
        left: '20px',
        color: 'white',
      },
    },
    [CarouselVariant.IMAGE_CTA]: {
      leftButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: { md: 'calc(100% - 30px)', lg: 'calc(100% - 30px)', xl: '50%' },
        left: { md: 'auto', xl: '2rem' },
        right: {
          md: `calc(${imageCtaTextRightSpacingMd} + ${largeArrowButtonWidth} + 7.5px)`,
          xl: '30px',
        },
        transform: 'translateY(-100%)',
      },
      rightButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: { md: 'calc(100% - 30px)', lg: 'calc(100% - 30px)', xl: '50%' },
        right: '30px',
        transform: 'translateY(-100%) rotate(180deg)',
      },
      text: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: { md: 'calc(100% - 78px)', xl: '50%' },
        right: { md: imageCtaTextRightSpacingMd, xl: '30px' },
        left: 'auto',
        transform: 'translateY(15px) translateX(-7px)',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      },
      dotsWrapper: {
        paddingTop: '76%', // 4/5 aspect ratio * 100 = 80% but compensated for -4% for margin
        position: 'absolute',
        top: '0',
        left: '0',
      },
      dots: {
        display: { base: 'flex', md: 'none' },
        position: 'absolute',
        left: '20px',
        bottom: '16px',
        color: 'white',
      },
      ctaButton: {
        bg: 'transparent',
        px: 1,
        ml: '-0.25rem !important', // need to override the VStack child margin
        borderRadius: 'sm',
        color: 'secondary.500',
        _hover: {
          bg: 'secondary.50',
        },
      },
    },
    [CarouselVariant.HERO_FULL]: {
      buttonsWrapper: {
        maxWidth: { base: 'inherit', xl: '1260px', '2xl': 'inherit' },
        margin: 'auto',
        position: { base: 'static', xl: 'relative', '2xl': 'static' },
      },

      leftButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: { md: 'calc(100% - 35px)', '2xl': '60%' },
        left: { md: '30px', lg: '70px', xl: '0px', '2xl': '1.875rem' },
        transform: 'translateY(-100%)',
      },
      rightButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: { md: 'calc(100% - 35px)', '2xl': '60%' },
        right: { md: 'auto', '2xl': '1.875rem' },
        left: {
          md: `calc(${heroTextLeftSpacingMd} + ${largeArrowButtonWidth})`,
          lg: `calc(${heroTextLeftSpacingLg} + ${largeArrowButtonWidth})`,
          xl: `calc(${heroTextLeftSpacingXl} + ${largeArrowButtonWidth})`,
          '2xl': 'auto',
        },
        transform: 'translateY(-100%) rotate(180deg)',
      },
      text: {
        display: { base: 'none', md: 'block' },
        fontSize: { base: '18px', '2xl': '20px' },
        position: 'absolute',
        top: { md: 'calc(100% - 40px)', '2xl': '60%' },
        right: { md: 'auto', '2xl': '1.7rem' },
        left: {
          md: heroTextLeftSpacingMd,
          lg: heroTextLeftSpacingLg,
          xl: heroTextLeftSpacingXl,
          '2xl': 'auto',
        },
        transform: {
          md: 'translateY(-28px) translateX(-5px)',
          '2xl': 'translateY(14px) translateX(-6px)',
        },
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      },
      dots: {
        display: { base: 'flex', md: 'none' },
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        color: 'white',
      },
    },
    [CarouselVariant.HERO_SHORT]: {
      buttonsWrapper: {
        maxWidth: 'inherit',
        margin: 'auto',
        position: 'static',
      },
      leftButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: { md: 'calc(100% - 35px)', xl: '60%' },
        left: { md: '30px', lg: '70px', xl: '1.875rem' },
        transform: 'translateY(-100%)',
      },
      rightButton: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        top: { md: 'calc(100% - 35px)', xl: '60%' },
        right: { md: 'auto', xl: '1.875rem' },
        left: {
          md: `calc(${heroTextLeftSpacingMd} + ${largeArrowButtonWidth})`,
          lg: `calc(${heroTextLeftSpacingLg} + ${largeArrowButtonWidth})`,
          xl: 'auto',
        },
        transform: 'translateY(-100%) rotate(180deg)',
      },
      text: {
        display: { base: 'none', md: 'block' },
        position: 'absolute',
        fontSize: { base: '18px', '2xl': '20px' },
        top: { md: 'calc(100% - 40px)', xl: '60%' },
        right: { md: 'auto', xl: '1.7rem' },
        left: {
          md: heroTextLeftSpacingMd,
          lg: heroTextLeftSpacingLg,
          xl: 'auto',
        },
        transform: {
          md: 'translateY(-28px) translateX(-5px)',
          xl: 'translateY(14px) translateX(-6px)',
        },
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      },
      dots: {
        display: { base: 'flex', md: 'none' },
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        color: 'white',
      },
    },
    [CarouselVariant.GENERIC_CTA]: {
      wrapper: {
        mx: '-10px',
      },
      buttonsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: { base: '10px', md: '30px' },
      },
      leftButton: {
        display: { base: 'none', lg: 'inline' },
      },
      rightButton: {
        display: { base: 'none', lg: 'inline' },
        transform: 'rotate(180deg)',
      },
      text: {
        display: 'none',
      },
      dotsWrapper: {
        mb: { base: 5, lg: 0 },
      },
      dots: {
        display: 'flex',
        color: 'black',
        px: '35px',
      },
    },
    [CarouselVariant.CATEGORY_CTA]: {
      wrapper: {
        mx: '-10px',
      },
      buttonsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: { base: '10px', md: '30px' },
      },
      leftButton: {
        display: { base: 'none', lg: 'inline' },
      },
      rightButton: {
        display: { base: 'none', lg: 'inline' },
        transform: 'rotate(180deg)',
      },
      text: {
        display: 'none',
      },
      dotsWrapper: {
        mb: { base: 5, lg: 0 },
      },
      dots: {
        display: 'flex',
        color: 'black',
        px: '35px',
      },
    },
  },
};
