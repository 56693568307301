export const above = (breakpoint: string, content: object) => ({
  [`@media screen and (min-width: ${breakpoint})`]: {
    ...content,
  },
});

export const below = (breakpoint: string, content: object) => ({
  [`@media screen and (max-width: ${parseInt(breakpoint) - 1}px)`]: {
    ...content,
  },
});

export const between = (breakpointFrom: string, breakpointTo: string, content: object) => ({
  [`@media screen and (min-width: ${breakpointFrom}) and (max-width: ${
    parseInt(breakpointTo) - 1
  }px)`]: {
    ...content,
  },
});
