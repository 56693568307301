import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowDown from '../../icons/arrowDown';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(['arrowIcon']);

export default defineMultiStyleConfig({
  baseStyle: {
    arrowIcon: {
      aspectRatio: '5 / 3',
      w: '0.7rem',
      backgroundImage: svgUrl(arrowDown(colors.primary[500])),
    },
  },
});
