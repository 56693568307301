import * as Sentry from '@sentry/nextjs';
import axios, { AxiosError } from 'axios';
import { getSessionId } from 'inspiretec-booking-journey-ui/utils/cookies';
import getConfig from 'next/config';
import { host } from '../constants/express';
import environmentVariables from '../environment-variables';

const { port } = environmentVariables(process.env.ENVIRONMENT);

export const getBookingApi = () => {
  let api = `http://${host}:${port}/api-booking`; // TODO make server api DRY, default in here for storybook
  if (getConfig()) {
    const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
    api = serverRuntimeConfig.apiBookingUiUrl || publicRuntimeConfig.apiBookingUiUrl;
  }
  return api;
};

export const getContentApi = () => {
  let api = `http://${host}:${port}/api-content`; // TODO make server api DRY, default in here for storybook
  if (getConfig()) {
    const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
    api = serverRuntimeConfig.apiContentUiUrl || publicRuntimeConfig.apiContentUiUrl;
  }

  return api;
};

export const getUrl = () => {
  let url = `http://${host}:${port}`; // TODO make server api DRY, default in here for storybook
  if (getConfig()) {
    const { serverRuntimeConfig } = getConfig();
    url = serverRuntimeConfig.url || `${location.protocol}//${location.host}`;
  }

  return url;
};

/**
 * This is our generic Axios get handler that is used directly by the server and but the client
 * via Next.js for the UI's API needs
 *
 * @param url String
 * @returns AxiosResponse
 */
export const axiosGet = async (url: string) => {
  let errorString = '';
  let statusCode: number | undefined = 200;
  const response = await axios.get(url).catch((err: AxiosError) => {
    errorString = `Error axiosGet url:${url} status:${err.response?.status}`;
    statusCode = err.response?.status;
    Sentry.withScope((scope) => {
      const sessionId = getSessionId();
      if (sessionId) scope.setTag('sessionId', sessionId);
      Sentry.captureException(errorString);
    });
    console.error(errorString);
  });
  // We really want to throw an Error if the API responds with a 500 or above, this will redirect
  // to the 500 page.
  if (errorString && statusCode && statusCode >= 500) {
    throw Error(errorString);
  }
  return response;
};
