import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../utils/graphics';
import alertSvg from '../../icons/alertSvg';

export default {
  parts: ['content', 'arrow', 'body'],
  baseStyle: {
    content: {
      width: 'auto',
      bg: 'error.100',
      _focus: { boxShadow: 'none', outline: 'none' },
      borderColor: 'error.100',
    },
    arrow: {
      bg: ({ colors }: StyleFunctionProps) => `${colors.error[100]} !important`,
      borderColor: 'error.100',
      border: 'none',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 'sm',
      textAlign: 'center',
      lineHeight: 1.5,
      _before: {
        content: '""',
        display: 'block',
        w: 4,
        h: 4,
        bg: ({ colors }: StyleFunctionProps) => svgUrl(alertSvg(colors.error['500'])),
        mb: 2,
      },
    },
  },
};
