import { ComponentStyleConfig } from '@chakra-ui/react';
import { searchButton, searchIcon } from '../Search';
import SearchControl from '../SearchControl';

const SearchDynamicPackage: ComponentStyleConfig = {
  parts: [
    'outer',
    'inner',
    'searchIcon',
    'searchButton',
    'departure',
    'destination',
    'date',
    'duration',
    'guests',
    'bookingJourneyMobileFooter',
  ],
  baseStyle: {
    outer: {},
    inner: {
      flexWrap: 'wrap',
      // Offset to remove/ignore left whitespace from Search Form so text and underline are flush
      // with outside edge, this allows us to use a consistent padding value in outer.
      ml: -4,
    },
    departure: {
      flexBasis: { base: '100%', md: '50%', xl: '21%' },
    },
    destination: {
      flexBasis: { base: '100%', md: '50%', xl: '21%' },
    },
    date: {
      flexBasis: { base: '50%', md: '33%', xl: '12%' },
    },
    duration: {
      flexBasis: { base: '50%', md: '33%', xl: '14%' },
    },
    guests: {
      flexBasis: { base: '100%', md: '33%', xl: '19%' },
    },
    searchIcon,
    searchButton: {
      ...searchButton,
      flexBasis: { base: '100%', md: '50%', lg: '33%', xl: '12%' },
    },
  },
  variants: {
    bookingJourney: {
      outer: {
        display: 'flex',
        flexDirection: 'column',
        p: { base: 4, lg: 10, xl: 0 },
        pb: { base: 4, lg: 6, xl: 0 }, // less because of footer nav
        pl: { base: 4, lg: 10, xl: 0 },
        h: '100%',
        bg: 'white',
      },
      bookingJourneyMobileFooter: SearchControl.baseStyle?.footer,
    },
  },
};

export default SearchDynamicPackage;
