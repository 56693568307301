import { ResponsiveObject } from '@chakra-ui/react';
import { BaseBreakpointConfig } from '@chakra-ui/theme-tools';

export const sortBreakpoints = (baseBreakpointConfig: BaseBreakpointConfig) => {
  return {
    base: baseBreakpointConfig.base,
    sm: baseBreakpointConfig.sm,
    md: baseBreakpointConfig.md,
    lg: baseBreakpointConfig.lg,
    xl: baseBreakpointConfig.xl,
    '2xl': baseBreakpointConfig['2xl'],
  };
};

export const createPseudoBackgroundImageElement = (stylesObject: any) => {
  return {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '1em',

    ...stylesObject,
  };
};

export const updateResponsiveValue = (value: ResponsiveObject<string>, modifier: number) => {
  const updatedResponsiveValue = { ...value };

  Object.keys(updatedResponsiveValue).forEach((key) => {
    updatedResponsiveValue[key] = `${parseInt(updatedResponsiveValue[key] as string) + modifier}px`;
  });

  return updatedResponsiveValue;
};

const socialIconMap = {
  facebook: { svgUrl: 'var(--global-facebook-icon)', alt: 'Facebook' },
  twitter: { svgUrl: 'var(--global-twitter-icon)', alt: 'Twitter' },
  linkedin: { svgUrl: 'var(--global-linkedin-icon)', alt: 'LinkedIn' },
  instagram: { svgUrl: 'var(--global-instagram-icon)', alt: 'Instagram' },
  youtube: { svgUrl: 'var(--global-youtube-icon)', alt: 'YouTube' },
};

type SocialIconKey = keyof typeof socialIconMap;

export const getSocialIcon = (iconName: string) => {
  return socialIconMap[iconName as SocialIconKey];
};

export default null;
