import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import chevronDownSvg from '../../icons/chevronDownSvg';
import userSvg from '../../icons/userSvg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'actionLink',
  'agentStatus',
  'expandIcon',
  'module',
  'userIcon',
  'text',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    actionLink: {
      color: 'var(--global-contact-bar-color)',
    },
    text: { fontWeight: 600 },
    agentStatus: {
      borderColor: 'var(--global-contact-bar-color)',
    },
    expandIcon: {
      bg: ({ colors }: Record<string, StyleFunctionProps>) =>
        svgUrl(chevronDownSvg(colors.primary[500])),
    },
    module: {
      bg: 'var(--global-contact-bar-background-color)',
      color: 'var(--global-contact-bar-color)',
    },
    userIcon: {
      aspectRatio: '11 / 14',
      h: '14px',
      bg: ({ colors }: Record<string, StyleFunctionProps>) => svgUrl(userSvg(colors.primary[500])),
    },
  },
  variants: {
    transparent: {},
    opaque: {},
  },
});
