import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { QueryClient } from 'react-query';
import { setSessionIdInNewRelic } from '../../utils/newrelic';

interface ApplyAppBootstrapProps {
  children: (data: ApplyAppBootstrapChildProps) => JSX.Element;
}

interface ApplyAppBootstrapChildProps {
  showReactQueryDevTools: boolean;
  queryClient: QueryClient;
  featureIsB2b: boolean;
}

const ApplyAppBootstrap = ({ children }: ApplyAppBootstrapProps) => {
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const [showReactQueryDevTools, setShowReactQueryDevTools] = useState(false);
  const sessionIdRef = useRef('');
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    setShowReactQueryDevTools(true);
    setSessionIdInNewRelic(sessionIdRef);
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      window.gtag?.('config', publicRuntimeConfig.gaToken, {
        page_path: url,
      });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return children({
    featureIsB2b: publicRuntimeConfig.featureIsB2b,
    queryClient,
    showReactQueryDevTools,
  });
};

export default ApplyAppBootstrap;
