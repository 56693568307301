/**
 * The Drawer is designed to be consistent across the application, and with the similar Modal
 * component.
 */
// https://chakra-ui.com/docs/components/drawer/theming
import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  closeButton: {
    top: { base: 5, md: 4 },
    right: { base: 5, md: 4 },
  },
  header: {
    px: { base: 5, md: 7 },
    py: { base: 7, md: 7 },
  },
  body: {
    p: { base: 5, md: 7 },
  },
  footer: {
    px: { base: 5, md: 7 },
    py: 4,
    borderTopWidth: '1px',
  },
});

export default defineMultiStyleConfig({
  baseStyle,
});
