import { GlobalStyleProps } from '@chakra-ui/theme-tools';
import { above, below } from '../../../utils/mediaQueries';

const font = {
  fontFamily: 'MuseoSansRounded',
  fontWeight: 'normal',
  color: 'var(--global-form-label-color)',
};

const btn = {
  display: 'inline-flex',
  appearance: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  position: 'relative',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  outline: 'transparent solid 2px',
  outlineOffset: '2px',
  lineHeight: '1.2',
  borderRadius: '5px',
  fontWeight: 'semibold',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  height: '12',
  minWidth: '12',
  fontSize: '15px',
  paddingInlineStart: '6',
  paddingInlineEnd: '6',
  cursor: 'pointer',

  _focus: {
    boxShadow: 'outline',
  },
};

const field = {
  width: 'full',
  minWidth: '0px',
  outline: 'transparent solid 2px',
  outlineOffset: '2px',
  position: 'relative',
  appearance: 'none',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  fontSize: 'lg',
  paddingInlineStart: '4',
  paddingInlineEnd: '4',
  height: '12',
  borderRadius: 'md',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderImage: 'initial',
  borderColor: 'transparent',
  backgroundColor: 'gray.100',

  _hover: {
    backgroundColor: 'gray.200',
  },

  _focus: {
    background: 'transparent',
    borderColor: 'gray.700',
  },
};

const grid = (props: GlobalStyleProps) => {
  let gridObj = {
    '.umbraco-forms-row': {
      display: 'flex',
      flexFlow: 'row wrap',

      ...above(props.theme.breakpoints.lg, {
        marginLeft: '-4',
        marginRight: '-4',
      }),

      ...below(props.theme.breakpoints.md, {
        flexFlow: 'column',
        marginLeft: '0',
        marginRight: '0',
      }),
    },
  };

  for (let i = 1; i <= 12; i++) {
    gridObj = {
      ...gridObj,
      [`.umbraco-forms-col-${i}`]: {
        flex: '0 0 auto',
        width: `${(i / 12) * 100}%`,
        paddingLeft: '4',
        paddingRight: '4',

        ...below(props.theme.breakpoints.md, {
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
        }),
      },
    };
  }

  return gridObj;
};

export default (props: GlobalStyleProps) => ({
  '.umbraco-forms-form': {
    ...grid(props),
    'h1, h2, h3, h4, h5, h6': {
      ...font,
      fontWeight: 'semibold',
      fontStyle: 'normal',
    },
    h1: {
      fontSize: '4xl',
      lineHeight: '10',
    },
    h2: {
      fontSize: '3xl',
      lineHeight: '9',
    },
    h3: {
      fontSize: '2xl',
      lineHeight: '8',
    },
    h4: {
      fontSize: 'xl',
      lineHeight: '7',
    },
    h5: {
      fontSize: 'lg',
      lineHeight: '6',
    },
    h6: {
      fontSize: 'md',
      lineHeight: '5',
    },
    '.umbraco-forms-caption': {
      ...font,
      fontSize: '2xl',
      marginBottom: '5',
      lineHeight: '1.875em',
    },
    '.umbraco-forms-fieldset-caption': {
      ...font,
      fontSize: 'lg',
      marginBottom: '6',
      lineHeight: '1.75em',
    },
    '.umbraco-forms-label': {
      ...font,
      marginBottom: '2',
      lineHeight: '1.1875em',
      display: 'inline-block',
      color: 'var(--global-form-label-color)',
    },
    '.umbraco-forms-tooltip': {
      ...font,
      fontSize: 'xs',
      marginLeft: '1',

      '.umbraco-forms-icon': {
        verticalAlign: 'text-top',
      },
    },
    '.umbraco-forms-text': {
      ...font,
      lineHeight: '7',
    },
    '.umbraco-forms-icon': {
      height: '1em',
      width: '1em',
      color: 'currentcolor',
      display: 'inline-block',
    },
    '.umbraco-forms-field': {
      marginBottom: '4',
    },
    '.umbraco-forms-textbox': field,
    '.umbraco-forms-textarea': {
      ...field,
      paddingTop: '2',
      paddingBottom: '2',
      minHeight: '20',
      lineHeight: 'short',
    },
    '.umbraco-forms-dropdown-wrapper': {
      position: 'relative',
      width: 'full',
      height: 'fit-content',

      '.umbraco-forms-dropdown': {
        ...field,
        paddingInlineEnd: '8',
        lineHeight: 'normal',
      },
      '.umbraco-forms-dropdown-icon': {
        width: '6',
        height: '100%',
        right: '2',
        color: 'currentcolor',
        fontSize: 'xl',
        position: 'absolute',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
    '.umbraco-forms-checkboxlist .umbraco-forms-checkbox-wrapper, .umbraco-forms-radiobuttonlist .umbraco-forms-radiobutton-wrapper':
      {
        marginBottom: '1',
      },
    '.umbraco-forms-checkbox-wrapper, .umbraco-forms-radiobutton-wrapper': {
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      verticalAlign: 'top',
      position: 'relative',

      '.umbraco-forms-text': {
        marginInlineStart: '2',
        userSelect: 'none',
        fontSize: 'lg',
      },
    },
    '.umbraco-forms-checkbox, .umbraco-forms-radiobutton': {
      position: 'absolute',
      opacity: '0',
      height: '0',
      width: '0',
    },
    '.umbraco-forms-checkbox-icon, .umbraco-forms-radiobutton-icon': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'top',
      flexShrink: '0',
      width: '6',
      height: '6',
      transitionProperty: 'box-shadow',
      transitionDuration: 'normal',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderImage: 'initial',
      borderColor: 'inherit',
      color: 'white',
    },
    '.umbraco-forms-checkbox-icon': {
      userSelect: 'none',
      borderRadius: 'sm',
      verticalAlign: 'top',

      '.umbraco-forms-icon': {
        fill: 'none',
        strokeWidth: '2',
        stroke: 'currentcolor',
        strokeDasharray: '16',
        fontSize: 'xs',
        transitionProperty: 'transform',
        transitionDuration: 'normal',
        width: '1.2em',
      },
    },
    '.umbraco-forms-checkbox:checked ~ .umbraco-forms-checkbox-icon, .umbraco-forms-radiobutton:checked ~ .umbraco-forms-radiobutton-icon':
      {
        background: `var(--global-form-label-color)`,
        borderColor: 'var(--global-form-label-color)',
        color: 'white',

        _hover: {
          background: 'var(--global-background-color-hover)',
          borderColor: 'var(--global-background-color-hover)',
        },

        _focusVisible: {
          boxShadow: 'outline',
        },
      },
    '.umbraco-forms-radiobutton-icon': {
      borderRadius: 'full',
    },
    '.umbraco-forms-radiobutton:checked ~ .umbraco-forms-radiobutton-icon': {
      _before: {
        content: '""',
        display: 'inline-block',
        position: 'relative',
        width: '50%',
        height: '50%',
        borderRadius: '50%',
        background: 'currentcolor',
      },
    },
    '.umbraco-forms-fileupload': {
      ...field,
      lineHeight: '38px',

      _focus: {
        background: 'gray.100',
        borderColor: 'transparent',
      },
    },
    '.umbraco-forms-fieldset': {
      marginBottom: '12',
    },
    '.umbraco-forms-btn': {
      ...btn,
      background: 'var(--global-background-color)',
      color: 'white',

      _hover: {
        background: `var(--global-background-color-hover)`,
      },
    },
    '.umbraco-forms-btn-outline': {
      ...btn,
      border: '1px solid currentColor',
      color: 'var(--global-background-color-hover)',
      background: 'transparent',

      _hover: {
        background: 'var(--global-background-color-hover)',
        color: 'white',
      },

      _active: {
        background: 'var(--global-button-active-color)',
      },
    },
    '.input-validation-error': {
      borderColor: 'error.500',
    },
    '.field-validation-error': {
      ...font,
      color: 'error.500',
      fontSize: 'sm',
      marginTop: '1',
      display: 'flex',
      alignItems: 'center',
      width: 'full',
    },
    '.grecaptcha-notice': {
      ...font,
      fontSize: 'sm',
    },
  },
});
