import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { StatusCodes } from 'http-status-codes';
import { getSessionId } from 'inspiretec-booking-journey-ui/utils/cookies';
import getConfig from 'next/config';
import Router from 'next/router';
import React from 'react';
import useBrochurewareDictionary from '../../api/Dictionary/useBrochurewareDictionary';
import { LOGIN_ROUTE } from '../../constants/routes';

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  labels: any;
  errorBoundaryTheme: any;
  error?: Error | undefined;
  componentStack?: string;
  resetError?: () => void;
}

const ClientErrorBoundary = ({
  labels,
  errorBoundaryTheme,
  error,
  componentStack,
  resetError,
}: ErrorBoundaryProps) => {
  const { publicRuntimeConfig } = getConfig();

  const devEnvironment = publicRuntimeConfig.environment === 'local';
  return (
    <Box p={4} className="ErrorBoundary">
      <Alert status="error" variant="subtle" flexDirection="column" alignItems="flex-start">
        <HStack mb={devEnvironment ? 3 : 0} spacing={4}>
          <AlertIcon boxSize="20px" m={0} mb="2px" />
          <AlertTitle fontSize="md" sx={errorBoundaryTheme.alertTitle}>
            {labels('brokenText')}
          </AlertTitle>
          <Button
            onClick={() => Router.push('/')}
            variant={errorBoundaryTheme.redirectButtonVariant}
            sx={errorBoundaryTheme.redirectButtonOverride}
          >
            {labels('redirectButtonText')}
          </Button>
          {resetError && (
            <Button
              onClick={resetError}
              variant={errorBoundaryTheme.redirectButtonVariant}
              sx={errorBoundaryTheme.redirectButtonOverride}
            >
              {labels('resetButtonText')}
            </Button>
          )}
        </HStack>
        {devEnvironment && (
          <AlertDescription w="100%">
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {error && error.toString()}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{componentStack}</AccordionPanel>
              </AccordionItem>
            </Accordion>
          </AlertDescription>
        )}
      </Alert>
    </Box>
  );
};

interface AppErrorBoundaryProps {
  children: React.ReactNode;
}

/**
 * This component is only used at a top level to handle an error on the client above that of the
 * standard component ErrorBoundary protection. Here we send on to the 500 error page whilst
 * capturing error information via Sentry.
 */
export function AppErrorBoundary(props: AppErrorBoundaryProps) {
  function onFallBackError() {
    window.location.href = '/500/';
  }

  return (
    <>
      <Sentry.ErrorBoundary fallback={<div ref={onFallBackError}></div>} showDialog={false}>
        {props.children}
      </Sentry.ErrorBoundary>
    </>
  );
}

/**
 * This is a higher order component that wraps a component with an error boundary. It will catch any
 * errors that occur in the child component and display an error message to the user.
 *
 * @param WrappedComponent The component to wrap with the error boundary
 */

export function withErrorBoundary<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component.
  const WithErrorBoundary = (props: React.ComponentProps<typeof WrappedComponent>) => {
    const { labels } = useBrochurewareDictionary('ErrorBoundary');
    const errorBoundaryTheme = useMultiStyleConfig('ErrorBoundary', { baseStyles: true });
    const { publicRuntimeConfig } = getConfig();
    const devEnvironment = publicRuntimeConfig.environment === 'local';

    return (
      <Sentry.ErrorBoundary
        onError={(error) => {
          if (
            publicRuntimeConfig?.featureIsB2b &&
            error?.message === StatusCodes.UNAUTHORIZED.toString()
          ) {
            Router.push(`${LOGIN_ROUTE}?returnUrl=${encodeURIComponent(Router.asPath)}`);
          }
        }}
        beforeCapture={(scope) => {
          scope.setTag('componentName', displayName);
          scope.setTag('sessionId', getSessionId());
        }}
        fallback={({ error, componentStack, resetError }) => (
          <ClientErrorBoundary
            labels={labels}
            errorBoundaryTheme={errorBoundaryTheme}
            error={error}
            componentStack={componentStack}
            // not using in production at the moment as I've seen issues when component is reset
            // but the router path is not reset within the booking journey.
            resetError={devEnvironment ? resetError : undefined}
          />
        )}
        showDialog={false}
      >
        <WrappedComponent {...(props as T)} />
      </Sentry.ErrorBoundary>
    );
  };

  WithErrorBoundary.displayName = `withErrorBoundary(${displayName})`;

  return WithErrorBoundary;
}
