import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';

export default {
  parts: [],
  baseStyle: {
    viewMoreBtn: {
      color: 'primary.500',
    },
    collapseIcon: {
      h: '5px',
      w: '8px',
      backgroundImage: svgUrl(arrowUp(colors.gray[700])),
    },
    expandIcon: {
      h: '5px',
      w: '8px',
      backgroundImage: svgUrl(arrowDown(colors.gray[700])),
    },
  },
};
