import { extendTheme, withDefaultProps } from '@chakra-ui/react';
import { GlobalStyleProps } from '@chakra-ui/theme-tools';
import {
  documentClassCategory,
  feature,
  parseAsHtmlDocumentClass,
} from '../../utils/documentClasses';
import { svgUrl } from '../../utils/graphics';
// Brochureware Components
import AlternativeContentMedia from './brochureware/AlternativeContentMedia';
import Breadcrumb from './brochureware/Breadcrumb';
import CarouselBase from './brochureware/CarouselBase';
import CategoryCtaCarouselItem from './brochureware/CategoryCtaCarouselItem';
import ContactBar from './brochureware/ContactBar';
import ContentBlock from './brochureware/ContentBlock';
import coopHolidaysLogoSvg from './brochureware/coopHolidaysLogoSvg';
import ErrorPage from './brochureware/ErrorPage';
import ExpandingContentList from './brochureware/ExpandingContentList';
import Footer from './brochureware/Footer';
import GenericCtaCarousel from './brochureware/GenericCtaCarousel';
import Header from './brochureware/Header';
import HeroBanner from './brochureware/HeroBanner';
import HeroCarouselItem from './brochureware/HeroCarouselItem';
import Login from './brochureware/Login';
import Navigation from './brochureware/Navigation';
import PaginationArrow from './brochureware/PaginationArrow';
import TabbedContentBlock from './brochureware/TabbedContentBlock';
import Video from './brochureware/Video';
import yourCoopTravelLogo from './brochureware/yourCoopTravelLogoSvg';
// Booking Journey Chakra Ui Components
import Accordion from './chakra/Accordion';
import Button from './chakra/Button';
import Checkbox from './chakra/Checkbox';
import CloseButton from './chakra/CloseButton';
import Drawer from './chakra/Drawer';
import Heading from './chakra/Heading';
import Input from './chakra/Input';
import Link from './chakra/Link';
import Menu from './chakra/Menu';
import Modal from './chakra/Modal';
import Popover from './chakra/Popover/Popover';
import Select from './chakra/Select';
import Tag from './chakra/Tag';
import Text from './chakra/Text';
// Custom Components
import AccommodationAnchorNavigation from './components/AccommodationDetails/AccommodationAnchorNavigation';
import AccommodationFacilities from './components/AccommodationDetails/AccommodationFacilities';
import AccommodationGallery from './components/AccommodationDetails/AccommodationGallery';
import AccommodationHeader from './components/AccommodationDetails/AccommodationHeader';
import AccommodationOverview from './components/AccommodationDetails/AccommodationOverview';
import AddRemove from './components/AddRemove';
import AddRemoveGuests from './components/AddRemoveGuests';
import AgentCommission from './components/AgentCommission';
import AlertDialog from './components/AlertDialog';
import BookingFooter from './components/BookingFooter';
import CheckboxGroup from './components/CheckboxGroup';
import ConfirmationPage from './components/ConfirmationPage';
import BaggageIncludedInformation from './components/Core/BaggageIncludedInformation';
import FormErrorValidation from './components/Core/FormErrorValidation';
import PriceStat from './components/Core/PriceStat';
import SelectedCheckWithLabel from './components/Core/SelectedCheckWithLabel';
import DetailsPage from './components/DetailsPage';
import Errata from './components/Errata';
import ErrorBoundary from './components/ErrorBoundary';
import ExcursionCard from './components/ExcursionCard';
import ExpandedSelect from './components/ExpandedSelect';
import FilterAccordion from './components/FilterAccordion';
import FilterDrawerFooter from './components/FilterDrawerFooter';
import FilterPopover from './components/FilterPopover';
import FlightCard from './components/FlightCard';
import FlightOptionsList from './components/FlightOptionsList';
import GenericPanel from './components/GenericPanel';
import GuestDetails from './components/GuestDetails';
import LoadingSpinner from './components/Loading/LoadingSpinner';
import MiniQuote from './components/MiniQuote';
import MissingPicture from './components/MissingPicture';
import PaymentFooter from './components/PaymentFooter';
import PaymentModule from './components/PaymentModule';
import PreviouslyPopulated from './components/PreviouslyPopulated';
import ProductSearch from './components/ProductSearch';
import Progress from './components/Progress';
import QuoteHeader from './components/QuoteHeader';
import QuoteReSearch from './components/QuoteReSearch';
import QuoteTimeout from './components/QuoteTimeout';
import RangeSliderInput from './components/RangeSliderInput';
import Rating from './components/Rating';
import RecentSearch from './components/RecentSearch';
import RoomAndBoard from './components/RoomAndBoard';
import RoomAndBoardRoom from './components/RoomAndBoard/RoomAndBoardRoom';
import Search from './components/Search';
import SearchControl from './components/SearchControl';
import SearchControlError from './components/SearchControlError';
import SearchDate from './components/SearchDate';
import DatePicker from './components/SearchDate/DatePicker';
import SearchDuration from './components/SearchDuration';
import SearchDynamicPackage from './components/SearchDynamicPackage';
import SearchFilters from './components/SearchFilters';
import SearchLocation from './components/SearchLocation';
import SearchAutoCompleteResult from './components/SearchLocation/SearchAutoCompleteResult';
import SearchLocationPreview from './components/SearchLocation/SearchLocationPreview';
import SearchNoResults from './components/SearchNoResults';
import SearchPopover from './components/SearchPopover';
import SearchResult from './components/SearchResult';
import SearchResults from './components/SearchResults';
import SearchResultsMap from './components/SearchResultsMap';
import SearchRoomGuest from './components/SearchRoomGuest';
import SearchTabs from './components/SearchTabs';
import SearchUnderlay from './components/SearchUnderlay';
import SearchWait from './components/SearchWait';
import Stepper from './components/Stepper';
import StickyPanel from './components/StickyPanel';
import FilterButton from './components/StickyPanel/FilterButton';
import GridMapSelector from './components/StickyPanel/GridMapSelector';
import SearchResultsSort from './components/StickyPanel/SearchResultsSort';
import SupplementCard from './components/SupplementCard';
import TextWithViewMoreOrLess from './components/TextWithViewMoreOrLess';
import TransferCard from './components/TransferCard';
// Config
import breakpoints from './config/breakpoints';
import colors from './config/colors';
import globals from './config/globals';
import Typography, { headingFontColor } from './config/typography';
// Icons
import alertSvg from './icons/alertSvg';
import chevronRightSvg from './icons/chevronRightSvg';
import homeSvg from './icons/homeSvg';
import infoFillTooltipSvg from './icons/infoFillTooltipSvg';
import facebookSvg from './icons/Social/facebookSvg';
import instagramSvg from './icons/Social/instagramSvg';
import linkedinSvg from './icons/Social/linkedinSvg';
import twitterSvg from './icons/Social/twitterSvg';
import youtubeSvg from './icons/Social/youtubeSvg';
import tickInCircle from './icons/tickInCircle';
// Pikaday
import pikadayStyles from './pikaday';
// Classes
import RichText from './RichText';
// Umbraco Forms
import umbracoFormsStyles from './umbraco-forms';

// Export Theme Font
export { default as font } from './font';

// Export Page Theme
// Do not import into files from here - import from index.ts in theme folder instead
export const PageTheme = {
  COOP_HOLIDAYS: 'coopHolidays',
  COOP_TRAVEL: 'coopTravel',
  // Add more themes as client requests
} as const;

const coopTravelPageThemeClassName = parseAsHtmlDocumentClass(
  PageTheme.COOP_TRAVEL,
  documentClassCategory.PAGE_THEME
);

const featureIsB2bClassName = parseAsHtmlDocumentClass(
  feature.IS_B2B,
  documentClassCategory.FEATURE
);

// Extend Theme
const theme = extendTheme(
  {
    breakpoints,
    styles: {
      global: (props: GlobalStyleProps) => ({
        ':root': {
          '--global-breadcrumb-home-icon': svgUrl(homeSvg(colors.primary[500])),
          '--global-heading-font-color': colors.primary[500],
          '--global-background-color': colors.primary[500],
          '--global-background-color-hover': colors.primary[600],
          '--global-logo': svgUrl(coopHolidaysLogoSvg('white')),
          '--global-logo-grey': svgUrl(coopHolidaysLogoSvg(colors.secondary[500])),
          '--global-logo-drawer': svgUrl(coopHolidaysLogoSvg(colors.primary[500])),
          '--global-logo-login': svgUrl(coopHolidaysLogoSvg(colors.primary[500])),
          '--global-facebook-icon': svgUrl(facebookSvg(colors.primary[500])),
          '--global-twitter-icon': svgUrl(twitterSvg(colors.primary[500])),
          '--global-linkedin-icon': svgUrl(linkedinSvg(colors.primary[500])),
          '--global-instagram-icon': svgUrl(instagramSvg(colors.primary[500])),
          '--global-youtube-icon': svgUrl(youtubeSvg(colors.primary[500])),
          '--global-form-label-color': colors.primary[500],
          '--global-button-active-color': colors.primary[100],
          '--global-alert-icon': svgUrl(alertSvg(colors.primary[500])),
          '--global-tick-in-circle-icon': svgUrl(tickInCircle(colors.primary[500])),
          '--global-info-fill-tooltip-icon': svgUrl(infoFillTooltipSvg(colors.primary[500])),
          '--global-navigation-chevron-right-icon': svgUrl(chevronRightSvg(colors.primary[500])),
          '--global-thin-gray-line': `1px solid ${colors.secondary[200]}`,
          '--global-contact-bar-background-color': 'white',
          '--global-contact-bar-color': colors.secondary[500],
          [`&.${featureIsB2bClassName}`]: {
            '--global-contact-bar-background-color': colors.accent[500],
            '--global-contact-bar-color': colors.primary[500],
          },
          '--global-generic-carousel-cta-background-color': 'white',
          '--global-generic-carousel-cta-border': '2px solid var(--global-background-color)',
          [`&.${coopTravelPageThemeClassName}`]: {
            '--global-breadcrumb-home-icon': svgUrl(homeSvg(colors.secondary[500])),
            '--global-heading-font-color': colors.secondary[500],
            '--global-background-color': colors.secondary[500],
            '--global-background-color-hover': colors.secondary[600],
            '--global-logo': svgUrl(yourCoopTravelLogo('white')),
            '--global-logo-grey': svgUrl(yourCoopTravelLogo(colors.secondary[500])),
            '--global-logo-drawer': svgUrl(yourCoopTravelLogo(colors.secondary[500])),
            '--global-logo-login': svgUrl(yourCoopTravelLogo(colors.secondary[500])),
            '--global-facebook-icon': svgUrl(facebookSvg(colors.secondary[500])),
            '--global-twitter-icon': svgUrl(twitterSvg(colors.secondary[500])),
            '--global-linkedin-icon': svgUrl(linkedinSvg(colors.secondary[500])),
            '--global-instagram-icon': svgUrl(instagramSvg(colors.secondary[500])),
            '--global-youtube-icon': svgUrl(youtubeSvg(colors.secondary[500])),
            '--global-form-label-color': colors.secondary[500],
            '--global-button-active-color': colors.secondary[100],
            '--global-alert-icon': svgUrl(alertSvg(colors.secondary[500])),
            '--global-tick-in-circle-icon': svgUrl(tickInCircle(colors.primary[500])),
            '--global-info-fill-tooltip-icon': svgUrl(infoFillTooltipSvg(colors.primary[500])),
            '--global-navigation-chevron-right-icon': svgUrl(
              chevronRightSvg(colors.secondary[500])
            ),
            '--global-thin-gray-line': `1px solid ${colors.secondary[200]}`,
            '--global-contact-bar-background-color': colors.flameOrange,
            '--global-contact-bar-color': 'white',
            '--global-generic-carousel-cta-background-color': colors.paleBlue,
            '--global-generic-carousel-cta-border': 'none',
          },
        },
        // Gusset that appears when scroll lock in active, hiding anything slightly jutting into the scroll bar area
        '[data-scroll-lock]': {
          _before: {
            content: '""',
            position: 'absolute',
            right: 0,
            height: '100vh',
            width: 'var(--scrollbar-compensation)',
            zIndex: 'var(--chakra-zIndices-modal)',
            backgroundColor: 'white',
          },
        },
        '.chakra-toast .chakra-alert__icon': {
          alignSelf: 'center',
        },
        '.grecaptcha-badge': {
          visibility: 'hidden',
        },
        ...RichText,
        ...umbracoFormsStyles(props),
        ...pikadayStyles,
      }),
    },
    colors: {
      ...colors,
    },

    components: {
      // brochureware
      AlternativeContentMedia,
      Breadcrumb,
      CarouselBase,
      CategoryCtaCarouselItem,
      ContactBar,
      ContentBlock,
      ErrorPage,
      ExpandingContentList,
      Footer,
      GenericCtaCarousel,
      Header,
      HeroBanner,
      HeroCarouselItem,
      Login,
      Navigation,
      PaginationArrow,
      TabbedContentBlock,
      Video,
      // booking journey
      // Chakra Ui Components
      Accordion,
      Button,
      Checkbox,
      CloseButton,
      Drawer,
      Heading,
      Input,
      Link,
      Menu,
      Modal,
      Popover,
      Select,
      Tag,
      Text,
      // Custom Components
      AccommodationAnchorNavigation,
      AccommodationFacilities,
      AccommodationGallery,
      AccommodationHeader,
      AccommodationOverview,
      AddRemove,
      AddRemoveGuests,
      AgentCommission,
      AlertDialog,
      BaggageIncludedInformation,
      BookingFooter,
      CheckboxGroup,
      ConfirmationPage,
      DatePicker,
      DetailsPage,
      Errata,
      ErrorBoundary,
      ExpandedSelect,
      ExcursionCard,
      FilterAccordion,
      FilterButton,
      FilterDrawerFooter,
      FilterPopover,
      FlightCard,
      FlightOptionsList,
      FormErrorValidation,
      GenericPanel,
      GridMapSelector,
      GuestDetails,
      LoadingSpinner,
      MiniQuote,
      MissingPicture,
      PaymentFooter,
      PaymentModule,
      PreviouslyPopulated,
      PriceStat,
      ProductSearch,
      Progress,
      QuoteHeader,
      QuoteReSearch,
      QuoteTimeout,
      Rating,
      RangeSliderInput,
      RecentSearch,
      RoomAndBoard,
      RoomAndBoardRoom,
      Search,
      SearchAutoCompleteResult,
      SearchControl,
      SearchControlError,
      SearchDate,
      SearchFilters,
      SearchLocation,
      SearchLocationPreview,
      SearchDuration,
      SearchDynamicPackage,
      SearchNoResults,
      SearchPopover,
      SearchResult,
      SearchResults,
      SearchResultsMap,
      SearchResultsSort,
      SearchRoomGuest,
      SearchTabs,
      SearchUnderlay,
      SearchWait,
      SelectedCheckWithLabel,
      Stepper,
      StickyPanel,
      SupplementCard,
      TextWithViewMoreOrLess,
      TransferCard,
    },
    ...Typography,
    initialColorMode: 'light',
    useSystemColorMode: false,
    globals,
    radii: {
      none: '0',
      sm: '0.125rem',
      base: '0.25rem',
      md: '0.375rem', // 6px
      lg: '0.5rem', // 8px
      xl: '0.625rem', // 10px
      '2xl': '1rem', // 16px
      '3xl': '1.5rem',
      full: '9999px',
    },
    semanticTokens: {
      colors: {
        'chakra-heading-text': { _light: headingFontColor }, // (custom) --chakra-colors-chakra-heading-text
        'chakra-body-text': { _light: 'secondary.500' }, // --chakra-colors-chakra-body-text
        'chakra-body-bg': { _light: 'white' }, // --chakra-colors-chakra-body-bg
        'chakra-border-color': { _light: 'primary.100' }, // --chakra-colors-chakra-border-color
        'chakra-subtle-bg': { _light: 'gray.100' }, // --chakra-colors-chakra-subtle-bg
        'chakra-placeholder': { _light: 'gray.500' }, // --chakra-colors-chakra-placeholder
        'chakra-byway-primary': { _light: 'primary.500' }, // (custom) --chakra-colors-chakra-byway-primary
      },
      sizes: {
        'image-border-radius': 'var(--chakra-radii-xl)', // --chakra-sizes-chakra-border-radius
      },
    },
    shadows: {
      outline: `0 0 0 3px ${colors.focus}`,
      inner: `inset 0 0 0 3px ${colors.focus}`,
    },
    transitions: {
      searchExpand: 0.125,
      searchCollapse: 0.1,
      searchExpandFramerEase: 'easeOut',
      searchCollapseFramerEase: 'easeIn',
      searchExpandEase: 'ease-out',
      searchCollapseEase: 'ease-in',
    },
  },
  withDefaultProps({
    defaultProps: {
      variant: 'filled',
      size: 'sm',
    },
    components: ['Input'],
  }),
  withDefaultProps({
    defaultProps: {
      size: 'lg',
    },
    components: ['Select'],
  }),
  withDefaultProps({
    defaultProps: {
      colorScheme: 'primary',
    },
    components: ['Switch'],
  })
);

export default theme;
