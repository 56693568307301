export default {
  parts: ['alertIcon, errorText'],
  baseStyle: {
    alertIcon: {
      w: 6,
      h: 6,
      bg: 'var(--global-alert-icon)',
    },
    errorText: { fontSize: 'lg', fontWeight: 400, lineHeight: '28px', textAlign: 'center' },
  },
};
