/**
 * The Accordion is designed to be consistent across the application
 */
// https://v2.chakra-ui.com/docs/components/accordion/theming
import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  accordionAnatomy.keys
);

const baseStyle = definePartsStyle({
  icon: {
    color: 'primary.500',
  },
});

const variantOutline = definePartsStyle({
  // define the part you're going to style
  root: {},
  container: {
    borderWidth: 1, // the surrounding border,
  },
  button: {
    justifyContent: 'space-between', // ensure align icon to the right
  },
  icon: {
    alignSelf: 'flex-start',
  },
  panel: {
    borderTopWidth: 1,
  },
});

const variants = {
  outline: variantOutline,
};

const sizes = {
  lg: definePartsStyle({
    root: {},
    container: {
      borderRadius: 'lg',
    },
    button: {
      px: 5,
      py: { base: 4, xl: 5 },
    },
    icon: {
      w: { base: 7, md: 9 },
      h: { base: 7, md: 9 },
    },
    panel: {
      px: 5,
      py: 8,
    },
  }),
  md: definePartsStyle({
    container: {
      borderRadius: 'md',
    },
    button: {
      px: 4,
      py: 4,
    },
    icon: {
      w: 6,
      h: 6,
      mr: -1,
    },
    panel: {
      px: 4,
      py: 6,
    },
  }),
  sm: definePartsStyle({
    container: {
      borderRadius: 'md',
    },
    button: {
      px: 4,
      py: 2,
    },
    icon: {
      w: 5,
      h: 5,
      mr: -1,
    },
    panel: {
      px: 4,
      py: 4,
    },
  }),
};

export default defineMultiStyleConfig({ baseStyle, variants, sizes });
