/**
 * This dictionary hook is purely for Brochureware components to
 * access the content API and backfill labels from the CMS, Booking
 * Journey will have its counter part within BookingJourney.UI.
 * I've deliberately decided to keep separate for now, until a
 * suitable solution to make this more DRY presents itself.
 */

import { AxiosError } from 'axios';
import getConfig from 'next/config';
import { useCallback, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import getDictionary, { DICTIONARY_QUERY_KEY } from '../../queries/content/dictionary';
import defaultBrochurewareDictionary from './defaultBrochurewareDictionary';

/**
 * Umbraco structure should be similar to this
 * Websites
 * ├─[WEBSITE_NAME]
 *    ├─ Home
 *    ├─ Modules Data
 *    ├─ Data
 *       ├─ Labels
 *          ├─ Booking
 *          │  ├─ Components
 *          │     ├─ [COMPONENT-NAME]
 *          ├─ Brochureware // (this is our CATALOGUE_KEY)
 *          │  ├─ Components
 *          │     ├─ [COMPONENT-NAME]
 */

const CATALOGUE_KEY = 'Brochureware';

// we use this globally accessible dictionaryApiEndpoint in
// publicRuntimeConfig because we need this to be the same
// within the booking journey host to make leverage from caching.
const API_ENDPOINT = getConfig().publicRuntimeConfig?.dictionaryApiEndpoint;
if (!API_ENDPOINT) {
  throw Error(`Error: No dictionaryApiEndpoint specified within publicRuntimeConfig,
    expecting something like dictionary?culture=en-GB&isPreview=false`);
}

export interface DefaultDictionaryItem {
  [key: string]: {
    labels?: {
      [key: string]: string;
    };
    validationMessages?: {
      [key: string]: string;
    };
    errorMessages?: {
      [key: string]: string;
    };
  };
}

interface DictionaryProperty {
  [key: string]: string;
}

interface DictionaryItem {
  name: string;
  labels: DictionaryProperty;
  validationMessages: DictionaryProperty;
  errorMessages: DictionaryProperty;
  items: DictionaryItem[];
}

const useBookingDictionary = (componentKey: string) => {
  const warnHistoryRef = useRef<Map<string, boolean>>(new Map());
  const onError = (error: AxiosError) => {
    console.error(error);
  };

  const options = {
    staleTime: 1000 * 60 * 60 * 0.1, // 6 minutes
    onError,
  };

  const response = useQuery<DictionaryItem, AxiosError<any> | undefined>(
    DICTIONARY_QUERY_KEY,
    getDictionary,
    options as any
  );

  const { data, refetch, remove, ...responseProps } = response;

  const getComponentDictionaryItem = useCallback(() => {
    if (data) {
      const catalogueData = data.items?.find((item) => item.name === CATALOGUE_KEY);

      const componentsData = catalogueData?.items.find((item) => item.name === 'Components');
      if (componentsData) {
        const componentDictionaryItem: DictionaryItem | DefaultDictionaryItem | undefined =
          componentsData.items.find((item) => item.name === componentKey);

        if (componentDictionaryItem) {
          return componentDictionaryItem;
        }

        console.warn(`Dictionary: no content for component "${componentKey}"`);
        return defaultBrochurewareDictionary[componentKey];
      }
    }

    return undefined;
  }, [data, componentKey]);

  const checkToWarn = (str: string) => {
    // client only
    if (typeof window !== 'undefined' && !warnHistoryRef.current.has(str)) {
      console.warn(str);
      warnHistoryRef.current.set(str, true);
    }
  };

  const getDefaultLabel = (componentKey: string, key: string) => {
    checkToWarn(`Dictionary: no content for component "${componentKey}" with label "${key}"`);
    return defaultBrochurewareDictionary?.[componentKey]?.labels?.[key] || undefined;
  };

  const getDefaultValidation = (componentKey: string, key: string) => {
    checkToWarn(`Dictionary: no content for component "${componentKey}" with validation "${key}"`);
    return defaultBrochurewareDictionary?.[componentKey]?.validationMessages?.[key] || undefined;
  };

  const getDefaultErrors = (componentKey: string, key: string) => {
    checkToWarn(`Dictionary: no content for component "${componentKey}" with error "${key}"`);
    return defaultBrochurewareDictionary?.[componentKey]?.errorMessages?.[key] || undefined;
  };

  const allLabels = useMemo(
    () => (data ? getComponentDictionaryItem()?.labels || {} : {}),
    [data, getComponentDictionaryItem]
  );

  const allValidation = useMemo(
    () => (data ? getComponentDictionaryItem()?.validationMessages || {} : {}),
    [data, getComponentDictionaryItem]
  );

  const allErrors = useMemo(
    () => (data ? getComponentDictionaryItem()?.errorMessages || {} : {}),
    [data, getComponentDictionaryItem]
  );

  const getLabel = (key: string): string => {
    if (!data) {
      return '';
    }
    // Check if the key exists in 'allLabels'.
    if (key in allLabels) {
      // If 'allLabels[key]' has a value, including an empty string (which means BE has made this label optional), return it directly.
      return allLabels[key];
    }
      // If the key doesn't exist in 'allLabels', use 'getDefaultLabel'.
      const label = getDefaultLabel(componentKey, key);
      // If 'getDefaultLabel' provides a meaningful value, return it; otherwise, fallback to debugging placeholder.
      return label || `${componentKey}.${key}`;
  };

  const getValidation = (key: string) =>
    data
      ? allValidation[key] || getDefaultValidation(componentKey, key) || `${componentKey}.${key}`
      : '';

  const getErrors = (key: string) =>
    data ? allErrors[key] || getDefaultErrors(componentKey, key) || `${componentKey}.${key}` : '';

  return {
    labels: getLabel,
    validation: getValidation,
    errors: getErrors,
    ...responseProps,
  };
};

export default useBookingDictionary;
