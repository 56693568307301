import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import beachSvg from '../../icons/beachSvg';
import carSvg from '../../icons/carSvg';
import citySvg from '../../icons/citySvg';
import coachSvg from '../../icons/coachSvg';
import cruiseSvg from '../../icons/cruiseSvg';
import planeSvg from '../../icons/flightSvg';
import hotelSvg from '../../icons/hotelSvg';

const heightOfSearchButton = '50';

export default {
  parts: [
    'bookingJourneySearchTitle', // override only required for coop. Do not delete.
    'tabs',
    'form',
    'recentDesktop',
    'recentHandheld',
    'recentButton',
    'outer',
    'selectedIcon',
    'hotelIcon',
    'flightIcon',
    'cityIcon',
    'carIcon',
    'cruiseIcon',
    'coachIcon',
  ],
  baseStyle: {
    outer: {
      bg: 'white',
      p: { base: 4, md: 6, lg: 8 },
      pt: { base: 6, md: 6, lg: 6 },
      pb: { base: 2, md: 8, xl: 2 },
      spacing: 0,
      borderRadius: { base: 0, lg: 'xl' },
      boxShadow: ({ globals }: StyleFunctionProps) => globals.shadow,
      '.HeroBanner-placeholderAboveHero &': {
        boxShadow: { xl: 'none' },
      },
      '.HeroBanner &': {
        mt: 0,
        mb: 0,
        alignSelf: 'stretch',
      },
      '.HeroBannerCarousel &': {
        mt: -16, // as per designs to rise outer up over hero
        mb: 2,
      },
    },
    bookingJourneySearchTitle: {
      color: 'primary.500', // override only required for coop. Do not delete.
    },
    tabs: {
      pb: { base: 4, xl: 2 },
    },
    form: {},
    recentDesktop: {},
    recentHandheld: {
      position: 'relative',
    },
    recentButton: {
      position: { base: 'relative', md: 'absolute' },
      justifyContent: { base: 'flex-start', md: 'center' },
      right: 0,
      top: { base: 'auto', md: `-${heightOfSearchButton}px` },
      height: `${heightOfSearchButton}px`,
      color: 'primary.500',
    },
    selectedIcon: {
      filter: 'brightness(0) grayscale(1) invert(1)',
    },
    hotelIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(hotelSvg(colors.primary['500'])),
    },
    flightIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(planeSvg(colors.primary['500'])),
    },
    cityIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(citySvg(colors.primary['500'])),
    },
    carIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(carSvg(colors.primary['500'])),
    },
    cruiseIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(cruiseSvg(colors.primary['500'])),
    },
    coachIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(coachSvg(colors.primary['500'])),
    },
    beachIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(beachSvg(colors.primary['500'])),
    },
  },
};
