export default {
  parts: ['hotelAddress', 'hotelPrice, offerBadge'],
  baseStyle: {
    accommodationHeaderWrapper: {},
    accommodationHeaderVStack: {
      mt: { base: 4, md: 0 },
      pb: { base: 5, md: 0 },
    },
    hotelAddress: {
      fontWeight: 400,
      color: 'gray.600',
    },
    hotelPrice: {
      fontWeight: 500,
      color: 'gray.600',
    },
    hotelName: {},
    addressAndRatingContainer: {
      gap: { base: 0, md: 3 },
    },
    ratingContainer: {},
    priceBarContainer: {},
    priceSectionContainer: {},
    offerBadgeContainer: {
      mt: '22px',
      marginBottom: '2px',
    },
    offerBadge: {
      bgColor: 'primary.100',
      borderRadius: 'full',
      fontWeight: 'medium',
    },
    totalPriceContainer: {},
    totalPriceText: {
      fontWeight: 500,
      color: 'gray.600',
    },
    totalPriceAmount: {},
    originalPriceContainer: {
      display: 'flex',
      height: 10,
      fontWeight: 400,
      marginBottom: 1,
    },
    originalPrice: {
      textDecoration: 'line-through',
      alignSelf: 'flex-end',
    },
    accommodationLink: {
      ml: 4,
    },
    roomSelectionButton: {},
    mobileHotelTagStack: {
      pt: '6px',
    },
  },
};
