export default {
  baseStyle: {
    wrapper: {
      background: 'white',
      alignSelf: 'flex-end',
      mt: 'auto',
      bottom: 0,
      position: 'sticky',
    },
    container: {
      borderTopWidth: 'thin',
      borderRightWidth: 'thin',
      px: 8,
      pb: 4,
      pt: 4,
    },
    resultCountText: {
      pb: 4,
      mt: 1,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  },
};
