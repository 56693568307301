import buttonVariants from '../Button';

// Duplicate button variants for links
export default {
  ...buttonVariants,
  variants: {
    outline: {
      ...buttonVariants.variants.outline,
      px: '5',
      py: '2',
      fontWeight: 'bold',
      textAlign: 'center',
      textWrap: 'nowrap',
      _hover: {
        ...buttonVariants.variants.outline._hover,
        textDecoration: 'none',
      },
    },
  },
};
