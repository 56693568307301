export default {
  baseStyle: {
    control: {
      borderRadius: 'var(--chakra-radii-sm)',
      _focus: {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
