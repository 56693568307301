import { ComponentStyleConfig } from '@chakra-ui/react';
import { createPseudoBackgroundImageElement } from '../../../../utils/chakra-ui';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import infoFillTooltipSvg from '../../icons/infoFillTooltipSvg';
import tickInCircle from '../../icons/tickInCircle';

const RoomAndBoardRoom: ComponentStyleConfig = {
  parts: ['roomButton', 'left', 'right', 'selectButton', 'selectedIcon', 'priceStat', 'priceLabel'],
  baseStyle: {
    roomButton: {
      position: 'relative',
      borderRadius: 'var(--chakra-radii-xl)',
      overflow: 'hidden',
      _before: createPseudoBackgroundImageElement({
        borderRadius: 'var(--chakra-radii-xl)',
        boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-gray-200)',
        zIndex: 1,
        pointerEvents: 'none',
      }),
      _disabled: {
        opacity: 1,
      },
    },
    left: {
      w: { base: '100%', md: 'unset' },
      padding: {
        base: 'var(--chakra-sizes-5) var(--chakra-sizes-6) var(--chakra-sizes-4) var(--chakra-sizes-6)',
        md: 'var(--chakra-sizes-5) var(--chakra-sizes-6)',
      },
      borderBottom: {
        base: `1px solid ${colors.gray[200]}`,
        md: 'none',
      },
    },

    cancellationIcon: {
      display: 'inline-block',
      w: 5,
      h: 5,
      mr: 3,
      bg: svgUrl(tickInCircle(colors.primary[500])),
      verticalAlign: 'bottom',
    },
    right: {
      w: { base: '100%', md: '175px', lg: '200px' },
      h: { base: 'unset', md: 36 },
      padding: {
        base: 5,
        md: 'var(--chakra-sizes-4) var(--chakra-sizes-6) var(--chakra-sizes-5) var(--chakra-sizes-3)',
      },
      borderLeftWidth: '1px',
    },
    selectButton: {
      h: 12,
      w: '100%',
    },
    selectedIcon: {
      w: 8,
      h: 8,
      bg: svgUrl(tickInCircle(colors.primary[500])),
      backgroundRepeat: 'no-repeat',
    },
    priceStat: {
      w: '100%',
      px: 3,
      textAlign: 'left',
    },
    priceLabel: {
      color: 'gray.600',
    },
    priceNumber: {
      fontWeight: 700,
      lineHeight: 1.2,
      color: 'primary.500',
    },
    infoIcon: {
      bg: svgUrl(infoFillTooltipSvg(colors.primary[500])),
      backgroundRepeat: 'no-repeat',
      w: 6,
      h: 6,
    },
  },
  variants: {
    selected: () => ({
      roomButton: {
        borderColor: 'white',
        backgroundColor: 'primary.50',
        _before: {
          borderColor: 'white',
          boxShadow: 'inset 0px 0px 0px 2px var(--chakra-colors-primary-500)',
        },
      },
    }),
  },
};

export default RoomAndBoardRoom;
