export default {
  parts: [
    'anchor',
    'marker',
    'selectedText',
    'text',
    'step',
    'circleProgress',
    'circleProgressStepCounter',
    'circleProgressStepText',
  ],
  baseStyle: {
    anchor: {
      _hover: {
        textDecoration: 'none',
      },
    },
    step: {
      '&.progress-step-selected': {
        cursor: 'default',
      },
      '&.progress-step-disabled': {
        cursor: 'not-allowed',
      },
    },
    text: {
      color: 'gray.600',
      fontWeight: 'medium',

      '.progress-step-selected &': {
        color: 'primary.500',
        fontWeight: 'semibold',
      },
    },
    marker: {
      h: 1,
      minW: '11rem',
      bg: 'primary.100',
      '.progress-step-selected &': {
        bg: 'accent.500',
      },
      '.progress-step-disabled &': {
        bg: 'primary.100',
      },
    },
    circleProgress: {
      '& .circle-track': {
        stroke: 'primary.100',
        strokeWidth: 4,
      },
      '& .circle-progress': {
        stroke: 'accent.500',
        strokeWidth: 4,
      },
    },
    circleProgressStepCounter: {
      fontWeight: 700,
      color: 'primary.500',
    },
    circleProgressStepText: {
      fontWeight: 'semibold',
      color: 'primary.500',
    },
  },
};
