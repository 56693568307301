import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import chevronRightSvg from '../../icons/chevronRightSvg';
import searchSvg from '../../icons/searchSvg';

const iconStyle = {
  alignSelf: 'center',
  w: '6',
  h: '6',
};

const borderColour = colors.primary['50'];

export default {
  baseStyle: {
    openDrawerIcon: {
      ...iconStyle,
      bg: svgUrl(chevronRightSvg()),
    },
    backButton: {
      ...iconStyle,
      bg: svgUrl(chevronRightSvg('white')),
      transform: 'rotateZ(180deg)',
    },
    backButtonContainer: {
      background: 'primary.700',
      position: 'absolute',
      top: 10,
      left: 7,
      p: 2,
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      height: '44px',
      width: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mobileOption: {
      display: 'flex',
      justifyContent: 'space-between',
      py: '2',
      mt: '2',
      fontSize: 'lg',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    popoverTitle: {
      fontSize: 'xl',
      pb: 4,
      position: 'absolute',
      top: 6,
      left: '50%',
      transform: 'translateX(-50%)',
      color: 'primary.500',
    },
    filterContent: {
      fontSize: 'lg',
    },
    selectedDateString: {
      fontWeight: 'bold',
      color: 'white',
    },
    filtersInnerContainer: {
      borderRight: `1px solid ${borderColour}`,
    },
    filterInputs: {
      mt: 4,
    },
    plusMinusContainer: {
      marginRight: 1,
    },
    dateString: {
      color: 'primary.500',
      fontWeight: 'bold',
    },
    filterTitle: {
      color: 'primary.500',
      mr: 2,
    },
    searchIcon: {
      bg: svgUrl(searchSvg(colors.primary[500])),
    },
  },
};
