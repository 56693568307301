import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { createPseudoBackgroundImageElement } from '../../../../utils/chakra-ui';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'genericPanelContainer',
  'genericPanelContainerButton',
  'genericPanelFooter',
  'genericPanelSplitItemsWrapper',
  'genericPanelItem',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    genericPanelContainerButton: {
      _before: createPseudoBackgroundImageElement({
        borderRadius: 'var(--chakra-radii-xl)',
        boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-chakra-border-color)',
        zIndex: 0,
        pointerEvents: 'none',
      }),
    },
    genericPanelItem: {
      borderBottomWidth: 1,
      _last: {
        borderBottomWidth: { base: 1, md: 0 },
      },
    },
    genericPanelSplitItemsWrapper: {
      _last: {
        base: {
          '& .GenericPanelItem': {
            borderBottomWidth: 1,
          },
        },
        md: {
          borderBottomWidth: 0,
          '& .GenericPanelItem': {
            borderBottomWidth: 0,
          },
        },
      },
    },
    genericPanelFooter: {
      borderLeftWidth: { base: 0, md: 1 },
    },
  },
  variants: {
    selected: {
      genericPanelContainerButton: {
        backgroundColor: 'primary.50',
        _before: createPseudoBackgroundImageElement({
          boxShadow: 'inset 0px 0px 0px 2px var(--chakra-colors-primary-500)',
          zIndex: 1,
        }),
      },
    },
  },
});
