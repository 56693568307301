import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../utils/graphics';
import customScrollBar from '../../customScrollBar';
import arrowRight from '../../icons/arrowRight';

const RoomAndBoard = {
  parts: [
    'heading',
    'roomGroup',
    'roomGroupHeading',
    'roomGroupVStack',
    'moreRoomOptionsButtonsIcon',
  ],
  baseStyle: {
    heading: {},
    moreRoomOptionsButtonsIcon: {
      w: 3,
      h: 3,
      ml: 3,
      backgroundImage: ({ colors }: StyleFunctionProps) =>
        svgUrl(arrowRight(colors.primary['500'])),
    },
    roomGroupVStack: {
      borderColor: '#FFF',
    },
    tag: {
      bgColor: 'primary.100',
      color: 'primary.500',
      marginTop: '2px',
    },
    tagLabel: {},
    tabScroll: {
      display: 'flex',
      overflowX: 'auto',
      pb: 2, // Gap between scrollbar and tabs
      ...customScrollBar,
    },
    tabs: {
      bg: 'white',
      color: 'gray.700',
      borderRadius: 'full',
      border: '1px solid',
      borderColor: 'gray.200',
      _hover: {
        boxShadow: 'md',
      },
      _selected: {
        bg: 'primary.500',
        color: 'white',
        borderRadius: 'full',
        _hover: {
          boxShadow: 'md',
        },
      },
    },
  },
};

export default RoomAndBoard;
