import {
  Box,
  Divider,
  Flex,
  HStack,
  Img,
  Link as Anchor,
  Stack,
  StackDivider,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import DOMPurify from 'isomorphic-dompurify';
import React, { useEffect } from 'react';
import { FooterProps } from '.';
import useBrochurewareDictionary from '../../api/Dictionary/useBrochurewareDictionary';
import { MEDIA_BASE_PATH } from '../../constants/routes';
import { cmsRichTextRules } from '../../constants/sanitize';
import { getSocialIcon } from '../../utils/chakra-ui';
import {
  hideManageCookiesLinkVisibilityBasedOnCookieScript,
  isCivicCookieControlScriptPresent,
  ManageCookiesLinkClassName,
} from './helpers';

const MinimalBottomSection = ({ settings, hasMinimalFooter }: FooterProps) => {
  const footerTheme = useMultiStyleConfig('Footer', { baseStyles: true });
  const { labels } = useBrochurewareDictionary('Footer');

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const cookieScriptIsPresent = isCivicCookieControlScriptPresent();
      hideManageCookiesLinkVisibilityBasedOnCookieScript(cookieScriptIsPresent);
      if (cookieScriptIsPresent) observer.disconnect();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const onManageCookiesClick = () => {
    if (window.CookieControl) window.CookieControl.open();
  };

  return (
    <>
      {!hasMinimalFooter && (
        <Divider display={{ base: 'none', md: 'block' }} sx={footerTheme.divider} />
      )}
      <Box py={6}>
        <Flex
          order={1}
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'flex-start', md: 'center' }}
          justify="space-between"
          gap={{ base: 8, md: 5 }}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            align={{ base: 'flex-start', md: 'center' }}
          >
            {hasMinimalFooter && (
              <Box
                role="img"
                aria-label={`${settings?.siteName} Logo`}
                flexShrink={0}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                sx={footerTheme.logo}
              />
            )}
            <Text
              mt={0}
              whiteSpace="nowrap"
              sx={footerTheme.copyright}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(settings?.footerCopyright || '', cmsRichTextRules),
              }}
            />
            {settings?.footerSecondaryNavigationLinks && (
              <HStack
                display={hasMinimalFooter ? { base: 'flex', md: 'none', lg: 'flex' } : 'flex'}
                divider={<StackDivider sx={footerTheme.divider} />}
                wrap="wrap"
              >
                {settings?.footerSecondaryNavigationLinks?.map((item) => (
                  <Anchor
                    key={item?.title}
                    href={item?.url}
                    target={item?.isNewWindow ? '_blank' : '_self'}
                    rel={item?.isNewWindow ? 'noopener noreferrer' : ''}
                    sx={footerTheme.secondaryNavLink}
                  >
                    {item?.title}
                  </Anchor>
                ))}
                <Anchor
                  className={ManageCookiesLinkClassName}
                  as="button"
                  onClick={() => onManageCookiesClick()}
                  sx={footerTheme.secondaryNavLink}
                >
                  {labels('manageCookiesLinkText')}
                </Anchor>
              </HStack>
            )}
          </Stack>
          {settings?.footerSocialLinks && (
            <HStack
              spacing={3}
              display={!hasMinimalFooter ? { base: 'flex', md: 'none', lg: 'flex' } : 'flex'}
            >
              {settings?.footerSocialLinks.map((socialLink, index) => {
                const socialIcon = getSocialIcon(socialLink.title.toLowerCase());
                return (
                  <Anchor
                    key={socialLink.title + index}
                    href={socialLink.url}
                    target={socialLink.isNewWindow ? '_blank' : '_self'}
                    rel={socialLink.isNewWindow ? 'noopener noreferrer' : ''}
                  >
                    <Box
                      role="img"
                      aria-label={socialIcon.alt}
                      bgImage={socialIcon.svgUrl}
                      sx={footerTheme.socialIcon}
                    />
                  </Anchor>
                );
              })}
            </HStack>
          )}
        </Flex>
        {hasMinimalFooter && settings?.footerSecondaryNavigationLinks && (
          <HStack
            display={{ base: 'none', md: 'flex', lg: 'none' }}
            divider={<StackDivider sx={footerTheme.divider} />}
            wrap="wrap"
            mt={4}
          >
            {settings?.footerSecondaryNavigationLinks?.map((item) => (
              <Anchor
                key={item?.title}
                href={item?.url}
                target={item?.isNewWindow ? '_blank' : '_self'}
                rel={item?.isNewWindow ? 'noopener noreferrer' : ''}
                sx={footerTheme.secondaryNavLink}
              >
                {item?.title}
              </Anchor>
            ))}
            <Anchor
              className={ManageCookiesLinkClassName}
              as="button"
              onClick={() => onManageCookiesClick()}
              sx={footerTheme.secondaryNavLink}
            >
              {labels('manageCookiesLinkText')}
            </Anchor>
          </HStack>
        )}
      </Box>
      <Divider
        display={!hasMinimalFooter ? { base: 'none', md: 'block', xl: 'none' } : 'block'}
        sx={footerTheme.divider}
      />
      {settings?.footerImages && (
        <Flex
          gap={5}
          py={5}
          display={!hasMinimalFooter ? { base: 'none', md: 'flex', xl: 'none' } : 'flex'}
        >
          {settings?.footerImages?.map((image) => (
            <Img
              key={image?.alt}
              src={`${MEDIA_BASE_PATH}${image?.url}`}
              alt={image?.alt}
              objectFit="cover"
              loading="lazy"
              sx={footerTheme.footerImage}
            />
          ))}
        </Flex>
      )}
    </>
  );
};

export default MinimalBottomSection;
