import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import SearchControl from '../SearchControl';

export default {
  parts: ['NumberInputField', 'Box', 'NumberIncrementStepper', 'NumberDecrementStepper'],
  baseStyle: {
    NumberInputGroup: {
      w: { base: 'calc(100% + 1rem)', xl: '100%' },
    },
    NumberInputField: {
      height: 8, // match other inputs
      marginBottom: SearchControl.baseStyle?.activeInput.marginBottom,
      borderBottom: 'none',
      boxShadow: 'none',
      ':focus': {
        boxShadow: 'none',
      },
    },

    NumberIncrementStepper: {
      mt: '-3px',
      bg: svgUrl(arrowUp(colors.primary[500])),
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: '12px',
      border: 'none',
      fontSize: 0,
      color: 'primary.500',
    },
    NumberDecrementStepper: {
      mb: `calc(5px + ${SearchControl.baseStyle?.activeInput.marginBottom})`,
      bg: svgUrl(arrowDown(colors.primary[500])),
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom center',
      backgroundSize: '12px',
      border: 'none',
      fontSize: 0,
      color: 'primary.500',
    },
    NumberInputStepper: {
      pl: '10px',
      pr: 0,
    },
  },
};
