import { svgUrl } from '../../../../utils/graphics';
import missingPictureSvg from '../../icons/missingPictureSvg';

export default {
  parts: ['background', 'icon'],
  baseStyle: {
    background: {
      bg: 'gray.100',
    },
    icon: {
      w: 8,
      h: 8,
      backgroundImage: svgUrl(missingPictureSvg()),
    },
  },
};
