import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'noResultsContainer',
  'noResultsIcon',
  'noResultsText',
  'noResultsTitle',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    noResultsIcon: {
      color: 'primary.500',
    },
  },
});
