export default {
  parts: ['select', 'wrapper'],
  baseStyle: {
    select: {
      color: 'primary.500',
      borderRadius: '5px',
      borderColor: 'primary.500',
      borderWidth: '2px',
      fontSize: 'sm',
      fontWeight: 'semibold',
    },
    wrapper: {
      borderRadius: '10em',
    },
  },
};
