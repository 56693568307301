import { Z_INDEX } from '../../../../constants/zIndex';

export default {
  parts: ['flexOuter', 'logo', 'module'],
  baseStyle: {
    flexOuter: {},
    logo: {
      width: '227px',
      height: '57px',
      bgRepeat: 'no-repeat',
      bgImage: 'var(--global-logo)',
      bgSize: 'contain',
      mr: { base: 0, md: 20 },
      color: 'white',
    },
    module: {
      bg: 'var(--global-background-color)',
      py: '4',
      zIndex: Z_INDEX.header,
    },
  },
  // This has been deactivated
  // variants: {
  //   transparent: {
  //     module: {
  //       bg: 'transparent',
  //       position: 'absolute',
  //       borderBottom: '1px solid rgba(256, 256, 256, .25)',
  //     },
  //   },
  // },
};
