import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import theme, { font } from '../../theme';
import { Page } from '../../types';
import { getBrowserTitle } from '../../utils/routes';

interface ApplyAppHeadWithContentAPIDataProps {
  siteName?: string;
  pageData?: Page;
}

export const ApplyAppHeadWithContentAPIData = ({
  siteName,
  pageData,
}: ApplyAppHeadWithContentAPIDataProps) => {
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();

  return (
    <Head>
      {siteName && pageData?.browserTitle && (
        <>
          <title>{getBrowserTitle(router, siteName, pageData?.browserTitle)}</title>
          <meta name="apple-mobile-web-app-title" content={siteName} />
        </>
      )}
      {pageData?.seoMetaDescription && (
        <meta name="description" content={pageData?.seoMetaDescription} />
      )}
      {pageData?.seoNoIndex && <meta name="robots" content="noindex, nofollow" />}
      {!pageData?.seoExcludeCanonicalUrl && pageData?.seoCanonicalLink && (
        <link rel="canonical" href={`${publicRuntimeConfig.url}${pageData?.seoCanonicalLink}`} />
      )}
    </Head>
  );
};

const ApplyAppHead = () => (
  <Head>
    <meta name="viewport" content="initial-scale=1.0, viewport-fit=cover" />
    <meta name="google-site-verification" content="XIu76zCXxdPbsIsDXH7eMXEFdjMD7AexT1mwQYofKok" />
    {/* Standard Favicon */}
    <link rel="icon" id="favicon" type="image/x-icon" href={`/meta/favicon.ico?v=1`} />
    {/* For Apple devices - Android and other platforms will also pick it up */}
    <link rel="apple-touch-icon" href={`/meta/apple-touch-icon.png?v=1`} />

    <meta name="msapplication-TileColor" content={theme.globals.mobileThemeColor} />
    <meta name="theme-color" content={theme.globals.mobileThemeColor} />

    <meta name="msapplication-config" content={`/meta/browserconfig.xml?v=1`} />
    <link rel="manifest" href={`/meta/site.webmanifest?v=1`} />
    <style
      dangerouslySetInnerHTML={{
        __html: font,
      }}
    ></style>
    {/* Preload fonts */}
    <link
      rel="preload"
      href="/fonts/MuseoSansRounded500.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href="/fonts/MuseoSansRounded700.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
  </Head>
);

export default ApplyAppHead;
