/**
 * Hi use this link below to create your colour ranges from 50 -> 900.
 * Paste in your base colour, this will become your 500, the others will be generated.
 * https://hihayk.github.io/scale/#4/5/50/89/0/0/-0/0/0077C8/0/119/200/white
 */

// Sumatra Jungle Green
const primary = {
  '50': '#F7FAF7', // panel
  '100': '#E4EDE8', // progress slug, tag, form stroke, rating off
  '200': '#88B4B2',
  '300': '#5B9794',
  '400': '#2D7A77',
  '500': '#005D59',
  '600': '#00514E',
  '700': '#004643',
  '800': '#003A38',
  '900': '#002F2D',
};

// Gray
const secondary = {
  '50': '#F4F5F5',
  '100': '#CECFD0',
  '200': '#AFB1B2',
  '300': '#909395',
  '400': '#727577',
  '500': '#53575A',
  '600': '#494C4F',
  '700': '#3E4144',
  '800': '#343638',
  '900': '#2A2C2D',
};

// Jaipur Pink
const accent = {
  '50': '#FFF7F5',
  '100': '#FFEBE5',
  '200': '#FFDED5',
  '300': '#FFD2C5',
  '400': '#FFC6B5',
  '500': '#FFB9A5', // selected progress, selected rating
  '600': '#DFA290',
  '700': '#BF8B7C',
  '800': '#9F7467',
  '900': '#805D53',
};

// Scarlet Red
const promo = {
  '50': '#FCEDED',
  '100': '#FCEBEB',
  '200': '#F5C6C7',
  '300': '#EFA1A3',
  '400': '#E97C7F',
  '500': '#E2575B', // offers, slip accom indicator
  '600': '#DC3237',
  '700': '#A52629',
  '800': '#8A1F22',
  '900': '#6E191C',
};

const error = {
  '50': '#FCEDED',
  '100': '#FCEBEB', // validation panel
  '200': '#F5C6C7',
  '300': '#EFA1A3',
  '400': '#E97C7F',
  '500': '#E2575B', // validation text, icons
  '600': '#DC3237',
  '700': '#A52629',
  '800': '#8A1F22',
  '900': '#6E191C',
};

const warning = {
  '50': '#FBEFE7',
  '100': '#F5D4BF',
  '200': '#EFBA97',
  '300': '#E9A06F',
  '400': '#E38548',
  '500': '#DD6B20',
  '600': '#C15E1C',
  '700': '#A65018',
  '800': '#8A4314',
  '900': '#6F3610',
};

const success = {
  '50': '#EBF8F0',
  '100': '#CAEBD8',
  '200': '#AADFC0',
  '300': '#89D3A8',
  '400': '#69C790',
  '500': '#48BB78',
  '600': '#3FA469',
  '700': '#368C5A',
  '800': '#2D754B',
  '900': '#245E3C',
};

const gray = {
  '100': '#EDF2F7',
  '200': '#E2E8F0',
  '400': '#A0AEC0',
  '700': '#2D3748',
  '900': 'var(--gray-900, #2D3748)',
};

const colors = {
  primary,
  secondary,
  accent,
  promo,
  success,
  warning,
  error,
  gray,
  cyan: '#00B5D8', // coop holidays map marker
  paleBlue: '#AFE2E3', // coop travel contact bar
  flameOrange: '#EA5E2F', // coop travel cta button
  focus: 'rgba(243, 169, 0, 0.6)', // this is an orange from the original site should be perhaps the pink '#FFB9A5AA',
  tripAdvisorGreen: '#48BB78',
};

export default colors;
