export enum ComponentRenderState {
  SSR_UNMOUNTED,
  CLIENT_MOUNTED,
  FLICKITY_READY,
}

export enum ItemsRenderState {
  RENDER_FIRST,
  SHOW_ALL,
}

export enum CarouselVariant {
  IMAGE_CTA = 'imageCta',
  IMAGE = 'image',
  HERO_FULL = 'heroFull',
  HERO_SHORT = 'heroShort',
  GENERIC_CTA = 'genericCta',
  CATEGORY_CTA = 'categoryCta',
}
