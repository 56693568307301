import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import circleEmptySvg from '../../icons/circleEmptySvg';
import circleFillSvg from '../../icons/circleFillSvg';

const svgRating = {
  w: '10px',
  h: '10px',
  mr: '1',
  backgroundSize: 'contain',
};

export default {
  parts: [
    'outer',
    'inner',
    'imageOuter',
    'image',
    'header',
    'footer',
    'content',
    'price',
    'originalPrice',
    'priceLabel',
    'button',
    'ratingEmpty',
    'ratingHalf',
    'ratingFill',
    'offerBadge',
  ],
  baseStyle: {
    outer: {
      w: {
        base: 'full',
      },
    },
    inner: {
      rounded: 'lg',
      borderWidth: '1px',
    },
    imageOuter: {
      minHeight: '48',
    },
    image: {
      roundedTop: 'lg',
      objectFit: 'cover',
    },
    header: {},
    title: {
      fontWeight: 'semibold',
    },
    description: {},
    footer: {},
    content: {},
    priceLabel: {
      fontWeight: 'medium',
    },
    price: {
      fontWeight: 'semibold',
    },
    originalPrice: {},
    button: {},
    ratingEmpty: {
      ...svgRating,
      backgroundImage: svgUrl(circleEmptySvg(colors.primary[100])),
    },
    ratingHalf: {
      ...svgRating,
      backgroundImage: svgUrl(circleFillSvg(colors.primary[100])),
    },
    ratingFill: {
      ...svgRating,
      backgroundImage: svgUrl(circleFillSvg(colors.primary[100])),
    },
    offerBadge: {
      bgColor: 'promo.600',
      color: 'white',
      borderRadius: 'full',
      fontWeight: 'medium',
    },
  },
  variants: {
    marker: {
      inner: {
        rounded: 'none',
        borderWidth: 0,
      },
    },
  },
};
