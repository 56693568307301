/**
 * The Modal is designed to be consistent across the application, and with the similar Drawer
 * component.
 */
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const desktopBreakpoint = 'md';

const baseStyle = definePartsStyle({
  // define the part you're going to style
  dialog: {
    minWidth: { base: 'full', md: 'xl' },
    maxWidth: { base: 'full', md: 'xl' },
    minH: { base: 'var(--chakra-vh)', [desktopBreakpoint]: 'auto' },
    mt: { base: 0, [desktopBreakpoint]: 16 },
    mb: { base: 0, [desktopBreakpoint]: 16 },
    borderRadius: { base: 0, [desktopBreakpoint]: 'var(--chakra-radii-md)' },
    py: { base: 0, [desktopBreakpoint]: 5 }, // the 5 here bring the outer y padding to 12, no matter if say header is not included
  },
  closeButton: {
    top: { base: 5, [desktopBreakpoint]: 4 },
    right: { base: 5, [desktopBreakpoint]: 4 },
  },
  header: {
    px: { base: 5, [desktopBreakpoint]: 7 },
    py: { base: 7, [desktopBreakpoint]: 7 },
  },
  body: {
    p: { base: 5, [desktopBreakpoint]: 7 },
  },
  footer: {
    px: { base: 5, [desktopBreakpoint]: 7 },
    pt: { base: 4, [desktopBreakpoint]: 0 },
    pb: { base: 4, [desktopBreakpoint]: 7 },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
});
