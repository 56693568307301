export const ManageCookiesLinkClassName = 'ManageCookiesLink';

export const hideManageCookiesLinkVisibilityBasedOnCookieScript = (
  cookieScriptIsPresent: boolean
) => {
  const manageCookiesLinks = document.getElementsByClassName(ManageCookiesLinkClassName);
  Array.from(manageCookiesLinks).forEach((manageCookieLinkElement) => {
    // 1. Find the manage cookie link element and hide it if script is NOT present
    const manageCookieLink = manageCookieLinkElement as HTMLElement;
    manageCookieLink.style.display = cookieScriptIsPresent ? 'block' : 'none';

    // 2. If script not present, also hide the divider link before it
    // so there is no hanging divider at the end
    const divider = manageCookieLink.previousSibling as HTMLElement;
    if (divider && divider.classList.contains('chakra-stack__divider')) {
      divider.style.display = cookieScriptIsPresent ? 'block' : 'none';
    }
  });
};

export const isCivicCookieControlScriptPresent = () => {
  const scripts = Array.from(document.getElementsByTagName('script'));
  const civicScriptRegexPattern = /civiccomputing/;
  const matchingScript = scripts.find((script) => civicScriptRegexPattern.test(script.src));
  return !!matchingScript;
};
