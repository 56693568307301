export default {
  baseStyle: {
    indicator: {
      mt: '2px',
      height: '2px',
      bg: 'secondary.700',
    },
    button: {
      px: 8,
      '&[aria-selected="true"]': {
        fontWeight: '600',
        textDecoration: { base: 'underline', md: 'none' }, // Workaround for chakra tab indication bug
      },
    },
  },
};
