import { svgUrl } from '../../../../utils/graphics';
import { above } from '../../../../utils/mediaQueries';
import breakpoints from '../../config/breakpoints';
import arrowLeft from '../../icons/arrowLeft';
import closeSvg from '../../icons/closeSvg';
import hamburgerSvg from '../../icons/hamburgerSvg';

const icon = (svg: string) => ({
  h: 6,
  w: 6,
  bgImage: svgUrl(svg),
  bgRepeat: 'no-repeat',
  bgPos: 'center',
});

const subLevel = {
  base: {
    color: 'gray.700',
    h: 'full',
    w: 'full',
  },
  mobile: {
    pos: 'absolute',
    display: 'none',
    overflow: 'visible',
    top: 0,
    left: '100%',
    '&.level-active': {
      display: 'block',
    },
    '&.level-selected': {
      overflow: 'hidden scroll',
    },
    color: 'var(--global-heading-font-color)',
  },
  desktop: {
    left: 0,
  },
};

const navigationItem = {
  base: {
    pos: 'relative',
    textAlign: 'initial',
  },
  mobile: {
    display: 'block',
    w: 'full',
    px: 8,
    py: 3,
    _hover: {
      bgColor: 'gray.100',
    },
    _focus: {
      bgColor: 'gray.100',
    },
  },
  desktop: {
    display: 'inline-block',
  },
};

const navigationItemText = {
  base: {
    m: 0,
    fontWeight: 'semibold',
    textWrap: 'pretty',
  },
  mobile: {
    fontSize: 'xl',
  },
};

export default {
  parts: [
    'backIcon',
    'baseLevel',
    'bottomSelfLink',
    'burgerIcon',
    'burgerButton',
    'closeIcon',
    'drawer',
    'drawerContent',
    'drawerContentSlides',
    'drawerHeader',
    'drawerHeaderButton',
    'drawerHeaderTitle',
    'drawerOverlay',
    'drawerSecondaryNavigation',
    'flyOut',
    'linkBlock',
    'linkGroup',
    'linkSubGroup',
    'logo',
    'mainNavigation',
    'mainNavigationItem',
    'mainNavigationItemChevron',
    'mainNavigationItemContainer',
    'mainNavigationItemText',
    'mainNavigationSubItem',
    'mainNavigationSubItemDesktopOnly',
    'mainNavigationSubItemMobileOnly',
    'mainNavigationSubItemText',
    'module',
    'nav',
    'navContainer',
    'parentLink',
    'secondaryNavigation',
    'secondaryNavigationItem',
  ],
  baseStyle: {
    backIcon: icon(arrowLeft('white')),
    baseLevel: subLevel.base,
    bottomSelfLink: {
      pos: 'absolute',
      left: 0,
      bottom: 0,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      h: 12,
      width: 'full',
      bgColor: 'var(--global-background-color)',
      color: 'white',
      borderBottomRadius: 10,
      px: 1,
    },
    burgerIcon: icon(hamburgerSvg('white')),
    burgerButton: {},
    closeIcon: icon(closeSvg('white')),
    drawer: {},
    drawerContent: {},
    drawerContentSlides: {},
    drawerHeader: {
      px: 8,
      py: 4,
      justifyContent: 'space-between',
    },
    drawerHeaderButton: {
      p: 0,
    },
    drawerHeaderTitle: {
      fontSize: 'xl',
      fontWeight: 'semibold',
    },
    drawerOverlay: {},
    drawerSecondaryNavigation: {
      justifyContent: 'flex-end',
      gap: 4,
      px: 8,
      py: 2,
      borderTopWidth: '1px',
      mt: 'auto',
    },
    flyOut: subLevel.base,
    linkBlock: {},
    linkGroup: {},
    linkSubGroup: {
      alignItems: 'stretch',
      flexDir: 'column',
    },
    logo: {
      w: '178px',
      h: '44px',
      bgRepeat: 'no-repeat',
      bgImage: 'var(--global-logo-drawer)',
      mr: '20',
      color: 'white',
      backgroundSize: 'contain',
    },
    mainNavigation: {},
    mainNavigationItem: navigationItem.base,
    mainNavigationItemChevron: {
      display: 'block',
      pos: 'absolute',
      right: 3,
      top: '50%',
      transform: 'translateY(-50%)',
      h: 6,
      w: 6,
      bgRepeat: 'no-repeat',
      bgPosition: 'center',
      bgImage: 'var(--global-navigation-chevron-right-icon)',
    },
    mainNavigationItemContainer: {},
    mainNavigationItemText: navigationItemText.base,
    mainNavigationSubItem: {
      ...navigationItemText.base,
      color: 'var(--global-heading-font-color)',
    },
    mainNavigationSubItemDesktopOnly: navigationItem.base,
    mainNavigationSubItemMobileOnly: navigationItem.base,
    mainNavigationSubItemText: {
      ...navigationItemText.base,
      color: 'var(--global-heading-font-color)',
    },
    module: {},
    nav: {
      justifyContent: 'space-between',
      w: 'full',
    },
    navContainer: subLevel.base,
    parentLink: {},
    secondaryNavigation: {
      justifyContent: 'flex-end',
      gap: 4,
      mr: 4,
    },
    secondaryNavigationItem: {},
  },
  variants: {
    mobile: {
      baseLevel: subLevel.mobile,
      drawer: {
        pos: 'fixed',
        top: 0,
        left: '100%',
        h: 'full',
        w: 'full',
        bgColor: 'white',
        zIndex: 'popover',
        transform: `translateX(0)`,
        transition: 'transform 0.2s ease-out 0s',
        willChange: 'transform',
        '&.drawer-open': {
          transform: 'translateX(-100%)',
        },
      },
      drawerContent: {
        pos: 'relative',
        h: 'full',
        w: 'full',
        overflow: 'hidden',
      },
      drawerContentSlides: {
        pos: 'absolute',
        width: 'full',
        height: 'full',
        transform: `translateX(0)`,
        transition: 'transform 0.2s ease-out 0s',
        willChange: 'transform',
        '&.level-2': {
          transform: 'translateX(-100%)',
        },
        '&.level-3': {
          transform: 'translateX(-200%)',
        },
      },
      drawerOverlay: {
        pos: 'fixed',
        left: 0,
        top: 0,
        w: '100vw',
        h: '100vh',
        bgColor: 'blackAlpha.600',
        zIndex: 'overlay',
      },
      flyOut: subLevel.mobile,
      linkGroup: {
        flexDir: 'column',
      },
      mainNavigation: {
        flexDir: 'column',
      },
      mainNavigationItem: navigationItem.mobile,
      mainNavigationItemChevron: {
        content: '""',
      },
      mainNavigationItemText: navigationItemText.mobile,
      mainNavigationSubItem: navigationItem.mobile,
      mainNavigationSubItemDesktopOnly: {
        ...navigationItem.mobile,
        display: 'none',
      },
      mainNavigationSubItemMobileOnly: navigationItem.mobile,
      mainNavigationSubItemText: navigationItemText.mobile,
      nav: {
        flexDir: 'column',
        h: 'full',
      },
      navContainer: {
        ...subLevel.mobile,
        left: 0,
      },
    },
    desktop: {
      baseLevel: subLevel.desktop,
      bottomSelfLink: {
        display: 'flex',
      },
      drawerSecondaryNavigation: {
        display: 'none',
      },
      burgerButton: {
        display: 'none',
      },
      drawerHeader: {
        display: 'none',
      },
      flyOut: {
        ...subLevel.desktop,
        pos: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        h: 'auto',
        w: 'auto',
        bgColor: 'white',
        borderRadius: 10,
        zIndex: 'popover',
      },
      linkBlock: {
        display: 'grid',
        gridTemplateRows: '1fr auto',
        breakInside: 'avoid',
        w: '165px',
        '& + &': {
          mt: 4,
        },
        ...above(breakpoints.xl, {
          w: '245px',
        }),
      },
      linkGroup: {
        display: 'block',
        py: 8,
        px: 20,
        columnGap: 1,
        '&.has-link': {
          pb: 20,
        },
        '&.col-count-1': {
          columnCount: 1,
          px: 8,
        },
        '&.col-count-2': {
          columnCount: 2,
        },
        '&.col-count-3': {
          columnCount: 3,
        },
        '&.col-count-4': {
          columnCount: 4,
        },
        '&.col-count-5': {
          columnCount: 5,
        },
      },
      linkSubGroup: {
        mt: 4,
      },
      mainNavigationItem: {
        ...navigationItem.desktop,
        p: 4,
        color: 'white',
        fontSize: 'md',
        _active: {
          color: 'white',
        },
      },
      mainNavigationItemContainer: {
        pos: 'relative',
        '&.active-nav': {
          _after: {
            content: '""',
            display: 'block',
            pos: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            height: 0,
            width: 0,
            borderBottom: '9px solid white',
            borderLeft: '9px solid transparent',
            borderRight: '9px solid transparent',
            mb: '-1px',
          },
        },
        '& .nav-menu': {
          display: 'none',
        },
      },
      mainNavigationItemText: {
        fontWeight: 'semibold',
      },
      mainNavigationSubItem: {
        ...navigationItem.desktop,
        lineHeight: 8,
      },
      mainNavigationSubItemDesktopOnly: {
        ...navigationItem.desktop,
        color: 'var(--global-heading-font-color)',
      },
      mainNavigationSubItemMobileOnly: {
        ...navigationItem.desktop,
        color: 'var(--global-heading-font-color)',
        display: 'none',
      },
      mainNavigationSubItemText: {
        fontWeight: 'normal',
      },
      module: {
        pos: 'relative',
      },
      nav: {
        color: 'white',
      },
      navContainer: subLevel.desktop,
      parentLink: {
        display: 'none',
      },
    },
  },
};
