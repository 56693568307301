import axios from 'axios';
import { getSessionId } from 'inspiretec-booking-journey-ui/utils/cookies';
import getConfig from 'next/config';

export function setSessionIdInNewRelic(sessionIdRef: React.MutableRefObject<string>) {
  axios.interceptors.response.use(function (response) {
    const apiBookingUiUrl: string = getConfig()?.publicRuntimeConfig?.apiBookingUiUrl;
    const successfulResponse = [200, 201, 204].includes(response?.status);
    if (response?.config.url?.includes(apiBookingUiUrl) && successfulResponse) {
      const sessionId = getSessionId();

      if (sessionId && sessionIdRef.current !== sessionId) {
        sessionIdRef.current = sessionId;
        window?.newrelic?.setUserId(sessionId);
        window?.newrelic?.setCustomAttribute('sessionId', sessionId, true);
      }
    }
    return response;
  });
}

export default null;
