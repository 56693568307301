const globals = {
  gutter: { base: '16px', md: '24px', xl: 8 },
  line: 'solid 1px gray.200',
  mobileThemeColor: 'white',
  moduleGutter: '16',
  moduleGutterLarge: '40',
  moduleGutterSmall: '8',
  moduleGutterSnug: '0',
  modulePricePerPersonMarginTopOveride: 5,
  searchHeight: { base: '70px', xl: '84px' },
  shadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
};

export default globals;
