import Button from '../../chakra/Button';

export default {
  parts: ['heading', 'text', 'button'],
  baseStyle: {
    heading: {
      color: 'white',
      fontSize: { base: '36px', md: '60px' },
      lineHeight: { base: '40px', md: '60px' },
      fontWeight: { base: 600, md: 700 },
    },
    text: {
      color: 'white',
    },
    ...Button.variants.solid,
  },
};
