import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const hideFirstItemLineOnDesktop = {
  // do not display line on first control on desktop
  display: { base: 'block', xl: 'none' },
};

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'activeGroup',
  'activeHeading',
  'activeInput',
  'activeInputHighlight',
  'footer',
  'triggerGroup',
  'triggerHeading',
  'triggerInput',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    activeGroup: {
      position: 'relative',
    },
    triggerGroup: {
      position: 'relative',
      p: 4,
      h: (theme: Record<string, StyleFunctionProps>) => theme.globals.searchHeight?.xl, // use desktop size for control height
      // This is our thin line that divides controls,
      _before: {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: { base: '1px', xl: 'calc(100% - 2rem)' },
        width: { base: 'calc(100% - var(--chakra-space-4))', xl: '1px' },
        left: { base: 'auto', xl: 0 },
        backgroundColor: 'var(--chakra-colors-chakra-border-color)',
        bottom: { base: 3, xl: 'auto' },
        '.SearchDynamicPackageForm__item:first-of-type &': hideFirstItemLineOnDesktop,
        '.SearchForm__item:first-of-type &': hideFirstItemLineOnDesktop,
      },
    },
    triggerInput: {
      border: 'none',
      fontSize: 'md',
    },
    triggerHeading: {
      fontSize: 'md',
      color: 'primary.500',
    },
    activeHeading: {
      fontSize: 'md',
    },
    activeInput: {
      boxShadow: 'none',
      fontSize: 'md',
      marginBottom: '5px', // gap required against activeInputHighlight
      height: 'var(--chakra-sizes-8)',
      ':focus': {
        boxShadow: 'none',
      },
      _disabled: {
        opacity: 1,
      },
    },
    activeInputHighlight: {
      'input + &': {
        display: 'block',
        position: 'absolute',
        height: '1px',
        width: '100%',
        bottom: '0', // bespoke alignment with divider line above
        // mb: '5px', // pushes out div so negative bottom shows
        backgroundColor: 'var(--chakra-colors-chakra-border-color)',
      },
      'input:focus + &': {
        backgroundColor: 'gray.500',
      },
    },
    footer: {
      mt: { base: 0, md: 6 },
      pt: { base: 6, md: 6 },
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
    },
  },
});
