import { svgUrl } from '../../../../../utils/graphics';
import alertSvg from '../../../icons/alertSvg';

export default {
  parts: [
    'tag',
    'tagText',
    'ActiveEditBox',
    'ActiveHeading',
    'ActiveInput',
    'alertIcon',
    'Heading',
  ],
  baseStyle: {
    ActiveEditBox: {
      borderRadius: '16px',
      borderWidth: 1,
      py: '36px',
      bgColor: '#FFF',
      opacity: 1,
      position: 'relative',
      zIndex: 5,
      top: '-36px',
      left: '-40px',
      maxWidth: '500px',
      minWidth: '378px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    ActiveInput: {
      boxShadow: 'none',
      ':focus': {
        boxShadow: 'none',
        borderBottom: '1px solid #2D3748',
      },
    },
    alertIcon: {
      mt: 1,
      w: 5,
      h: 5,
      bg: svgUrl(alertSvg()),
    },
    Heading: {
      fontSize: 'sm',
    },
    results: {
      width: '100%',
      minHeight: '4px', // This allows for a smaller pb on the main popover when the footer is vacant
    },
    resultName: {
      fontWeight: 600,
      my: 0,
      textAlign: 'left',
    },
    resultLocation: {
      fontWeight: 400,
      my: 0,
      textAlign: 'left',
      color: 'gray.600',
      fontSize: 'sm',
    },
  },
};
