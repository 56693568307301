export default {
  // Firefox
  scrollbarWidth: 'thin',
  scrollbarColor: '#aaa #f1f1f1',
  // Chrome and Safari
  '&::-webkit-scrollbar': {
    height: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#aaa', // Lightened grey
    borderRadius: '2px',
    marginTop: '14px', // Gap between tabs and scroll
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#888',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '2px', // More rounded for horizontal scrollbar
  },
};
