import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import timeFillSvg from '../../icons/timeFillSvg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(['timeIcon']);

export default defineMultiStyleConfig({
  baseStyle: {
    timeIcon: {
      bg: svgUrl(timeFillSvg(colors.primary[500])),
      w: { base: '16px', md: '27px', lg: '32px' },
      h: { base: '16px', md: '27px', lg: '32px' },
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      mb: { base: 0, lg: '2px' },
    },
  },
});
