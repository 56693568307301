export default {
  parts: [],
  baseStyle: {
    input: {
      w: 32,
      bgColor: 'white',
    },
    prevValue: {
      color: 'secondary.500',
    },
    newValue: {
      color: 'secondary.500',
    },
    changeCommissionContent: {
      bg: 'primary.50',
      color: 'secondary.500',
    },
    enterCommissionLabel: {
      color: 'secondary.500',
    },
    button: {
      bgColor: 'primary.500',
      color: 'white',
    },
    commissionItem: {
      _notFirst: {
        borderTopWidth: 1,
      },
    },
  },
};
