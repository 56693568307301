export default {
  parts: ['iconBack', 'iconTriangle'],
  baseStyle: {
    iconBack: {
      bg: 'secondary.500',
      _hover: {
        bg: 'secondary.600',
      },
      active: {
        bg: 'secondary.600',
      },
    },
    iconTriangle: {
      color: 'white',
    },
  },
};
