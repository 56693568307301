import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import listSvg from '../../icons/listSvg';
import pinSvg from '../../icons/pinSvg';

const iconStyle = {
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

export default {
  parts: ['tab', 'button', 'mapIcon', 'listIcon'],
  baseStyle: {
    tab: {
      backgroundColor: 'white',
      color: colors.gray[700],
      p: 0,
      borderRadius: 0,
      borderBottom: '2px solid #FFF',
      ':hover': {
        backgroundColor: 'white',
      },
      ':hover[disabled]': {
        backgroundColor: 'white',
      },
      _disabled: {
        backgroundColor: 'white',
        borderBottom: `2px solid ${colors.gray[700]}`,
        color: colors.gray[700],
      },
    },
    button: {},
    mapIcon: {
      ...iconStyle,
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(pinSvg(colors.primary['500'])),

      w: '14px',
      h: '17px',
      '.chakra-button:hover &': {
        backgroundImage: svgUrl(pinSvg('white')),
      },
    },
    listIcon: {
      ...iconStyle,
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(listSvg(colors.primary['500'])),
      w: '10px',
      h: '10px',
      '.chakra-button:hover &': {
        backgroundImage: svgUrl(listSvg('white')),
      },
    },
  },
};
