import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'contentFlexColumn',
  'footer',
  'footerText',
  'formControl',
  'formControlVStack',
  'formInput',
  'formLabel',
  'formValidation',
  'formVStack',
  'logo',
  'supportLabel',
  'supportNumber',
  'supportVStack',
  'title',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    logo: {
      aspectRatio: '291 / 60',
      height: '55px',
      bgImage: 'var(--global-logo-login)',
    },
    title: {
      fontWeight: '400',
    },
  },
});
