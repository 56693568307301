import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const sizes = {
  sm: definePartsStyle({
    content: {
      fontSize: 'sm',
    },
  }),
};

const dark = definePartsStyle({
  arrow: {
    bg: 'secondary.900 !important', // we need important here because, JS is used to inline the jsx props which override without
  },
  content: {
    bg: 'secondary.900',
    color: 'white',
  },
});

const variants = {
  dark,
};

export default defineMultiStyleConfig({
  variants,
  sizes,
});
