import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'priceStat',
  'priceLabel',
  'priceNumber',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    priceNumber: {
      color: 'primary.500',
    },
  },
});
