import { StyleFunctionProps } from '@chakra-ui/react';
import { chakraZIndexes } from '../../../../constants/zIndex';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import globals from '../../config/globals';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import bedSvg from '../../icons/bedSvg';
import coachSvg from '../../icons/coachSvg';
import luggageSvg from '../../icons/luggageSvg';
import planeSvg from '../../icons/planeSvg';
import ticketSvg from '../../icons/ticketSvg';

export default {
  parts: [
    'positionContainer',
    'containerBox',
    'altContainerBox',
    'sectionTitle',
    'sectionText',
    'sectionTextStrong',
    'sectionItemHeader',
    'borderLine',
    'upArrow',
    'downArrow',
    'bedIcon',
    'planeIcon',
    'infoTooltipIcon',
    'location',
    'flightLegTimeSlipIndicator',
    'tag',
    'tagLabel',
    'totalPrice',
    'price',
    'discountBadge',
    'discountBadgeLabel',
    'hotelImage',
  ],
  baseStyle: {
    positionContainer: {
      backgroundColor: 'white',
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      zIndex: chakraZIndexes.sticky - 1, // to prevent miniquote from covering stickypanel
      // increase max width to cover large box shadows beneath
      width: {
        base: `calc(100% + ${parseInt(globals.gutter.base, 10) * 2}px)`,
        md: `calc(100% + ${parseInt(globals.gutter.md, 10) * 2}px)`,
        lg: '330px',
      },
      transform: {
        base: `translateX(-${parseInt(globals.gutter.base, 10)}px)`,
        md: `translateX(-${parseInt(globals.gutter.md, 10)}px)`,
        lg: 'unset',
      },
    },
    containerBox: {
      width: {
        base: `calc(100% - ${parseInt(globals.gutter.base, 10) * 2}px)`,
        md: `calc(100% - ${parseInt(globals.gutter.md, 10) * 2}px)`,
        lg: '330px',
      },
      transform: {
        base: `translateX(${parseInt(globals.gutter.base, 10)}px)`,
        md: `translateX(${parseInt(globals.gutter.md, 10)}px)`,
        lg: 'unset',
      },
      borderTopWidth: `1px`,
      borderBottomWidth: `1px`,
      borderLeftWidth: { base: 'none', lg: `1px` },
      borderRightWidth: { base: 'none', lg: `1px` },
      borderRadius: { base: 'unset', lg: '10px' },
    },
    altContainerBox: {
      width: {
        base: `calc(100% - ${parseInt(globals.gutter.base, 10) * 2}px)`,
        md: `calc(100% - ${parseInt(globals.gutter.md, 10) * 2}px)`,
        lg: '330px',
      },
      transform: {
        base: `translateX(${parseInt(globals.gutter.base, 10)}px)`,
        md: `translateX(${parseInt(globals.gutter.md, 10)}px)`,
        lg: 'unset',
      },
      borderWidth: `1px`,
      borderRadius: '10px',
    },
    borderLine: {
      borderBottomWidth: `1px`,
    },
    upArrow: {
      h: '9px', // specific dimensions
      w: '15px', // specific dimensions
      backgroundImage: svgUrl(arrowUp(colors.primary[500])),
    },
    downArrow: {
      h: '9px', // specific dimensions
      w: '15px', // specific dimensions
      backgroundImage: svgUrl(arrowDown(colors.primary[500])),
    },
    bedIcon: {
      h: 4,
      w: '22px',
      backgroundImage: svgUrl(bedSvg(colors.primary[500])),
    },
    coachIcon: {
      h: '19px',
      w: '16px',
      backgroundImage: svgUrl(coachSvg(colors.primary[500])),
    },
    planeIcon: {
      h: 5,
      w: 5,
      backgroundImage: svgUrl(planeSvg(colors.primary[500])),
    },
    luggageIcon: {
      h: '20px',
      w: '14px',
      backgroundImage: svgUrl(luggageSvg(colors.primary[500])),
    },
    ticketIcon: {
      h: '15px',
      w: '19px',
      backgroundImage: svgUrl(ticketSvg(colors.primary[500])),
    },
    infoTooltipIcon: {
      h: 4,
      w: 4,
      bg: 'var(--global-info-fill-tooltip-icon)',
    },
    flightLegTimeSlipIndicator: {
      color: 'promo.500',
      fontWeight: 'semibold',
    },
    discountBadge: {
      bg: 'promo.600',
    },
    discountBadgeLabel: {
      fontSize: 'sm',
      color: 'white',
    },
    hotelImage: {
      img: {
        height: 'full !important', // overwrite inline style
      },
    },
    compactDrawerHeader: {
      color: 'primary.500',
    },
  },

  // MiniQuoteVariations enum are variants
  variants: {
    Checkout: () => ({
      positionContainer: {
        position: { base: 'static', lg: 'sticky' },
        top: ({ globals }: StyleFunctionProps) => globals.searchHeight,
      },
    }),
  },
};
