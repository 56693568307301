import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import filterSvg from '../../icons/filterSvg';

export default {
  parts: ['wrapper', 'filterButton', 'leftIcon', 'count'],
  baseStyle: {
    wrapper: {
      position: 'relative',
    },
    filterButton: {},
    leftIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(filterSvg(colors.primary['500'])),
      backgroundSize: 'contain',
      w: '14px',
      h: '14px',
      '.chakra-button:hover &': {
        backgroundImage: svgUrl(filterSvg('white')),
      },
    },
    count: {
      position: 'absolute',
      top: '-8px',
      right: '8px',
      bgColor: 'primary.500',
      color: 'white',
      h: '5',
      w: '5',
      fontSize: 'xs',
    },
  },
};
