import getConfig from 'next/config';

const config = getConfig();

export default null;

export const ROUTES_BASE_PATH = config?.publicRuntimeConfig?.contentRoutesBasePath; // /snow-travel/home

export const SITE_ID = config?.publicRuntimeConfig?.contentSiteId; // 1121

export const BOOKING_JOURNEY_ROUTE = '/booking';

export const LOGIN_ROUTE = '/login';

// TODO DRY for Storybook
export const MEDIA_BASE_PATH = config?.publicRuntimeConfig
  ? config.publicRuntimeConfig.mediaCdn
  : 'https://insagmedia.azureedge.net';
