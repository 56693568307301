import { StyleFunctionProps } from '@chakra-ui/react';

const modalToPopoverBreakpoint = 'xl';

export default {
  parts: ['popover', 'popoverHeader', 'popoverContent'],
  baseStyle: {
    popover: {
      position: { base: 'fixed', [modalToPopoverBreakpoint]: 'absolute' },
      p: { base: 4, lg: 10 },
      pb: { base: 4, lg: 6 }, // less because of footer nav
      px: { base: 0, lg: 0 },
      height: { base: '100%', [modalToPopoverBreakpoint]: 'auto' },
      maxH: { base: '100%', [modalToPopoverBreakpoint]: '600px' }, // needs to be px not vh to stop height reducing causing issues
      minW: { base: '100%', [modalToPopoverBreakpoint]: '386px' }, // 468 - 2 - 80; as per designs
      top: {
        base: 0,
        [modalToPopoverBreakpoint]: 'calc((var(--chakra-space-10) + 53px + 1rem) * -1)',
      }, // 53 height of title, sub title and 1rem padding
      left: {
        base: 0,
        [modalToPopoverBreakpoint]: 'calc((var(--chakra-space-10) + 1px - 1rem) * -1)',
      }, // 1 for border and 1rem padding
      boxShadow: ({ globals }: StyleFunctionProps) => ({
        base: 'none',
        [modalToPopoverBreakpoint]: globals.shadow,
      }),
      borderRadius: { base: 0, [modalToPopoverBreakpoint]: '16px' },
      borderWidth: { base: 0, [modalToPopoverBreakpoint]: 1 },
      bgColor: '#FFF',
      opacity: 1,
      boxSizing: { base: 'border-box', [modalToPopoverBreakpoint]: 'content-box' },
    },
    popoverHeader: {
      display: { base: 'flex', [modalToPopoverBreakpoint]: 'none' },
      mb: 5,
    },
  },
};
