export default `@font-face {
  font-family: "MuseoSansRounded";
  src: url("/fonts/MuseoSansRounded500.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansRounded";
  src: url("/fonts/MuseoSansRounded700.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}`;
