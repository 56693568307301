import { useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { Site } from '../../types';
import Module, { ModuleType } from '../Module';
import ExtendedTopSection from './ExtendedTopSection';
import MinimalBottomSection from './MinimalBottomSection';

export interface FooterProps {
  settings?: Site;
  hasMinimalFooter?: boolean;
}

const Footer = ({ settings, hasMinimalFooter }: FooterProps) => {
  const footerTheme = useMultiStyleConfig('Footer', { baseStyles: true });

  return (
    <Module className="Footer" type={ModuleType.DEFAULT} sx={footerTheme.module}>
      <footer>
        {!hasMinimalFooter && settings && <ExtendedTopSection settings={settings} />}
        <MinimalBottomSection settings={settings} hasMinimalFooter={hasMinimalFooter} />
      </footer>
    </Module>
  );
};

Footer.displayName = 'Footer';

export default Footer;
