import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import markerSvg from '../../icons/marker';
export default {
  parts: ['selectedMarker', 'marker'],
  baseStyle: {
    selectedMarker: {
      position: 'absolute',
      top: '-50px',
      left: '-29px',
      height: '75px',
      width: '58px',
      bg: svgUrl(markerSvg(colors.cyan)),
    },
    marker: {
      borderColor: 'primary.500',
      backgroundColor: 'primary.500',
      color: 'white',
    },
  },
};
