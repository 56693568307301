import { Button, ResponsiveValue, SystemStyleObject } from '@chakra-ui/react';
import { default as NextLink } from 'next/link';
import React from 'react';
import { Link as LinkType } from '../../types';
import { getRouteFromCmsUrl } from '../../utils/routes';

interface IProps {
  children: React.ReactNode;
  isInMobileNavWithChildNavItems?: boolean;
  link?: LinkType;
  name: string;
  sx?: SystemStyleObject;
  tabIndex?: number;
  variant?: ResponsiveValue<string> | undefined;
}

const ButtonLink = ({
  children,
  isInMobileNavWithChildNavItems = false,
  link,
  name,
  sx,
  tabIndex,
  variant,
  ...props
}: IProps) => {
  if (!link?.url || isInMobileNavWithChildNavItems)
    return (
      <Button data-name={name} {...props} sx={sx} variant={variant} textAlign="left">
        {children}
      </Button>
    );
  return (
    <Button
      {...props}
      as={NextLink}
      data-name={name}
      href={getRouteFromCmsUrl(link.url)}
      sx={sx}
      tabIndex={tabIndex}
      target={link.isNewWindow ? '_blank' : undefined}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default ButtonLink;
