export const HTML_DOCUMENT_PREFIX = 'ui';

export const documentClassCategory = {
  FEATURE: 'feature',
  PAGE_THEME: 'pageTheme',
} as const;
export type documentClassCategory =
  (typeof documentClassCategory)[keyof typeof documentClassCategory];

export const parseAsHtmlDocumentClass = (
  name: string | undefined,
  category: documentClassCategory
) => {
  if (!name) return '';
  return `${HTML_DOCUMENT_PREFIX}-${category}-${name}`;
};

export const feature = {
  IS_B2B: `isB2b`,
} as const;
export type Feature = (typeof feature)[keyof typeof feature];
