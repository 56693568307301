import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import infoFillTooltipSvg from '../../icons/infoFillTooltipSvg';

const standardRadius = '5';

const standardIconStyles = {
  alignSelf: 'center',
  w: '4',
  h: '4',
  ml: '1.5',
};

// needed so we can use border shorthand
const borderColor = colors.primary['100'];
// needed to pass to SVG
const iconColor = colors.primary['500'];

export default {
  baseStyle: {
    container: {
      py: '0',
      fontSize: 'sm',
    },
    whiteInfoIcon: {
      ...standardIconStyles,
      bg: svgUrl(infoFillTooltipSvg('white')),
    },
    primaryInfoIcon: {
      ...standardIconStyles,
      bg: svgUrl(infoFillTooltipSvg(iconColor)),
      fill: 'primary.500',
    },
    selectedOption: {
      background: 'primary.500',
      color: 'white',
      border: `1px solid ${borderColor}`,
      _hover: {
        background: 'primary.500',
      },
    },
    option: {
      display: 'flex',
      cursor: 'pointer',
      border: `1px solid ${borderColor}`,
      borderBottom: 'none',
      alignItems: 'center',
      transition: 'all 0.25s',
      _hover: {
        background: 'primary.100',
      },
      _first: {
        borderTopRightRadius: standardRadius,
        borderTopLeftRadius: standardRadius,
      },
      _last: {
        borderBottomRightRadius: standardRadius,
        borderBottomLeftRadius: standardRadius,
        borderBottom: `1px solid ${borderColor}`,
      },
    },
    disabled: {
      color: 'gray.400',
      cursor: 'not-allowed',
      _hover: {
        background: 'none',
      },
    },
    cheapestTag: {
      px: '2',
      borderRadius: 'xl',
      ml: 'auto',
      bg: 'primary.100',
      color: 'gray.700',
      fontSize: '12px',
    },
  },
};
