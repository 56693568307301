export default {
  baseStyle: {
    // flex can not be used with flickity items
    // combines number of slides in design with breakpoints
    '&.show-1-slides': {
      w: '100%', // Occupying the full width available
    },
    '&.show-2-slides': {
      w: {
        sm: '100%', // On small screens, each slide takes up the full width
        md: 'calc(50% - 1%)', // On medium and larger screens, two slides will share the width equally with a total of 2% gap distributed evenly
        lg: 'calc(50% - 1%)',
        xl: 'calc(50% - 1%)',
      },
    },
    '&.show-3-slides': {
      w: {
        sm: '100%', // On small screens, each slide takes up the full width
        md: 'calc(50% - 1%)', // On medium screens, we are still showing two slides with a total of 2% gap
        lg: 'calc(33.3333% - 0.6667%)', // On large and x-large screens, the total 2% gap is distributed across the three slides
        xl: 'calc(33.3333% - 0.6667%)',
      },
    },
    '&.show-4-slides': {
      w: {
        sm: '100%', // On small screens, each slide takes up the full width
        md: 'calc(50% - 1%)', // On medium screens, we are showing two slides with a total of 2% gap
        lg: 'calc(33.3333% - 0.6667%)', // On large screens, we are showing three slides with a total of 2% gap
        xl: 'calc(25% - 0.5%)', // On x-large screens, the total 2% gap is distributed across the four slides
      },
    },
    '&.show-5-slides': {
      w: {
        sm: '100%', // On small screens, each slide takes up the full width
        md: 'calc(50% - 1%)', // On medium screens, we are showing two slides with a total of 2% gap
        lg: 'calc(33.3333% - 0.6667%)', // On large screens, we are showing three slides with a total of 2% gap
        xl: 'calc(20% - 0.4%)', // On x-large screens, the total 2% gap is distributed across the five slides
      },
    },
  },
};
