import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import arrowRight from '../../icons/arrowRight';
import chevronDownSvg from '../../icons/chevronDownSvg';
import filterSvg from '../../icons/filterSvg';
import infoFillTooltipSvg from '../../icons/infoFillTooltipSvg';

export default {
  parts: [
    'heading',
    'infoIcon',
    'viewMoreButtonIcon',
    'sort',
    'sortWrapper',
    'price',
    'totalPrice',
    'sortIcon',
    'filterIcon',
  ],
  baseStyle: {
    heading: {},
    infoIcon: {
      bg: ({ colors }: StyleFunctionProps) => svgUrl(infoFillTooltipSvg(colors.primary['500'])),
      backgroundRepeat: 'no-repeat',
      w: 6,
      h: 6,
    },
    viewMoreButtonIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(arrowRight(colors.gray['700'])),
      backgroundRepeat: 'no-repeat',
      w: 3,
      h: 3,
      ml: 3,
    },
    price: {
      fontWeight: 'semibold',
    },
    totalPrice: {},
    sortIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) =>
        svgUrl(chevronDownSvg(colors.primary['700'])),
      backgroundSize: 'contain',
      w: '14px',
      h: '14px',
    },
    filterIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(filterSvg(colors.primary['700'])),
      backgroundSize: 'contain',
      w: '14px',
      h: '14px',
    },
  },
};
