import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps /*, AppContext */ } from 'next/app';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import ApplyAppBootstrap from '../components/App/ApplyAppBootstrap';
import ApplyAppHead, { ApplyAppHeadWithContentAPIData } from '../components/App/ApplyHead';
import ApplyPageThemeClientSide from '../components/App/ApplyPageThemeClientSide';
import UseHydratedContentApiData from '../components/App/UseHydratedContentApiData';
import ContactBar from '../components/ContactBar';
import { AppErrorBoundary } from '../components/ErrorBoundary';
import Footer from '../components/Footer';
import Header from '../components/Header';
import theme from '../theme';

const App = ({ Component: PageComponent, pageProps }: AppProps) => (
  <>
    {/* Add our default markup into the document Head */}
    <ApplyAppHead />
    {/* Wrap entire App stack in Chakra theme */}
    <ChakraProvider theme={theme}>
      {/* App client side protected through ErrorBoundary */}
      <AppErrorBoundary>
        {/* Initialise any App dependant non page specific functionality */}
        <ApplyAppBootstrap>
          {({ showReactQueryDevTools, queryClient, featureIsB2b }) => (
            <>
              {/* Initialise ReactQuery Provider */}
              <QueryClientProvider client={queryClient}>
                {/* Now hydrate our dehydratedState into ReactQuery cache */}
                <Hydrate state={pageProps.dehydratedState}>
                  {/* Utilise Page and Site Settings from hydrated state */}
                  <UseHydratedContentApiData ssrResolvedUrl={pageProps.ssrResolvedUrl}>
                    {({
                      headerVariant,
                      pageData,
                      renderContactBar,
                      renderFooter,
                      renderHeader,
                      renderHeadWithContentAPIData,
                      settings,
                    }) => (
                      <>
                        {/* Finally render our App Page Parts */}
                        <>
                          {/* Contact Bar Component */}
                          {renderContactBar && (
                            <ContactBar showAgentStatus={featureIsB2b} variant={headerVariant} />
                          )}
                          {/* Header Component */}
                          {renderHeader && <Header settings={settings} variant={headerVariant} />}
                          {/* Page Component */}
                          <PageComponent
                            {...pageProps}
                            bannerItem={pageData?.bannerItem}
                            breadcrumb={pageData?.breadcrumb}
                            browserTitle={pageData?.browserTitle}
                            contentPlaceholder={pageData?.contentPlaceholder}
                            hasTransparentHeader={pageData?.hasTransparentHeader}
                            pageThemeItem={pageData?.pageThemeItem}
                            shortFormImage={pageData?.shortFormImage}
                          />
                          {/* Footer Component */}
                          {renderFooter && (
                            <Footer
                              settings={settings}
                              hasMinimalFooter={pageData?.hasMinimalFooter}
                            />
                          )}
                        </>
                        {/* On client side router navigation we apply page specific themes */}
                        <ApplyPageThemeClientSide themeKey={pageData?.pageThemeItem?.themeKey} />
                        {/* Add / update page specific markup specific to page data */}
                        {renderHeadWithContentAPIData && (
                          <ApplyAppHeadWithContentAPIData
                            siteName={settings?.siteName}
                            pageData={pageData}
                          />
                        )}
                      </>
                    )}
                  </UseHydratedContentApiData>
                  {/* Show React Query Dev Tools outside of production */}
                  {showReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
                </Hydrate>
              </QueryClientProvider>
            </>
          )}
        </ApplyAppBootstrap>
      </AppErrorBoundary>
    </ChakraProvider>
  </>
);

export default App;
