import Typography, {
  bodyMedium,
  bodySmall,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  headingFontColor,
} from '../../config/typography';

export default {
  baseStyle: {
    fontFamily: Typography.fonts.heading,
    fontWeight: Typography.fontWeights.semibold,
    color: headingFontColor,
  },
  sizes: {
    xs: {
      fontSize: bodySmall,
    },
    sm: {
      fontSize: bodyMedium,
    },
    md: {
      fontSize: h6,
    },
    lg: {
      fontSize: h5,
      lineHeight: 1.2,
    },
    xl: {
      fontSize: h4,
      lineHeight: 1.2,
    },
    '2xl': {
      fontSize: h3,
      lineHeight: 1.3333333,
    },
    '3xl': {
      fontSize: h2,
      lineHeight: 1.2,
    },
    '4xl': {
      fontSize: h1,
      lineHeight: 1.2, // this is 1.1 in steve designs but because he uses the default chakra title, but he does want 1.2.
    },
  },
};
