export default {
  parts: ['content', 'heading', 'button'],
  baseStyle: {
    heading: {
      color: 'white',
      fontSize: { base: '36px', md: '48px', lg: '60px' },
      lineHeight: { base: '40px', md: '60px' },
      fontWeight: { base: 600, md: 700 },
    },
    button: {
      bg: 'secondary.500',
      _hover: {
        bg: 'secondary.700',
      },
    },
  },
};
