import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(accordionAnatomy.keys);
const ExpandingContentList = helpers.defineMultiStyleConfig({
  baseStyle: {
    icon: {
      width: '2.25rem',
      height: '2.25rem',
      color: 'var(--global-background-color)',
    },
    listTitle: {},
    itemTitle: {
      fontSize: { base: 'lg', md: 'xl' },
    },
  },
});

export default ExpandingContentList;
