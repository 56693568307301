import React, { useEffect, useRef } from 'react';
import { PageTheme } from '../../theme';
import { documentClassCategory, parseAsHtmlDocumentClass } from '../../utils/documentClasses';

interface ApplyPageThemeClientSideProps {
  children?: React.ReactNode;
  themeKey?: string;
}

const ApplyPageThemeClientSide = ({ children, themeKey }: ApplyPageThemeClientSideProps) => {
  const previousThemeRef = useRef<string | null>(themeKey || null);

  // Alter the theme class globally in html to manage theme changes when the route changes on the client-side.
  // See _document.tsx for server-side rendering logic of initial page theme.
  useEffect(() => {
    // Verify if the themeKey exists in the PageTheme
    const isValidThemeKey =
      themeKey && PageTheme && Object.values(PageTheme).includes(themeKey as PageTheme);

    // If it doesn't exist, fallback to default theme ('')
    const themeKeyToApply = isValidThemeKey ? themeKey : '';

    // If theme has changed
    if (themeKeyToApply !== previousThemeRef.current) {
      //  and there was an existing theme, remove previous theme
      if (previousThemeRef.current) {
        const className = parseAsHtmlDocumentClass(
          previousThemeRef.current,
          documentClassCategory.PAGE_THEME
        );
        document.documentElement.classList.remove(className);
      }
      // and apply the new theme
      if (themeKeyToApply) {
        const className = parseAsHtmlDocumentClass(
          themeKeyToApply,
          documentClassCategory.PAGE_THEME
        );
        document.documentElement.classList.add(className);
      }
    }
    // Change the ref to the new theme to check in future page route changes
    previousThemeRef.current = themeKeyToApply;
  }, [themeKey]);

  return <>{children ? children : null}</>;
};

export default ApplyPageThemeClientSide;
