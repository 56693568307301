import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import arrowDown from '../../icons/arrowDown';
import arrowLeft from '../../icons/arrowLeft';

export default {
  parts: [
    'backButton',
    'backButtonIcon',
    'innerLeft',
    'left',
    'lower',
    'module',
    'resultText',
    'right',
    'sticky',
    'upper',
    'wrapper',
  ],
  baseStyle: {
    backButton: {
      borderRadius: { base: 'full', lg: 'sm' },
      color: 'primary.500',
    },
    backButtonIcon: {
      width: { base: 5, md: 3 },
      height: { base: 5, md: 4 },
      transform: { base: 'none', md: 'scale(0.8) rotate(90deg)' },
      transformOrigin: 'center center',
      backgroundImage: ({ colors }: StyleFunctionProps) => ({
        base: svgUrl(arrowLeft('white')),
        md: svgUrl(arrowDown(colors.primary[500])),
      }),
      backgroundRepeat: 'no-repeat',
    },
    resultText: {
      fontWeight: 500,
      fontSize: '18px',
      display: 'inline',
    },
    resultCount: {
      fontWeight: 700,
    },
    tierOne: {
      h: ({ globals }: StyleFunctionProps) => globals.searchHeight,
    },
    tierTwo: {
      h: ({ globals }: StyleFunctionProps) => globals.searchHeight,
    },
    tierThree: {},
    module: {
      boxShadow: 'base',
    },
    left: {},
    right: {},
    sticky: {
      background: 'white',
    },
    backButtonText: {
      color: 'primary.500',
    },
  },
};
