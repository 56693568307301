import { Container, useTheme } from '@chakra-ui/react';
import React from 'react';

export enum ModuleType {
  DEFAULT, // maxWidth of xl breakpoint
  FULL_WIDTH,
  FULL_WIDTH_NO_PADDING,
  CONTENT_WIDTH, // maxWidth of 4xl size, perfect for content block text
}

export enum MarginType {
  DEFAULT,
  SNUG,
  LARGE,
  SMALL,
  SNUG_BOTH,
}

const Module = ({ marginType, type, ...chakraProps }: any) => {
  const theme = useTheme();
  let marginTop = theme.globals.moduleGutter;
  if (marginType === MarginType.SNUG) marginTop = theme.globals.moduleGutterSnug;
  if (marginType === MarginType.SNUG_BOTH) marginTop = theme.globals.moduleGutterSnug;
  if (marginType === MarginType.SMALL) marginTop = theme.globals.moduleGutterSmall;
  if (marginType === MarginType.LARGE) marginTop = theme.globals.moduleGutterLarge;

  let sx: any = {};
  sx.marginTop = marginTop;
  sx['&:nth-of-type(1)'] = { marginTop: '0' }; // all have margin except for the very top
  if (type !== ModuleType.FULL_WIDTH) {
    sx.paddingLeft = '0';
    sx.paddingRight = '0';
  }
  sx[`.Module-marginType4 + &`] = {
    mt: theme.globals.moduleGutterSnug,
  };

  // pull in props.sx for outer styling of container
  sx = { ...sx, ...chakraProps.sx };

  const containerClasses = ['Module'];
  if (marginType) containerClasses.push(`Module-marginType${marginType}`);
  if (type) containerClasses.push(`Module-type${type}`);
  if (chakraProps.className) containerClasses.push(chakraProps.className);

  return (
    <Container
      as={'div'} // TODO make work for non div, currently only works for div due to nth-of-type(1) above
      className={containerClasses.join(' ')}
      width="100%"
      maxWidth="none"
      {...chakraProps}
      sx={sx}
    >
      {type === ModuleType.DEFAULT || type === ModuleType.CONTENT_WIDTH || type === undefined ? (
        <Container maxWidth={type === ModuleType.CONTENT_WIDTH ? '4xl' : theme.breakpoints.xl}>
          {chakraProps.children}
        </Container>
      ) : (
        <>{chakraProps.children}</>
      )}
    </Container>
  );
};

export const withModule = (WrappedComponent: React.ComponentType<any>, moduleType?: ModuleType) => {
  const withModule = (props: React.PropsWithChildren<any>) => {
    switch (moduleType) {
      case ModuleType.FULL_WIDTH:
        return (
          <Module type={ModuleType.FULL_WIDTH}>
            <WrappedComponent {...props} />
          </Module>
        );
      default:
        return (
          <Module>
            <WrappedComponent {...props} />
          </Module>
        );
    }
  };
  withModule.displayName = 'WithModule';
  return withModule;
};

export default Module;
