export default {
  parts: ['inputContainer', 'input', 'inputLeft', 'slider'],
  baseStyle: {
    inputContainer: {
      width: 'var(--chakra-sizes-24)',
    },
    input: {
      paddingInlineStart: 'var(--chakra-space-6)',
      paddingInlineEnd: 'var(--chakra-space-2)',
    },
    inputLeft: {
      width: 'var(--chakra-space-8)',
      color: 'var(--chakra-color-gray-700)',
    },
    slider: {},
    sliderThumb: {
      boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.2)',
      overflow: 'visible',
    },
  },
};
