import { svgUrl } from '../../../../utils/graphics';
import menuSvg from '../../icons/menu';

export default {
  parts: [
    'anchor',
    'homeIcon',
    'dividerIcon',
    'text',
    'textCenter',
    'stack',
    'module',
    'menuButton',
    'menuIcon',
    'menuItem',
    'popoverContent',
    'popoverArrow',
  ],
  baseStyle: {
    homeIcon: {
      backgroundImage: 'var(--global-breadcrumb-home-icon)',
      backgroundSize: '20px 20px',
    },
    dividerIcon: {
      backgroundImage: 'var(--global-navigation-chevron-right-icon)',
      backgroundSize: '20px 20px',
    },
    menuButton: {
      borderRadius: '100%', // override the default button border radius
      height: 10, // override the default lg button to md
      minWidth: 10, // override the default lg button to md
      px: 4, // override the default lg button to md
    },
    menuIcon: {
      backgroundImage: svgUrl(menuSvg()),
    },
    menuItem: {
      fontSize: 'sm',
    },
    stack: {
      borderBottomWidth: '1px',
    },
    text: {
      fontWeight: 600,
    },
  },
};
