import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import cardSvg from '../../icons/cardSvg';
import infoSvg from '../../icons/infoFillTooltipSvg';

export default {
  parts: [],
  baseStyle: {
    wrapper: {
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
      bg: 'white',
      mb: ({ globals }: StyleFunctionProps) =>
        `calc((var(--chakra-sizes-${globals.moduleGutter}) + var(--chakra-sizes-4)) * -1)`,
    },
    dividerBorderBottom: {
      borderBottomWidth: { base: 0, md: '1px' },
      borderColor: 'primary.100',
    },
    dividerBorderRight: {
      borderRightWidth: { base: 0, lg: '1px' },
      borderColor: 'primary.100',
    },
    dividerBorderTopMobile: {
      borderTopWidth: { base: '1px', md: 0 },
      borderColor: 'primary.100',
    },
    cardIcon: {
      h: '20px',
      w: '25px',
      backgroundImage: svgUrl(cardSvg(colors.primary[500])),
    },
    amountInput: {
      borderRadius: 'md',
      color: 'gray.700',
      fontSize: 'lg',
    },
    infoIcon: {
      h: '20px',
      w: '20px',
      backgroundImage: svgUrl(infoSvg(colors.primary[500])),
    },
    infoText: {
      fontSize: 'sm',
      fontWeight: 400,
      color: 'secondary.500',
    },
    infoPrice: {
      fontSize: 'sm',
      fontWeight: 400,
      color: 'secondary.500',
    },
    totalPriceLabel: {
      fontSize: 'sm',
      fontWeight: 500,
      color: 'gray.600',
    },
    paymentDueLabel: {
      fontSize: 'md',
      fontWeight: 400,
      color: 'secondary.500',
    },
    packagePriceLabelText: {
      fontSize: 'md',
      fontWeight: 600,
      color: 'gray.700',
    },
    paymentDateText: {
      fontSize: 'md',
      fontWeight: 400,
      color: 'secondary.500',
    },
    tabText: {
      fontSize: 'md',
      fontWeight: 600,
      color: 'primary.500',
    },
    tab: {
      border: '1px solid',
      borderColor: 'primary.100',
      bg: 'primary.100',
      color: 'primary.500',
      _hover: {
        bg: 'primary.100',
      },
    },
    selectedTab: {
      position: 'relative',
      borderColor: 'primary.500',
      bg: 'primary.500',
      color: 'white',
      _hover: {
        bg: 'primary.500',
      },
      _after: {
        content: '""',
        w: '0',
        h: '0',
        border: '7px solid transparent',
        borderTopColor: 'primary.500',
        transform: 'translateX(-50%)',
        position: 'absolute',
        left: '50%',
        bottom: '-13px',
      },
    },
  },
};
