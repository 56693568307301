import { StyleFunctionProps } from '@chakra-ui/react';

export default {
  parts: ['nav', 'button'],
  baseStyle: {
    nav: {
      padding: { base: '16px 0 30px 0', lg: 8 },
      color: 'gray.600',
      fontWeight: 500,
      borderBottom: ({ colors }: StyleFunctionProps) => ({
        base: 0,
        lg: `1px solid ${colors.primary[100]}`,
      }),
      '.active': {
        color: 'primary.500',
        borderBottom: ({ colors }: StyleFunctionProps) => ({
          base: 0,
          lg: `2px solid ${colors.primary[500]}`,
        }),
      },
    },
    button: {
      paddingX: 3,
      paddingY: 2,
      fontWeight: 600,
      color: 'gray.600',
      borderBottom: '2px solid white',
    },
  },
};
