import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  ResponsiveValue,
  SystemStyleObject,
  Text,
  useBreakpoint,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { Link as LinkProps, NavigationItem } from '../../types';
import useIsomorphicLayoutEffect from '../../utils/useIsomorphicLayoutEffect';
import ButtonLink from '../ButtonLink/ButtonLink';
import Module, { MarginType, ModuleType } from '../Module';

interface NavigationProps {
  variant?: ResponsiveValue<string>;
  mainNavigationItems?: NavigationItem[];
  secondaryNavigationItems?: LinkProps[];
  hasTransparentHeader?: boolean;
}

interface NavigationItemProps {
  _after?: SystemStyleObject;
  anchorSx?: SystemStyleObject;
  isInMobileNavWithChildNavItems?: boolean;
  item: NavigationItem;
  name: string;
  onClick?: () => void;
  textSx?: SystemStyleObject;
}

const NavigationItem = ({
  item,
  anchorSx,
  textSx,
  name = 'unknown',
  isInMobileNavWithChildNavItems = false,
  ...props
}: NavigationItemProps) => (
  <ButtonLink
    {...props}
    isInMobileNavWithChildNavItems={isInMobileNavWithChildNavItems}
    link={item.navigationItemLink}
    name={name}
    sx={anchorSx}
    variant="link"
  >
    <Text sx={textSx} whiteSpace="pre-wrap">
      {item.navigationItemLink ? item.navigationItemLink.title : item.navigationItemTitle}
    </Text>
  </ButtonLink>
);

const Navigation = ({
  variant = 'mobile',
  mainNavigationItems,
  secondaryNavigationItems,
  hasTransparentHeader,
}: NavigationProps) => {
  const router = useRouter();
  const navTheme = useMultiStyleConfig('Navigation', { variant });
  const breakpoint = useBreakpoint();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const [activeDrawerSlides, setActiveDrawerSlides] = useState<string[]>([]);
  const [activeNavId, setActiveNavId] = useState<string>('');
  const menuBoundaryRef = useRef<HTMLDivElement>(null);
  const drawerButtonRef = useRef<HTMLButtonElement>(null);

  const resetDrawer = () => {
    onDrawerClose();
    setActiveDrawerSlides([]);
  };

  const calculateColCount = (items: NavigationItem[]) => {
    const itemPerCol = 10;
    const totalItemsCount =
      items.length +
      items.reduce((count, current) => count + current.navigationItemItems.length, 0);
    const colCount = Math.ceil(totalItemsCount / itemPerCol);

    return colCount <= 5 ? colCount : 5;
  };

  const layoutNav = () => {
    document.querySelectorAll('[data-nav-id]').forEach((el) => el.classList.remove('active-nav'));
    document.querySelectorAll('.nav-menu').forEach((el) => el.removeAttribute('style'));

    const activeNavItem = document.querySelectorAll(`[data-nav-id="${activeNavId}"]`)[0];
    const navMenu = activeNavItem?.querySelector('.nav-menu') as HTMLElement;

    if (!navMenu || !menuBoundaryRef.current || !drawerButtonRef.current) return;

    const menuBoundaries = menuBoundaryRef.current.getBoundingClientRect();

    // if drawerButtonRef has width, it is not hidden which means it is mobile view
    if (drawerButtonRef.current.getBoundingClientRect().width) return;

    // if menuBoundaryRef has no width, it is hidden which means it is a smaller screen (< xl)
    const isSmallerScreen = !menuBoundaries.width;

    // if smaller (non-mobile) screen, fill width of screen
    // if larger screen, use menuBoundaryRef which follows the nav
    const gutterValue = isSmallerScreen ? 16 : 0; // 1rem
    const leftBoundary = isSmallerScreen ? 0 : menuBoundaries.left;
    const rightBoundary = isSmallerScreen ? window.innerWidth : menuBoundaries.right;

    navMenu.style.visibility = 'hidden';
    navMenu.style.opacity = '0';
    navMenu.style.display = 'block';
    navMenu.style.maxWidth = `${
      isSmallerScreen ? window.innerWidth - gutterValue * 2 : menuBoundaries.width
    }px`;

    const activeNavBoundaries = navMenu.getBoundingClientRect();

    if (activeNavBoundaries.left < leftBoundary) {
      navMenu.style.transform = `translateX(calc(-50% + ${
        leftBoundary - activeNavBoundaries.left + gutterValue
      }px))`;
    } else if (activeNavBoundaries.right > rightBoundary) {
      navMenu.style.transform = `translateX(calc(-50% - ${
        activeNavBoundaries.right - rightBoundary - gutterValue
      }px))`;
    }

    navMenu.style.visibility = 'visible';
    navMenu.style.opacity = '1';

    activeNavItem?.classList.add('active-nav');
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setActiveNavId('');
      resetDrawer();
    });

    const handleResize = debounce(() => setActiveNavId(''), 200);
    window.addEventListener('resize', handleResize, { passive: true });

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * We use useLayoutEffect here to ensure the mouseEnter and mouseLeave layoutNav are
   * immediately updated, rather than wait for a render, this stop a flicker that can
   * happen when rolling over two elements as raised in COHOB2CUAT-145. We use this
   * isomorphic workaround to allow this to work on the server. This is fine because
   * the activeNavId is only ever set upon client action therefore no hydration errors.
   **/
  useIsomorphicLayoutEffect(() => {
    layoutNav();
  }, [activeNavId]);

  useEffect(() => {
    if (isDrawerOpen) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    resetDrawer();
  }, [breakpoint]);

  return (
    <>
      <Module
        className="Navigation"
        marginType={MarginType.SNUG}
        type={ModuleType.FULL_WIDTH_NO_PADDING}
        sx={navTheme.module}
      >
        <Box
          ref={menuBoundaryRef}
          display={{ base: 'none', xl: 'block' }}
          pos="absolute"
          width="full"
        />
        {isDrawerOpen && <Box sx={navTheme.drawerOverlay} />}
        <Box className={isDrawerOpen ? 'drawer-open' : ''} sx={navTheme.drawer}>
          <Box sx={navTheme.drawerContent}>
            <Box
              sx={navTheme.drawerContentSlides}
              className={isDrawerOpen ? `level-${activeDrawerSlides.length + 1}` : ''}
            >
              <Box
                className={`level-active ${
                  isDrawerOpen && !activeDrawerSlides.length ? 'level-selected' : ''
                }`}
                sx={navTheme.navContainer}
              >
                <Flex as="nav" sx={navTheme.nav}>
                  {mainNavigationItems && (
                    <>
                      <HStack sx={navTheme.drawerHeader}>
                        <Box
                          backgroundSize="contain"
                          backgroundRepeat="no-repeat"
                          sx={navTheme.logo}
                        />
                        <Button
                          variant="solid"
                          onClick={() => resetDrawer()}
                          sx={navTheme.drawerHeaderButton}
                          aria-label="Close"
                        >
                          <Center>
                            <Box sx={navTheme.closeIcon} />
                          </Center>
                        </Button>
                      </HStack>
                      <Flex sx={navTheme.mainNavigation}>
                        {mainNavigationItems.map((level1Item) => (
                          <Box
                            key={level1Item.id}
                            sx={navTheme.mainNavigationItemContainer}
                            onMouseEnter={() => setActiveNavId(level1Item.id)}
                            onMouseLeave={() => setActiveNavId('')}
                            data-nav-id={level1Item.id}
                          >
                            {!level1Item.navigationItemItems.length && (
                              <NavigationItem
                                item={level1Item}
                                anchorSx={navTheme.mainNavigationItem}
                                textSx={navTheme.mainNavigationItemText}
                                name="1-no-children-both"
                              />
                            )}
                            {!!level1Item.navigationItemItems.length && (
                              <>
                                <NavigationItem
                                  name="1-with-children-both"
                                  item={level1Item}
                                  isInMobileNavWithChildNavItems={['sm', 'md'].includes(breakpoint)} // TODO, isMobile logic needs centralising, this is fix for Live Coop, so ok for now.
                                  anchorSx={navTheme.mainNavigationItem}
                                  textSx={navTheme.mainNavigationItemText}
                                  _after={navTheme.mainNavigationItemChevron}
                                  onClick={() =>
                                    setActiveDrawerSlides((prev) => [...prev, level1Item.id])
                                  }
                                />
                                <Box
                                  className={`nav-menu ${
                                    isDrawerOpen && activeDrawerSlides.includes(level1Item.id)
                                      ? 'level-active'
                                      : ''
                                  } ${
                                    isDrawerOpen &&
                                    activeDrawerSlides[activeDrawerSlides.length - 1] ===
                                      level1Item.id
                                      ? 'level-selected'
                                      : ''
                                  }`}
                                  sx={navTheme.flyOut}
                                >
                                  <HStack sx={navTheme.drawerHeader}>
                                    <Button
                                      variant="solid"
                                      onClick={() =>
                                        setActiveDrawerSlides((prev) => prev.slice(0, -1))
                                      }
                                      sx={navTheme.drawerHeaderButton}
                                      aria-label="Back to main navigation"
                                    >
                                      <Center>
                                        <Box sx={navTheme.backIcon} />
                                      </Center>
                                    </Button>
                                    <Text sx={navTheme.drawerHeaderTitle}>
                                      {level1Item.navigationItemTitle}
                                    </Text>
                                    <Button
                                      variant="solid"
                                      onClick={() => resetDrawer()}
                                      sx={navTheme.drawerHeaderButton}
                                      aria-label="Close"
                                    >
                                      <Center>
                                        <Box sx={navTheme.closeIcon} />
                                      </Center>
                                    </Button>
                                  </HStack>
                                  <Flex
                                    className={`col-count-${calculateColCount(
                                      level1Item.navigationItemItems
                                    )} ${level1Item.navigationItemLink ? 'has-link' : ''}`}
                                    sx={navTheme.linkGroup}
                                  >
                                    {level1Item.navigationItemLink && (
                                      <Box sx={navTheme.parentLink}>
                                        <NavigationItem
                                          name="1-back-base"
                                          item={level1Item}
                                          anchorSx={navTheme.mainNavigationSubItem}
                                          textSx={navTheme.mainNavigationItemText}
                                        />
                                      </Box>
                                    )}
                                    {level1Item.navigationItemItems.map((level2Item) => (
                                      <Box key={level2Item.id} sx={navTheme.linkBlock}>
                                        {!level2Item.navigationItemItems.length && (
                                          <NavigationItem
                                            name="2-no-children-both"
                                            item={level2Item}
                                            anchorSx={navTheme.mainNavigationSubItem}
                                            textSx={navTheme.mainNavigationItemText}
                                          />
                                        )}
                                        {!!level2Item.navigationItemItems.length && (
                                          <>
                                            <NavigationItem
                                              name="2-with-children-mobile"
                                              item={level2Item}
                                              isInMobileNavWithChildNavItems
                                              anchorSx={navTheme.mainNavigationSubItemMobileOnly}
                                              textSx={navTheme.mainNavigationItemText}
                                              _after={navTheme.mainNavigationItemChevron}
                                              onClick={() =>
                                                setActiveDrawerSlides((prev) => [
                                                  ...prev,
                                                  level2Item.id,
                                                ])
                                              }
                                            />
                                            <NavigationItem
                                              name="2-with-children-desktop"
                                              item={level2Item}
                                              anchorSx={navTheme.mainNavigationSubItemDesktopOnly}
                                              textSx={navTheme.mainNavigationItemText}
                                              _after={navTheme.mainNavigationItemChevron}
                                              onClick={() =>
                                                setActiveDrawerSlides((prev) => [
                                                  ...prev,
                                                  level2Item.id,
                                                ])
                                              }
                                            />
                                            <Box
                                              className={`${
                                                isDrawerOpen &&
                                                activeDrawerSlides.includes(level2Item.id)
                                                  ? 'level-active'
                                                  : ''
                                              } ${
                                                isDrawerOpen &&
                                                activeDrawerSlides[
                                                  activeDrawerSlides.length - 1
                                                ] === level2Item.id
                                                  ? 'level-selected'
                                                  : ''
                                              }`}
                                              sx={navTheme.baseLevel}
                                            >
                                              <HStack sx={navTheme.drawerHeader}>
                                                <Button
                                                  variant="solid"
                                                  onClick={() =>
                                                    setActiveDrawerSlides((prev) =>
                                                      prev.slice(0, -1)
                                                    )
                                                  }
                                                  sx={navTheme.drawerHeaderButton}
                                                  aria-label={`Back to ${level1Item.navigationItemTitle}`}
                                                >
                                                  <Center>
                                                    <Box sx={navTheme.backIcon} />
                                                  </Center>
                                                </Button>
                                                <Text sx={navTheme.drawerHeaderTitle}>
                                                  {level2Item.navigationItemTitle}
                                                </Text>
                                                <Button
                                                  variant="solid"
                                                  onClick={() => resetDrawer()}
                                                  sx={navTheme.drawerHeaderButton}
                                                  aria-label="Close"
                                                >
                                                  <Center>
                                                    <Box sx={navTheme.closeIcon} />
                                                  </Center>
                                                </Button>
                                              </HStack>
                                              <Flex sx={navTheme.linkSubGroup}>
                                                {level2Item.navigationItemLink && (
                                                  <Box sx={navTheme.parentLink}>
                                                    <NavigationItem
                                                      name="2-back-base"
                                                      item={level2Item}
                                                      anchorSx={navTheme.mainNavigationSubItem}
                                                      textSx={navTheme.mainNavigationItemText}
                                                    />
                                                  </Box>
                                                )}
                                                {level2Item.navigationItemItems.map(
                                                  (level3Item) => (
                                                    <Box key={level3Item.id}>
                                                      <NavigationItem
                                                        name="3-no-children-both"
                                                        item={level3Item}
                                                        anchorSx={navTheme.mainNavigationSubItem}
                                                        textSx={navTheme.mainNavigationSubItemText}
                                                      />
                                                    </Box>
                                                  )
                                                )}
                                              </Flex>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                    ))}
                                  </Flex>
                                  {level1Item.navigationItemLink && (
                                    <NavigationItem
                                      name="1-back-base-bottom-of-drawer-desktop"
                                      item={level1Item}
                                      anchorSx={navTheme.bottomSelfLink}
                                      textSx={navTheme.mainNavigationItemText}
                                    />
                                  )}
                                </Box>
                              </>
                            )}
                          </Box>
                        ))}
                      </Flex>
                      {secondaryNavigationItems && (
                        <Flex sx={navTheme.drawerSecondaryNavigation}>
                          {secondaryNavigationItems.map((item, index) => (
                            <ButtonLink
                              name="secondary-mobile"
                              sx={navTheme.secondaryNavigationItem}
                              key={item.url + index}
                              link={item}
                            >
                              {item.title}
                            </ButtonLink>
                          ))}
                        </Flex>
                      )}
                    </>
                  )}
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Module>
      {secondaryNavigationItems && (
        <Flex display={{ base: 'none', md: 'flex' }} sx={navTheme.secondaryNavigation}>
          {secondaryNavigationItems.map((item, index) => (
            <ButtonLink
              name="secondary-desktop"
              key={item.url + index}
              link={item}
              variant={index === 1 ? 'outlineLight' : hasTransparentHeader ? 'solid' : 'solidLight'}
              sx={navTheme.secondaryNavigationItem}
            >
              {item.title}
            </ButtonLink>
          ))}
        </Flex>
      )}
      <Button
        type="button"
        ref={drawerButtonRef}
        variant="unstyled"
        onClick={onDrawerOpen}
        aria-label="Navigation Menu"
        sx={navTheme.burgerButton}
      >
        <Center aria-hidden="true">
          <Box sx={navTheme.burgerIcon} />
        </Center>
      </Button>
    </>
  );
};

Navigation.displayName = 'Navigation';

export default Navigation;
