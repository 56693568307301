import getConfig from 'next/config';
import { axiosGet, getContentApi } from '../../utils/api';

export const DICTIONARY_QUERY_KEY = 'dictionary';

const API_ENDPOINT = getConfig().publicRuntimeConfig?.dictionaryApiEndpoint;
if (!API_ENDPOINT) {
  throw Error(`Error: No dictionaryApiEndpoint specified within publicRuntimeConfig,
  expecting something like dictionary?culture=en-GB&isPreview=false`);
}

export const getDictionary = async () => {
  const url = `${getContentApi()}/${API_ENDPOINT}`;

  const response = await axiosGet(url);

  // ensure undefined is not returned upon error to avoid SerializableError
  const dictionary = response?.data || {};

  // axios return a lot of data
  return Promise.resolve(dictionary);
};

export default getDictionary;
