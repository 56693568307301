import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../../utils/graphics';
import colors from '../../../config/colors';
import luggageNoSvg from '../../../icons/luggageNoSvg';
import luggageSvg from '../../../icons/luggageSvg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'baggageIncludedIcon',
  'baggageNotIncludedIcon',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    baggageIncludedIcon: {
      h: '20px',
      w: '14px',
      backgroundImage: svgUrl(luggageSvg(colors.primary[500])),
    },
    baggageNotIncludedIcon: {
      h: '21px',
      w: '21px',
      backgroundImage: svgUrl(luggageNoSvg(colors.primary[500])),
    },
  },
});
