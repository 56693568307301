import { CULTURE } from '../../constants/i18n';
import { SITE_ID } from '../../constants/routes';
import { Site } from '../../types';
import { axiosGet, getContentApi } from '../../utils/api';

export const SITE_QUERY_KEY = 'site';

const getSettings = async () => {
  const url = `${getContentApi()}/settings/${SITE_ID}?culture=${CULTURE}&isPreview=false`;

  const response = await axiosGet(url);

  const settings: Site = response?.data || null;

  if (!settings) {
    throw Error(`Error: settings from /settings API is ${settings}`);
  }

  // ensures undefined is not returned upon error to avoid SerializableError
  // axios return a lot of data
  return Promise.resolve({ settings });
};

export default getSettings;
