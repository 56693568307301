import { StyleFunctionProps } from '@chakra-ui/react';
import { chakraZIndexes } from '../../../../constants/zIndex';
import { updateResponsiveValue } from '../../../../utils/chakra-ui';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import globals from '../../config/globals';
import alertSvg from '../../icons/alertSvg';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import bedSvg from '../../icons/bedSvg';
import calendar from '../../icons/calendar';
import coachSvg from '../../icons/coachSvg';
import luggageSvg from '../../icons/luggageSvg';
import planeSvg from '../../icons/planeSvg';
import tickInCircle from '../../icons/tickInCircle';

export default {
  parts: [],
  baseStyle: {
    tag: {
      fontSize: 'xs',
      fontWeight: 500,
      color: 'primary.500',
      backgroundColor: colors.primary[100],
    },
    guestFormHeader: {
      fontSize: 'lg',
      fontWeight: 600,
      color: 'primary.500',
    },
    formLabel: {
      mt: { base: 4, '2xl': 6 },
      fontSize: 'sm',
    },
    checkbox: {
      _hover: {
        bg: 'primary.300',
        borderColor: 'primary.200',
      },
      'span[data-checked]': {
        bg: 'primary.500',
        color: 'white',
        borderColor: 'primary.700',
        _hover: {
          bg: 'primary.600',
          borderColor: 'primary.600',
        },
      },
    },
    dateOfBirthInput: {},
    LeadPassengerSummaryFlex: {
      // borderBottom: `1px solid ${colors.gray[200]}`,
      position: 'relative',
      pb: 10,
      _after: {
        content: '""',
        position: 'absolute',
        bottom: 5,
        left: -5,
        right: -5,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
      },
    },
    excursionGuestFormBox: {
      _notLast: {
        _after: {
          content: '""',
          position: 'absolute',
          bottom: -8,
          left: -5,
          right: -5,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
        },
      },
    },
    leadPassengerSummaryBoldText: {
      color: 'primary.500',
    },

    // ICONS
    calendarIcon: {
      mt: 2,
      w: 4,
      h: 4,
      bg: svgUrl(calendar(colors.gray['400'])),
    },
    alertIcon: {
      w: 4,
      h: 4,
      bg: svgUrl(alertSvg(colors.primary[500])),
    },
    coachIcon: {
      h: { base: '21px', md: '24px' },
      w: { base: '16px', md: '20px' },
      backgroundImage: svgUrl(coachSvg(colors.gray[700])),
    },
    luggageIcon: {
      h: { base: '21px', md: '26px' },
      w: { base: '15px', md: '18px' },
      backgroundImage: svgUrl(luggageSvg(colors.gray[700])),
    },
    tickIcon: {
      bg: svgUrl(tickInCircle(colors.primary[400])),
      h: 6,
      w: 6,
    },

    // Miniquote labels
    stickyContainer: {
      backgroundColor: 'white',
      zIndex: chakraZIndexes.sticky,
      left: 0,
      right: 0,
      // increase max width to cover large box shadows beneath
      width: {
        base: `calc(100% + ${parseInt(globals.gutter.base, 10) * 2}px)`,
        md: `calc(100% + ${parseInt(globals.gutter.md, 10) * 2}px)`,
        lg: '330px',
      },
      transform: {
        base: `translateX(-${parseInt(globals.gutter.base, 10)}px)`,
        md: `translateX(-${parseInt(globals.gutter.md, 10)}px)`,
        lg: 'unset',
      },
      top: ({ globals }: StyleFunctionProps) => updateResponsiveValue(globals.searchHeight, 32),
      position: { base: 'static', lg: 'sticky' },
    },
    bedIcon: {
      h: 4,
      w: '22px',
      backgroundImage: svgUrl(bedSvg(colors.gray[700])),
    },
    planeIcon: {
      h: 5,
      w: 5,
      backgroundImage: svgUrl(planeSvg(colors.gray[700])),
    },
    upArrow: {
      h: '9px', // specific dimensions
      w: '15px', // specific dimensions
      backgroundImage: svgUrl(arrowUp(colors.primary[500])),
    },
    downArrow: {
      h: '9px', // specific dimensions
      w: '15px', // specific dimensions
      backgroundImage: svgUrl(arrowDown(colors.primary[500])),
    },
    containerBox: {
      width: {
        base: `calc(100% - ${parseInt(globals.gutter.base, 10) * 2}px)`,
        md: `calc(100% - ${parseInt(globals.gutter.md, 10) * 2}px)`,
        lg: '330px',
      },
      transform: {
        base: `translateX(${parseInt(globals.gutter.base, 10)}px)`,
        md: `translateX(${parseInt(globals.gutter.md, 10)}px)`,
        lg: 'unset',
      },
      borderTop: `1px solid ${colors.gray[200]}`,
      borderBottom: `1px solid ${colors.gray[200]}`,
      borderLeft: { base: 'none', lg: `1px solid ${colors.gray[200]}` },
      borderRight: { base: 'none', lg: `1px solid ${colors.gray[200]}` },
      borderRadius: { base: 'unset', lg: '10px' },
    },
    totalPrice: {
      fontSize: 'sm',
      color: 'gray.600',
      fontWeight: 500,
      mt: 0,
    },
    price: { fontSize: { base: '2xl', lg: '3xl' }, color: 'gray.700', fontWeight: 600, mt: 0 },
    sectionTitle: { fontSize: 'lg', color: 'gray.700', fontWeight: 600 },
    location: { fontSize: 'sm', color: 'gray.600', fontWeight: 500 },
    nights: { fontSize: 'sm', color: 'gray.700', fontWeight: 400 },
    roomHeader: { fontSize: 'sm', color: 'black', fontWeight: 600 },
    sectionText: { fontSize: 'sm', color: 'gray.700', fontWeight: 400 },
    sectionTextStrong: { fontSize: 'sm', color: 'gray.700', fontWeight: 500 },
    offerBadgeContainer: {
      mb: { base: '-3px', lg: '-2px' },
    },
    offerBadge: {
      bgColor: 'primary.100',
      borderRadius: 'full',
      fontWeight: 'medium',
    },
    priceContainer: {
      pt: { base: 4, md: 5, lg: '22px' },
      pb: { base: 0, lg: '18px' },
      alignItems: 'flex-end',
    },
    totalPriceContainer: {},
    totalPriceAmount: {},
    originalPriceContainer: {
      display: 'flex',
      height: '42px',
      fontWeight: 400,
      mb: { base: '2px', lg: '3px' }, // specific dimensions
    },
    originalPrice: {
      textDecoration: 'line-through',
      alignSelf: 'flex-end',
    },
    borderLine: {
      borderBottom: `1px solid ${colors.gray[200]}`,
    },
    flightLegTimeSlipIndicator: {
      color: 'promo.500',
      fontWeight: 'semibold',
    },
    infoTooltipIcon: {
      bg: 'var(--global-info-fill-tooltip-icon)',
      h: 4,
      w: 4,
    },
  },
};
