import { StyleFunctionProps } from '@chakra-ui/theme-tools';

export default {
  parts: [],
  baseStyle: {
    wrapper: {
      bottom: 0,
      mb: ({ globals }: StyleFunctionProps) =>
        `calc((var(--chakra-sizes-${globals.moduleGutter}) + var(--chakra-sizes-4)) * -1)`,
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1);',
      bg: 'white',
    },
    headingText: { color: 'gray.600', fontWeight: 500, fontSize: 'sm' },
    priceText: { color: 'gray.700', fontWeight: 600, fontSize: '3xl' },
  },
};
