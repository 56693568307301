import { svgUrl } from '../../../../utils/graphics';
import phoneSvg from '../../../coop/icons/phoneSvg';
import emailSvg from '../../icons/emailSvg';

export default {
  parts: [
    'module',
    'logo',
    'divider',
    'copyright',
    'text',
    'mainNavTitle',
    'mainNavLink',
    'secondaryNavLink',
    'socialIcon',
    'footerImage',
  ],
  baseStyle: {
    module: {
      bg: 'var(--global-background-color)',
      mt: 20,
    },
    logo: {
      width: { base: '227px', md: '198px', xl: '207px', '2xl': '291px' },
      height: { base: '46px', md: '40px', xl: '42px', '2xl': '59px' },
      bgImage: 'var(--global-logo)',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
    },
    divider: {
      borderColor: 'whiteAlpha.400',
    },
    copyright: {
      color: 'white',
      paddingBottom: 0,
    },
    text: {
      color: 'white',
    },
    mainNavTitle: {
      color: 'white',
      fontWeight: 'semibold',
      fontSize: { base: '18px', xl: '20px' },
    },
    mainNavLink: {
      color: 'white',
    },
    secondaryNavLink: {
      color: 'white',
      textDecoration: 'underline',
    },
    phoneIcon: {
      bg: svgUrl(phoneSvg()),
      width: '18px',
      height: '18px',
    },
    emailIcon: {
      bg: svgUrl(emailSvg()),
      width: '20px',
      height: '16px',
    },
    socialIcon: {
      width: '35px',
      height: '35px',
      bgRepeat: 'no-repeat',
    },
    footerImage: {
      height: '40px',
    },
  },
};
