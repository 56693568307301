import {
  Box,
  Divider,
  Flex,
  HStack,
  Img,
  Link as Anchor,
  List,
  ListItem,
  Stack,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import React, { Fragment } from 'react';
import useBrochurewareDictionary from '../../api/Dictionary/useBrochurewareDictionary';
import { MEDIA_BASE_PATH } from '../../constants/routes';
import { Site } from '../../types';
import { getSocialIcon } from '../../utils/chakra-ui';

interface ExtendedTopSectionProps {
  settings: Site;
}

const ExtendedTopSection = ({ settings }: ExtendedTopSectionProps) => {
  const footerTheme = useMultiStyleConfig('Footer', { baseStyles: true });
  const { labels } = useBrochurewareDictionary('Footer');

  return (
    <Flex
      flexDir={{ base: 'column', lg: 'row' }}
      pt={{ base: 0, lg: 10, xl: 20 }}
      pb={{ base: 0, lg: 14, xl: 24 }}
    >
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'column' }}
        spacing={{ base: 6, md: 0, lg: 8 }}
        justify={{ base: 'flex-start', md: 'space-between', lg: 'flex-start' }}
        pt={{ base: 5, md: 9, lg: 0 }}
        pb={{ base: 6, md: 7, lg: 0 }}
        mr={{ base: 0, lg: 10 }}
      >
        <Box
          role="img"
          aria-label={`${settings?.siteName} Logo`}
          flexShrink={0}
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          sx={footerTheme.logo}
        />
        <Stack
          spacing={4}
          direction={{ base: 'column', md: 'row', lg: 'column' }}
          align={{ base: 'flex-start', md: 'center', lg: 'flex-start' }}
        >
          {settings?.footerPhoneNumber && (
            <Anchor href={`tel:${settings?.footerPhoneNumber}`}>
              <Flex gap={4}>
                <Box role="img" aria-label="Phone icon" sx={footerTheme.phoneIcon} />
                <Text
                  mt={0}
                  sx={footerTheme.text}
                  _hover={{ textDecoration: 'underline', color: 'white' }}
                >
                  {settings?.footerPhoneNumber}
                </Text>
              </Flex>
            </Anchor>
          )}
          {settings?.footerEmail && (
            <Anchor href={`mailto:${settings?.footerEmail}`}>
              <Flex gap={4}>
                <Box role="img" aria-label="Email icon" sx={footerTheme.emailIcon} />
                <Text
                  mt={0}
                  sx={footerTheme.text}
                  _hover={{ textDecoration: 'underline', color: 'white' }}
                >
                  {labels('emailUsText')}
                </Text>
              </Flex>
            </Anchor>
          )}
          {settings?.footerSocialLinks && (
            <HStack spacing={3} display={{ base: 'none', md: 'flex', lg: 'none' }}>
              {settings?.footerSocialLinks.map((socialLink, index) => {
                const socialIcon = getSocialIcon(socialLink.title.toLowerCase());
                return (
                  <Anchor
                    key={socialLink?.title + index}
                    href={socialLink?.url}
                    target={socialLink?.isNewWindow ? '_blank' : '_self'}
                    rel={socialLink?.isNewWindow ? 'noopener noreferrer' : ''}
                  >
                    <Box
                      role="img"
                      aria-label={socialIcon.alt}
                      bgImage={socialIcon.svgUrl}
                      sx={footerTheme.socialIcon}
                    />
                  </Anchor>
                );
              })}
            </HStack>
          )}
        </Stack>
      </Stack>
      <Divider display={{ base: 'block', lg: 'none' }} sx={footerTheme.divider} />
      {settings?.footerNavigationCategories && (
        <List
          display="grid"
          gridTemplateColumns={{
            base: '1fr',
            md: `repeat(${settings?.footerNavigationCategories.length}, 1fr)`,
            xl: `repeat(${
              settings?.footerNavigationCategories.length + (settings?.footerImages ? 1 : 0)
            }, 1fr)`,
          }}
          my={{ base: 0, md: 8, lg: 0 }}
          w="full"
        >
          {settings?.footerNavigationCategories.map((category, index) => {
            return (
              <Fragment key={category?.footerNavigationCategoryTitle + index}>
                <ListItem my={{ base: 3, md: 0 }}>
                  <Text mt={0} mb={4} sx={footerTheme.mainNavTitle}>
                    {category?.footerNavigationCategoryTitle}
                  </Text>
                  <List spacing={4}>
                    {category?.footerNavigationCategoryLinks.map((link, index) => (
                      <ListItem key={link.url + index}>
                        <Anchor
                          sx={footerTheme.mainNavLink}
                          href={link?.url}
                          target={link?.isNewWindow ? '_blank' : '_self'}
                          rel={link?.isNewWindow ? 'noopener noreferrer' : ''}
                        >
                          {link?.title}
                        </Anchor>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
                <Divider display={{ base: 'block', md: 'none' }} sx={footerTheme.divider} />
              </Fragment>
            );
          })}
          {settings?.footerImages && (
            <ListItem
              my={{ base: 5, md: 0 }}
              display={{ base: 'list-item', md: 'none', xl: 'list-item' }}
            >
              <Flex gap={5} wrap="wrap">
                {settings?.footerImages.map((image) => (
                  <Img
                    key={image?.alt}
                    src={`${MEDIA_BASE_PATH}${image?.url}`}
                    alt={image?.alt}
                    objectFit="cover"
                    loading="lazy"
                    sx={footerTheme.footerImage}
                  />
                ))}
              </Flex>
            </ListItem>
          )}
          <Divider display={{ base: 'block', md: 'none' }} sx={footerTheme.divider} />
        </List>
      )}
    </Flex>
  );
};

export default ExtendedTopSection;
