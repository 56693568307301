import { CULTURE } from '../../constants/i18n';
import { ROUTES_BASE_PATH } from '../../constants/routes';
import { axiosGet, getContentApi } from '../../utils/api';

export const getParseRoutePath = (route: string) => {
  route = route.replace(/\/$/, ''); // remove ending /
  return route;
};

const getPage = (route: string) => async () => {
  const parsedRoutePath = getParseRoutePath(route);
  const url = `${getContentApi()}/page?path=${ROUTES_BASE_PATH}${parsedRoutePath}&culture=${CULTURE}&isPreview=false`;

  const response = await axiosGet(url);

  // spread ensures undefined is not returned upon error to avoid SerializableError
  return Promise.resolve({ ...response?.data?.properties });
};

export default getPage;
