export default {
  parts: [],
  baseStyle: {
    searchWaitWrapper: {},
    pictureWrapper: {},
    backgroundImage: {},
    gradientOverlay: {},
    searchWaitFlexContainer: {},
    progressBar: {
      width: '100%',
      background: 'white',
      "& [role='progressbar']": {
        backgroundColor: 'accent.500',
        backgroundImage:
          'linear-gradient(    to right,    transparent 0%, var(--chakra-colors-accent-100) 50%,    transparent 100%  )',
      },
    },
    content: {
      pt: {
        base: '12vw', // responsive sizes to soften difference between breakpoint above
        md: '9vw', // responsive sizes to soften difference between breakpoint above
        lg: '16vw', // responsive sizes to soften difference between breakpoint above
        xl: '11vw',
      },
      marginX: 'auto',
    },
    textWrapper: {
      width: '100%',
      maxWidth: { base: '100%', md: '90%', lg: '80%', xl: '744px' },
      textWrap: 'balance',
      marginX: 'auto',
    },
    loadingTitle: {
      color: 'white',
      fontSize: { base: '2.25rem', md: '72px' },
      fontWeight: '700',
      lineHeight: 1,
      mb: { base: '20px', md: '30px' },
    },
    loadingText: {
      color: 'white',
      fontWeight: 500,
      lineHeight: '28px',
    },
  },
};
