import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  Text,
  useBreakpoint,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useBrochurewareDictionary from '../../api/Dictionary/useBrochurewareDictionary';
import { USER_SESSION_ACTIONS } from '../../constants/actions';
import { LOGIN_ROUTE } from '../../constants/routes';
import { withErrorBoundary } from '../ErrorBoundary';
import { HeaderVariant } from '../Header';
import { getUserSession, removeUserSession } from '../Login/helpers';
import Module, { ModuleType } from '../Module';

export interface ContactBarProps {
  showAgentStatus: boolean;
  variant: HeaderVariant;
}

const ContactBar = ({ showAgentStatus, variant }: ContactBarProps) => {
  const { labels } = useBrochurewareDictionary('ContactBar');
  const contactBarTheme = useMultiStyleConfig('ContactBar', { baseStyles: true, variant });
  const [hasMounted, setHasMounted] = useState(false);
  const router = useRouter();
  const userSession = useMemo(() => {
    // Only aim to get user session once on client
    return hasMounted ? getUserSession() : undefined;
  }, [hasMounted]);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const breakpoint = useBreakpoint();
  const agentStatusWrapperRef = useRef<HTMLDivElement>(null);
  const agentStatusRef = useRef<HTMLDivElement>(null);

  const onLogin = () => {
    router.push(`${LOGIN_ROUTE}?returnUrl=${encodeURIComponent(router.asPath)}`);
  };

  const onLogout = () => {
    removeUserSession();
    router.push(`${LOGIN_ROUTE}?action=${USER_SESSION_ACTIONS.logout}`);
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    onClose();
  }, [breakpoint]);

  useEffect(() => {
    if (agentStatusWrapperRef.current && agentStatusRef.current) {
      agentStatusWrapperRef.current.removeAttribute('style');
      if (isOpen) {
        agentStatusWrapperRef.current.style.height = `${agentStatusRef.current.offsetHeight}px`;
        agentStatusWrapperRef.current.style.opacity = '1';
      }
    }
  }, [isOpen]);

  const contactHours = labels('contactHours');
  const contactLabel = labels('contactLabel');

  return (
    <Module
      className="ContactBar"
      type={ModuleType.FULL_WIDTH_NO_PADDING}
      sx={contactBarTheme.module}
    >
      <Flex
        justifyContent="space-between"
        py={{ base: 0, lg: 3 }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex px={4} py={{ base: 3, md: !showAgentStatus ? 3 : 0, lg: 0 }}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'flex-start', md: 'center' }}
            spacing={{ base: 2, md: 6 }}
          >
            {contactLabel && (
              <Text
                fontSize="sm"
                mt={0}
                whiteSpace="pre-wrap"
                sx={contactBarTheme.text}
                dangerouslySetInnerHTML={{ __html: contactLabel }}
              />
            )}
            {contactHours && (
              <Text
                fontSize="sm"
                mt={0}
                whiteSpace="pre-wrap"
                sx={contactBarTheme.text}
                dangerouslySetInnerHTML={{ __html: contactHours }}
              />
            )}
          </Stack>
          {/* Mobile only show agent status button */}
          {showAgentStatus && (
            <Button
              variant="unstyled"
              display={{ base: 'inline-block', lg: 'none' }}
              ml="auto"
              onClick={onToggle}
            >
              <Center>
                <Box
                  w={10}
                  h={10}
                  transition="transform 0.2s ease 0s"
                  transform={`rotate(${isOpen ? '-180' : '0'}deg)`}
                  bgRepeat="no-repeat"
                  bgSize="contain"
                  sx={contactBarTheme.expandIcon}
                />
              </Center>
            </Button>
          )}
        </Flex>
        {showAgentStatus && (
          <Box
            ref={agentStatusWrapperRef}
            overflow={{ base: 'hidden', lg: 'visible' }}
            transition="height 0.2s linear 0s, opacity 0.2s ease 0s"
            opacity={{ base: 0, lg: 1 }}
            height={{ base: 0, lg: 'auto' }}
          >
            <Box
              ref={agentStatusRef}
              px={4}
              py={{ base: 3, lg: 0 }}
              borderTop={{ base: '1px solid', lg: 'none' }}
              sx={contactBarTheme.agentStatus}
            >
              {userSession ? (
                <HStack spacing={10} justify={{ base: 'space-between', lg: 'flex-end' }}>
                  <HStack spacing={2}>
                    <Box bgRepeat="no-repeat" bgSize="contain" sx={contactBarTheme.userIcon} />
                    <Text fontSize="sm" mt={0}>
                      {userSession.name}
                    </Text>
                    <Text fontSize="sm" mt={0}>
                      |
                    </Text>
                    <Text fontSize="sm" mt={0}>
                      {userSession.agencyName}
                    </Text>
                  </HStack>
                  <Button
                    size="sm"
                    variant="link"
                    textDecoration="underline"
                    fontWeight="normal"
                    sx={contactBarTheme.actionLink}
                    onClick={onLogout}
                  >
                    {labels('logoutLabel')}
                  </Button>
                </HStack>
              ) : (
                <HStack spacing={2} justify="flex-end">
                  <Box sx={contactBarTheme.userIcon} />
                  <Button
                    size="sm"
                    variant="link"
                    sx={contactBarTheme.actionLink}
                    onClick={onLogin}
                  >
                    {labels('loginLabel')}
                  </Button>
                </HStack>
              )}
            </Box>
          </Box>
        )}
      </Flex>
    </Module>
  );
};

ContactBar.displayName = 'ContactBar';

export default withErrorBoundary(ContactBar);
