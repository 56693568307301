// This file includes some of the basic chakra z-indexes
// as well as some custom ones so we can see what they are
// stacking over

// Source - https://chakra-ui.com/docs/styled-system/theme#z-index-values
export const chakraZIndexes = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};

const customZIndexes = {
  iconsAboveFlickityCarousel: 1,
  elementsAboveModalOrDrawer: chakraZIndexes.modal + 1,
  plus: 2,
  mobileFilterDrawerFooter: 2000,
  header: 3,
  transparentHeaderUnderlay: 1,
};

export const Z_INDEX = {
  ...chakraZIndexes,
  ...customZIndexes,
};
