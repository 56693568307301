import { Box, Flex, Link as Anchor, useMultiStyleConfig } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { ROUTES_BASE_PATH } from '../../constants/routes';
import { Z_INDEX } from '../../constants/zIndex';
import { Site } from '../../types';
import { getRouteFromCmsUrl } from '../../utils/routes';
import Module, { MarginType, ModuleType } from '../Module';
import Navigation from './Navigation';

export const HeaderVariant = {
  OPAQUE: 'opaque',
  TRANSPARENT: 'transparent',
} as const;
export type HeaderVariant = (typeof HeaderVariant)[keyof typeof HeaderVariant];

export interface HeaderProps {
  variant: HeaderVariant;
  settings?: Site;
}

const Header = ({ settings, variant }: HeaderProps) => {
  const headerTheme = useMultiStyleConfig('Header', { baseStyles: true, variant });

  const isTransparent = variant === HeaderVariant.TRANSPARENT;

  return (
    <Module
      className="Header"
      py={4}
      zIndex={Z_INDEX.header}
      sx={headerTheme.module}
      marginType={MarginType.SNUG}
      type={ModuleType.FULL_WIDTH}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="full"
        sx={headerTheme.flexOuter}
      >
        <Link
          passHref
          href={getRouteFromCmsUrl(`${ROUTES_BASE_PATH}/`)}
          as={getRouteFromCmsUrl(`${ROUTES_BASE_PATH}/`)}
          legacyBehavior
        >
          <Anchor>
            <Box bgRepeat="no-repeat" bgSize="contain" sx={headerTheme.logo} />
          </Anchor>
        </Link>
        <Navigation
          variant={{ base: 'mobile', lg: 'desktop' }}
          mainNavigationItems={settings?.headerMainNavigation}
          secondaryNavigationItems={settings?.headerSecondaryNavigation}
          hasTransparentHeader={isTransparent}
        />
      </Flex>
    </Module>
  );
};

Header.displayName = 'Header';

export default Header;
