import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../../utils/graphics';
import colors from '../../../config/colors';
import alertSvg from '../../../icons/alertSvg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'inner',
  'outer',
  'icon',
  'message',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    icon: {
      bgImage: svgUrl(alertSvg(colors.error[500])),
    },
    message: {
      fontWeight: 400,
    },
  },
});
