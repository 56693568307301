import { createPseudoBackgroundImageElement } from '../../../../utils/chakra-ui';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import chevronRightInCircleSvg from '../../icons/chevronRightInCircle';

const wrapperBorder = createPseudoBackgroundImageElement({
  borderRadius: 'var(--chakra-radii-xl)',
  boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-gray-200)',
  zIndex: 1,
  pointerEvents: 'none',
});

export default {
  parts: [
    'airlineLogo',
    'chevronIcon',
    'directTag',
    'flightLeg',
    'flightLegAirportCode',
    'flightLegAirportName',
    'fontWeight',
    'flightLegHighlighted',
    'flightLegLine',
    'flightLegSchedule',
    'flightLegTime',
    'flightLegTimeSlipIndicator',
    'infoTooltipIcon',
    'mobileSeparator',
    'priceLabel',
    'priceNumber',
    'selectedIcon',
    'stopTag',
    'wrapper',
    'wrapperSelected',
  ],
  baseStyle: {
    airlineLogo: {
      h: 6,
    },
    chevronIcon: {
      bg: svgUrl(chevronRightInCircleSvg(colors.primary[700])),
      h: 8,
      w: 8,
    },
    directTag: {
      bgColor: 'primary.100',
      color: 'primary.500',
    },
    flightLeg: {},
    flightLegAirportCode: {
      color: 'primary.500',
      fontWeight: 'semibold',
    },
    flightLegAirportName: {
      fontWeight: {
        base: 'semibold',
        md: 'medium',
      },
      color: {
        base: 'primary.500',
        md: 'var(--chakra-colors-chakra-body-text)',
      },
    },
    flightLegHighlighted: {
      bg: 'primary.50',
    },
    flightLegLine: {
      borderBottom: 'var(--global-thin-gray-line)',
    },
    flightLegSchedule: {
      fontWeight: 'medium',
    },
    flightLegTime: {
      color: {
        base: 'var(--chakra-colors-chakra-body-text)',
        md: 'primary.500',
      },
      fontWeight: 'semibold',
    },
    flightLegTimeSlipIndicator: {
      color: 'promo.500',
      fontWeight: 'semibold',
    },
    infoTooltipIcon: {
      bg: 'var(--global-info-fill-tooltip-icon)',
      h: 4,
      w: 4,
    },
    mobileSeparator: {
      borderColor: 'primary.100',
    },
    priceLabel: {},
    priceNumber: {
      fontWeight: 'semibold',
      color: 'primary.500',
    },
    selectedIcon: {
      bg: 'var(--global-tick-in-circle-icon)',
      h: 8,
      w: 8,
    },
    stopTag: {
      bgColor: 'primary.100',
      color: 'primary.500',
    },
    wrapper: {
      borderRadius: 'xl',
      position: 'relative',
      _after: wrapperBorder,
    },
    wrapperFlightConfirmation: {
      _after: {
        content: 'none',
      },
    },
    wrapperSelected: {
      bg: 'primary.50',
      _after: {
        ...wrapperBorder,
        boxShadow: 'inset 0px 0px 0px 2px var(--chakra-colors-primary-500)',
      },
    },
  },
};
