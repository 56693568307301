import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import arrowRight from '../../icons/arrowRight';
import chevronRightInCircleSvg from '../../icons/chevronRightInCircle';
import chevronRightSvg from '../../icons/chevronRightSvg';
import clockSvg from '../../icons/clockSvg';

export default {
  parts: [
    'drawerButton',
    'drawerButtonLeftIcon',
    'drawerButtonRightIcon',
    'outer',
    'desktop',
    'top',
    'heading',
    'bottom',
    'product',
    'productHeading',
    'productIcon',
    'directTag',
    'detailsTitle',
    'button',
    'buttonIcon',
    'fromToIcon',
    'mobile',
    'mobileTop',
    'mobileBottom',
    'mobileProduct',
    'mobileProductHeading',
    'mobileProductIcon',
    'mobileHeading',
    'mobileDetails',
    'mobileButtonIcon',
  ],
  baseStyle: {
    drawerButton: {
      borderRadius: 0,
      color: 'primary.500',
    },
    drawerButtonLeftIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(clockSvg(colors.primary['500'])),
    },
    drawerButtonRightIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) =>
        svgUrl(chevronRightSvg(colors.primary['500'])),
      transform: 'scale(0.8)',
    },
    fromToIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) =>
        svgUrl(chevronRightInCircleSvg(colors.primary['500'])),
    },
    heading: {},
    product: {
      bg: 'gray.50',
    },
    mobileTop: {
      bg: 'gray.50',
    },
    detailsTitle: {
      color: 'var(--chakra-colors-chakra-body-text)',
    },
    buttonIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) =>
        svgUrl(arrowRight(colors.primary['500'])),
    },
    mobileHeading: {},
    mobile: {
      _hover: {
        background: 'var(--chakra-colors-primary-100)',
      },
      _focus: {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
    mobileButtonIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) =>
        svgUrl(chevronRightSvg(colors.primary['500'])),
    },
  },
};
