import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { createPseudoBackgroundImageElement } from '../../../utils/chakra-ui';
import { svgUrl } from '../../../utils/graphics';
import arrowLeft from '../icons/arrowLeft';
import arrowRight from '../icons/arrowRight';

const hitRect = createPseudoBackgroundImageElement({
  width: 12,
  height: 12,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
});

const preNextButtonStyles = {
  position: 'absolute',
  fontSize: 0,
  mt: '4px',
  w: '14px',
  h: '14px',
  _before: hitRect,
};

const labelLastAndFirst = {
  width: '50%',
  display: 'flex',
  flexDirection: 'row',
};

export default {
  '.pika-single': {
    '&.standalone': {
      bg: 'white',
      px: 8,
      boxShadow: ({ globals }: StyleFunctionProps) => globals.shadow,
      borderRadius: '10px',
      zIndex: 1,

      '&.is-hidden': {
        display: 'none',
      },
    },

    '.pika-button': {
      width: '100%',
      height: '100%',
    },
    '.pika-prev': {
      ...preNextButtonStyles,
      bg: ({ colors }: StyleFunctionProps) => svgUrl(arrowLeft(colors.primary['500'])),
    },
    '.pika-prev.is-disabled': {
      bg: ({ colors }: StyleFunctionProps) => svgUrl(arrowLeft(colors.gray['200'])),
    },
    '.pika-next': {
      ...preNextButtonStyles,
      right: 0,
      bg: ({ colors }: StyleFunctionProps) => svgUrl(arrowRight(colors.primary['500'])),
    },
    '.pika-next.is-disabled': {
      bg: svgUrl(arrowRight('')),
    },
    '.pika-label': {
      fontSize: '0',
    },
    '.pika-label:first-of-type': {
      ...labelLastAndFirst,
      justifyContent: 'flex-end',
    },
    '.pika-label:last-of-type': {
      ...labelLastAndFirst,
      justifyContent: 'flex-start',
    },
    '.pika-select': {
      visibility: 'visible',
      fontSize: 'md',
      mx: '10px',
      px: '10px',
    },
    '.pika-title': {
      display: 'flex',
      position: 'relative',
      pt: { base: '25px', md: '25px', xl: '38px' },
      pb: { base: '45px', md: '55px', xl: '38px' },
      _after: {
        content: '""',
        position: 'absolute',
        display: { base: 'none', md: 'block', xl: 'none' },
        width: '100%',
        bottom: 8,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
      },
    },
    '.is-selected': {
      position: 'relative',
      color: 'white',
      py: '0px',
      _before: {
        content: '""',
        position: 'absolute',
        display: 'block',
        height: '100%',
        maxHeight: '42px',
        left: '50%',
        top: '50%',
        aspectRatio: 1 / 1,
        bg: 'primary.700',
        borderRadius: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        zIndex: '-1',
      },
    },

    '.is-disabled': {
      color: 'gray.400',
      fill: 'gray.400',
    },
    '.pika-table': {
      // custom aligning for each breakpoint
      width: {
        base: 'calc(100% + 2rem)',
        md: 'calc(100% + 6rem)',
        lg: 'calc(100% + 8rem)',
        xl: 'calc(100% + 3rem)',
      },
      margin: {
        base: '0 -1rem',
        md: '0 -3rem',
        lg: '0 -4rem',
        xl: '0 -1.5rem',
      },
    },
    td: {
      fontSize: 'md',
      textAlign: 'center',
      color: 'gray.600',
      py: '10px',
      width: '55px',
      height: '55px',
    },
    abbr: {
      textDecoration: 'none',
    },
  },
};
