import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import beachSvg from '../../icons/beachSvg';
import carSvg from '../../icons/carSvg';
import citySvg from '../../icons/citySvg';
import coachSvg from '../../icons/coachSvg';
import cruiseSvg from '../../icons/cruiseSvg';
import planeSvg from '../../icons/flightSvg';
import hotelSvg from '../../icons/hotelSvg';
export default {
  parts: [
    'outer',
    'inner',
    'tab',
    'icon',
    'selectedIcon',
    'hotelIcon',
    'flightIcon',
    'cityIcon',
    'carIcon',
    'cruiseIcon',
    'coachIcon',
  ],
  baseStyle: {
    outer: {
      margin: '-2px', // minus for focus outline allowance made in scrolling div
      marginLeft: -4, // minus so on mobile scrollable area goes to screen edge
      marginRight: -4,
    },
    inner: {
      padding: '2px', // buttons padding back in to normal allowing outline to show
      _before: {
        // before and after elements so gap is auto inserted at ends of tabs, forcing out from negative margin above
        content: '""',
        display: 'block',
        width: '1px',
        flexShrink: 0,
      },
      _after: {
        content: '""',
        display: 'block',
        width: '1px',
        flexShrink: 0,
      },
    },
    icon: {
      w: 5,
      h: 5,
      mr: 1,
      backgroundPosition: 'center',
    },
    selectedIcon: {
      filter: 'brightness(0) grayscale(1) invert(1)',
    },
    tab: {
      border: '1px solid',
      borderColor: 'primary.200',
      borderRadius: '100em',
      background: 'white',
      color: 'primary.500',
      lineHeight: 1.25,
      _hover: {
        bg: 'primary.100',
      },
      _focus: {
        boxShadow: '0 0 0 2px rgba(66, 153, 225, 1)',
      },
    },
    tabSelected: {
      borderColor: 'primary.500',
      background: 'primary.500',
      color: 'white',
      _hover: {
        bg: 'primary.500',
      },
    },
    hotelIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(hotelSvg(colors.primary['500'])),
    },
    flightIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(planeSvg(colors.primary['500'])),
    },
    cityIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(citySvg(colors.primary['500'])),
    },
    carIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(carSvg(colors.primary['500'])),
    },
    cruiseIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(cruiseSvg(colors.primary['500'])),
    },
    coachIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(coachSvg(colors.primary['500'])),
    },
    beachIcon: {
      backgroundImage: ({ colors }: StyleFunctionProps) => svgUrl(beachSvg(colors.primary['500'])),
    },
  },
};
