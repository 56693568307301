export default {
  parts: ['ctaButton'],
  baseStyle: {
    ctaButton: {
      bg: 'transparent',
      px: 1,
      ml: '-0.25rem !important', // need to override the VStack child margin
      borderRadius: 'sm',
      color: 'secondary.500',
      _hover: {
        bg: 'secondary.50',
      },
    },
  },
};
