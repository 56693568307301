const parseAppName = (octopusNameString) => {
  if (octopusNameString?.includes('NewRelicAppName') || !octopusNameString) return '';
  return octopusNameString;
};

const parseLicenseKey = (octopusKeyString) => {
  if (octopusKeyString?.includes('NewRelicLicenseKey') || !octopusKeyString) return '';
  return octopusKeyString;
};

const isNewRelicValid = (octopusNameString, octopusKeyString) => {
  return !!parseAppName(octopusNameString) && !!parseLicenseKey(octopusKeyString);
};

module.exports = {
  parseAppName,
  parseLicenseKey,
  isNewRelicValid,
};
