import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import alertSvg from '../../icons/alertSvg';
import bedSvg from '../../icons/bedSvg';
import coachSvg from '../../icons/coachSvg';
import downloadArrow from '../../icons/downloadArrow';
import filledCheck from '../../icons/filledCheck';
import luggageSvg from '../../icons/luggageSvg';
import planeSvg from '../../icons/planeSvg';
import ticketSvg from '../../icons/ticketSvg';

const defaultDataBox = {
  w: 'full',
  maxW: '950px',
  borderRadius: '10px',
  border: `1px solid ${colors.primary[100]}`,
};

export default {
  parts: [],
  baseStyle: {
    filledCheckIcon: {
      w: 6,
      h: 6,
      bg: svgUrl(filledCheck(colors.primary[500])),
    },
    errorIcon: {
      w: 6,
      h: 6,
      bg: svgUrl(alertSvg(colors.primary[500])),
    },
    downloadArrow: {
      w: 4,
      h: 4,
      bg: svgUrl(downloadArrow(colors.primary[500])),
    },
    title: { fontSize: '3xl', fontWeight: 600 },
    header: { fontSize: 'xl', fontWeight: 600 },
    subheader: { fontSize: { base: 'lg', xl: 'xl' }, fontWeight: 400, color: 'primary.500' },
    priceTitle: { fontSize: 'sm', fontWeight: 500, color: 'gray.600' },
    errorText: { fontSize: 'lg', fontWeight: 400, lineHeight: '28px', textAlign: 'center' },
    dataBox: {
      ...defaultDataBox,
      py: 4,
    },
    flightDataBox: {
      ...defaultDataBox,
      pt: 4,
    },
    extrasDataBox: {
      ...defaultDataBox,
    },
    tag: {
      fontSize: 'xs',
      fontWeight: 600,
      color: 'primary.500',
      backgroundColor: 'primary.100',
    },
    bedIcon: {
      h: '18px',
      w: '26px',
      backgroundImage: svgUrl(bedSvg(colors.primary[500])),
    },
    coachIcon: {
      h: { base: '21px', md: '24px' },
      w: { base: '16px', md: '20px' },
      backgroundImage: svgUrl(coachSvg(colors.primary[500])),
    },
    flightIcon: {
      h: { base: '20px', md: '25px' },
      w: { base: '20px', md: '25px' },
      backgroundImage: svgUrl(planeSvg(colors.primary[500])),
      transform: 'rotate(45deg)',
    },
    luggageIcon: {
      h: { base: '21px', md: '26px' },
      w: { base: '15px', md: '18px' },
      backgroundImage: svgUrl(luggageSvg(colors.primary[500])),
    },
    ticketIcon: {
      aspectRatio: '19 / 15',
      w: { base: '21px', md: '24px' },
      backgroundImage: svgUrl(ticketSvg(colors.primary[500])),
    },
    nameText: {
      fontSize: 'lg',
      fontWeight: 600,
    },
    dobText: {
      fontSize: 'md',
      fontWeight: 400,
      color: 'secondary.500',
    },
    durationText: {
      fontSize: 'sm',
      fontWeight: 400,
      color: 'secondary.500',
    },
    quantityText: {
      fontSize: 'md',
      fontWeight: { base: 400, md: 600 },
      color: 'secondary.500',
    },
    errataBox: {
      ...defaultDataBox,
      overflow: 'hidden',
      p: 0,
    },
    errataTitle: {
      backgroundColor: 'primary.500',
      color: 'white',
      p: 5,
    },
    transferName: {
      color: 'secondary.500',
    },
    extraName: {
      color: 'secondary.500',
    },
    roomDescription: {
      color: 'secondary.500',
    },
    dividerBorder: {
      borderBottomWidth: '1px',
      borderColor: 'primary.100',
    },
    dividerBorderTop: {
      borderTopWidth: '1px',
      borderColor: 'primary.100',
    },
    stackDivider: {
      borderColor: 'primary.100',
      borderLeftWidth: '2px',
    },
  },
};
