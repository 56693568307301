import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../../utils/graphics';
import alertSvg from '../../../icons/alertSvg';

export default {
  parts: ['DatePicker', 'error'],
  baseStyle: {
    DatePicker: {
      // moved general datepicker style to theme global styles, this section can be used for overriding
    },
    error: {
      fontSize: 'sm',
      _before: {
        content: '""',
        w: 3,
        h: 3,
        mr: 1,
        bg: ({ colors }: StyleFunctionProps) => svgUrl(alertSvg(colors.error['500'])),
      },
    },
  },
};
