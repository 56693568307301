import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../utils/graphics';
import minusSvg from '../../icons/minusSvg';
import plusSvg from '../../icons/plusSvg';

const buttonIconStyle = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '20px',
  width: '100%',
  height: '100%',
};

export default {
  parts: ['wrapper', 'title', 'subTitle', 'value', 'button', 'buttonRemoveIcon', 'buttonAddIcon'],
  baseStyle: {
    wrapper: {
      p: 4,
    },
    subTitle: {
      mt: '0.125em',
      fontSize: 'xs',
    },
    button: {
      w: '48px',
      h: '48px',
      p: 0,
      borderRadius: '10em',
    },
    buttonRemoveIcon: {
      bg: ({ colors }: StyleFunctionProps) => svgUrl(minusSvg(colors.primary['500'])),
      ...buttonIconStyle,
      'button:hover > &': {
        bg: svgUrl(minusSvg('white')),
        ...buttonIconStyle,
      },
    },
    buttonAddIcon: {
      bg: ({ colors }: StyleFunctionProps) => svgUrl(plusSvg(colors.primary['500'])),
      ...buttonIconStyle,
      'button:hover > &': {
        bg: svgUrl(plusSvg('white')),
        ...buttonIconStyle,
      },
    },
    value: {
      w: 8,
      textAlign: 'center',
    },
  },
};
