/**
 * // example of customising spinner targeting nested elements
 * spinner: {
 *   svg: {
 *     width: '300px',
 *     height: '300px',
 *   },
 *   '.chakra-progress__track': {
 *     stroke: 'green',
 *   },
 *   '.chakra-progress__indicator': {
 *      stroke: 'red',
 *   },
 * },
 */

import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'spinner',
  'text',
  'outerStack',
  'textStack',
  'title',
]);

export default defineMultiStyleConfig({
  baseStyle: {},
});
