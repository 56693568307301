import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  item: {
    _focus: {
      bg: 'secondary.100',
    },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
});
