import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'selectButton',
  'numberInputGroup',
  'numberInput',
  'numberInputField',
  'numberInputStepper',
  'numberIncrementStepper',
  'numberDecrementStepper',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    numberIncrementStepper: {
      backgroundImage: svgUrl(arrowUp(colors.gray[700])),
    },
    numberDecrementStepper: {
      backgroundImage: svgUrl(arrowDown(colors.gray[700])),
    },
  },
});
