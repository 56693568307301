export default {
  parts: ['triggerGroup', 'triggerInput', 'triggerHeading'],
  baseStyle: {
    triggerGroup: {
      p: 4,
    },
    triggerInput: {
      border: 'none',
      fontSize: 'md',
    },
  },
};
