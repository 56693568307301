import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../../utils/graphics';
import colors from '../../../config/colors';
import tickInCircle from '../../../icons/tickInCircle';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(['text', 'icon', 'stack']);

export default defineMultiStyleConfig({
  baseStyle: {
    icon: {
      w: 8,
      h: 8,
      bg: svgUrl(tickInCircle(colors.primary[500])),
    },
  },
});
