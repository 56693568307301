export default {
  baseStyle: {
    borderRadius: '10px',
    _focus: {
      boxShadow: 'var(--chakra-shadows-outline)',
    },
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'var(--global-background-color)',
      color: 'var(--global-background-color)',
      _hover: {
        bg: 'var(--global-background-color-hover)',
        color: 'white',
        '& .chakra-button__icon': {
          filter: 'brightness(0) grayscale(1) invert(1)',
        },
      },
    },
    outlineDark: {
      color: 'gray.700',
      fontSize: 'sm',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.5rem',
      border: '3px solid',
      borderColor: 'gray.700',
      borderRadius: '10rem',
      _hover: {
        borderColor: 'gray.900',
      },
    },
    outlineLight: {
      color: 'white',
      border: '2px solid',
      borderColor: 'white',
      _hover: {
        bg: 'secondary.600',
      },
    },
    solid: {
      color: 'white',
      bg: 'var(--global-background-color)',
      _hover: {
        bg: 'var(--global-background-color-hover)',
      },
    },
    solidLight: {
      backgroundColor: 'white',
      color: 'secondary.500',
    },
    marker: {
      borderColor: 'secondary.500',
      backgroundColor: 'secondary.500',
      borderRadius: '10em',
      color: 'white',
      boxShadow: '2px 3px 5px 0 rgba(0, 0, 0, 0.5)',
      height: '28px',
      minH: '28px',
    },
  },
  sizes: {
    sm: {
      borderRadius: '5px',
    },
    md: {
      fontSize: '15px',
    },
    lg: {
      borderRadius: '10px',
      fontSize: '15px',
    },
  },
  defaultProps: {
    colorScheme: 'secondary',
    size: 'lg',
  },
};
