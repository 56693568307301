import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import chevronRight from '../../icons/chevronRightSvg';

const accordionIconStyle = {
  alignSelf: 'center',
  w: '24px', // to match SVG for centering
  h: '24px',
};

// Needed as we can't pass theme colours into SVGs
const iconColor = colors.primary['500'];

export default {
  parts: ['wrapper', 'expandedIcon', 'collapsedIcon'],
  baseStyle: {
    wrapper: {},
    expandedIcon: {
      ...accordionIconStyle,
      bg: svgUrl(chevronRight(iconColor)),
      transform: 'rotateZ(270deg)',
    },
    collapsedIcon: {
      ...accordionIconStyle,
      bg: svgUrl(chevronRight(iconColor)),
      transform: 'rotateZ(90deg)',
    },
    filterCount: {
      color: 'primary.500',
      bgColor: 'accent.500',
      fontSize: '14px',
      fontWeight: 600,
    },
  },
};
