// All on 1 line for this file for clarity, and sorting

const { parseAppName, parseLicenseKey } = require('./server/newrelic');

const defaultPort = 3000;
// prettier-ignore
const environments = {
  local: {
    apiBookingUiUrl: `http://localhost:${process.env.PORT || defaultPort}/api-booking`,
    apiBookingUrl: process.env.LOCAL_API_BOOKING_URL || 'https://dev-bookingapi-inspiretec-easyweb.dev-uumbraco01.hosting.inspiretec.com',
    apiContentUiUrl: `http://localhost:${process.env.PORT || defaultPort}/api-content`,
    apiContentUrl: process.env.LOCAL_API_CONTENT_URL || 'https://dev-contentapi-inspiretec-easyweb.dev-uumbraco01.hosting.inspiretec.com',
    bookingJourneyRoute: process.env.BOOKING_JOURNEY_ROUTE || '/booking',
    bookingUIConfigId: process.env.BOOKING_UI_CONFIG_ID || '2272',
    contentRoutesBasePath: process.env.CONTENT_ROUTES_BASE_PATH || '/demo/home',
    contentSiteId: process.env.CONTENT_SITE_ID || '1111',
    contentUrl: process.env.CONTENT_URL || 'https://dev-content-inspiretec-easyweb.dev-uumbraco01.hosting.inspiretec.com',
    cookieSecret: process.env.COOKIE_SECRET || 'tZ&jDf4/tvNsPgh',
    cspBlobSources: 'blob:',
    cspConnectSources: '*.nr-data.net *.newrelic.com *.ingest.sentry.io *.cloudflare.com static.cloudflareinsights.com maps.googleapis.com ajax.aspnetcdn.com https://www.youtube.com https://player.vimeo.com https://vimeo.com https://www.youtube.com/iframe_api *.civiccomputing.com *.googletagmanager.com *.google-analytics.com *.google.com *.gstatic.com *.hotjar.com *.luckyorange.com *.facebook.com *.responsetap.com *.doubleclick.net *.facebook.net',
    cspDefaultSources: '',
    cspFontSources: 'fonts.gstatic.com',
    cspFrameAncestorsSources: 'www.facebook.com',
    cspFrameSources: 'https://www.youtube.com https://player.vimeo.com https://vimeo.com *.google.com www.facebook.com',
    cspImageSources: 'www.tripadvisor.com maps.googleapis.com www.inspiretec.com maps.gstatic.com data: w3.org/svg/2000 www.google.co.uk www.google-analytics.com www.googletagmanager.com',
    cspObjectSources: 'data:',
    cspScriptSources: '*.nr-data.net *.newrelic.com *.ingest.sentry.io *.cloudflare.com static.cloudflareinsights.com maps.googleapis.com ajax.aspnetcdn.com https://www.youtube.com https://player.vimeo.com https://vimeo.com https://www.youtube.com/iframe_api *.civiccomputing.com *.googletagmanager.com *.google-analytics.com *.google.com *.gstatic.com *.hotjar.com *.luckyorange.com *.facebook.com *.responsetap.com *.doubleclick.net *.facebook.net',
    cspStyleSources: 'fonts.googleapis.com',
    environment: 'local',
    errorLevel: process.env.ERROR_LEVEL || 'http',
    featureExcursionsEnabled: process.env.FEATURE_EXCURSIONS_ENABLED ? process.env.FEATURE_EXCURSIONS_ENABLED === 'true' : false,
    featureIsB2b: process.env.FEATURE_IS_B2B ? process.env.FEATURE_IS_B2B === 'true' : false,
    gaToken: process.env.GA_TOKEN || 'G-C4SS0P5XV7',
    googleApiKey: process.env.GOOGLE_API_KEY || 'AIzaSyAmw5syCC2gSyrTCKAZAAW6YU3VrxsIbUQ',
    gtmToken: process.env.GTM_TOKEN || 'GTM-TLHMQHZ3',
    isProduction: process.env.NODE_ENV === 'production',
    loginRoute: process.env.LOGIN_ROUTE || '/login',
    mediaCdn: process.env.CONTENT_URL || 'https://dev-content-inspiretec-easyweb.dev-uumbraco01.hosting.inspiretec.com',
    newRelicAppName: parseAppName(process.env.NEW_RELIC_APP_NAME),
    newRelicLicenseKey: parseLicenseKey(process.env.NEW_RELIC_LICENSE_KEY), // string | undefined
    paymentUrl: process.env.PAYMENT_URL || 'https://dev-inspiretec-easyweb.dev-uumbraco01.hosting.inspiretec.com/Payment',
    port: process.env.PORT || defaultPort,
    recaptchaSiteKey: '6LePFD4oAAAAAPYH60VE6hC-lXnm5LAgSly02AKm',
    releaseVersion: process.env.BUILD_BUILDNUMBER || 'agency-product-nextjs@local',
    sentryAuthToken: process.env.SENTRY_AUTH_TOKEN,
    sentryDsn: process.env.SENTRY_DSN,
    url: `http://localhost:${process.env.PORT || defaultPort}`,
    domain: `localhost`,
    usePaymentIFrame: process.env.USE_PAYMENT_IFRAME
    ? process.env.USE_PAYMENT_IFRAME === 'true'
    : false,
    userSessionExpirationMinutes: '60',
  },
  release: {
    apiBookingUiUrl: 'https://#{Hostname}/api-booking', // client to Server via CloudFlare
    apiBookingUrl: 'http://localhost:#{BookingApiInternalPort}', // Server to API
    apiContentUiUrl: 'https://#{Hostname}/api-content', // client to Server via CloudFlare
    apiContentUrl: 'http://localhost:#{CmsApiInternalPort}', // Server to API
    bookingJourneyRoute: '/booking',
    bookingUIConfigId: '#{BookingUiConfigId}',
    contentRoutesBasePath: '#{ContentSiteBasePath}',
    contentSiteId: '#{ContentSiteId}',
    contentUrl: 'https://#{Hostname}',
    cookieSecret: '#{CookieSecret}',
    cspBlobSources: '#{CspBlobSources}',
    cspConnectSources: '#{CspConnectSources}',
    cspDefaultSources: '#{CspDefaultSources}',
    cspFontSources: '#{CspFontSources}',
    cspFrameAncestorsSources: '#{CspFrameAncestorsSources}',
    cspFrameSources: '#{CspFrameSources}',
    cspImageSources: '#{CspImageSources}',
    cspObjectSources: '#{CspObjectSources}',
    cspScriptSources: '#{CspScriptSources}',
    cspStyleSources: '#{CspStyleSources}',
    environment: '#{Octopus.Environment.Name}',
    errorLevel: '#{ErrorLevel}',
    featureExcursionsEnabled: ('#{FeatureExcursionsEnabled}').toLowerCase() === 'true',
    featureIsB2b: ('#{FeatureIsB2b}').toLowerCase() === 'true',
    gaToken: '#{GaToken}',
    googleApiKey: '#{GoogleApiKey}',
    gtmToken: '#{GtmToken}',
    isProduction: true,
    loginRoute: '/login',
    mediaCdn: 'https://#{MediaHostname}',
    newRelicAppName: process.env.NEW_RELIC_APP_NAME || parseAppName('#{NewRelicAppName}'),
    newRelicLicenseKey: process.env.NEW_RELIC_LICENSE_KEY || parseLicenseKey('#{NewRelicLicenseKey}'),
    paymentUrl: '#{PaymentUrl}',
    port: '#{WebsiteInternalPort}',
    recaptchaSiteKey: '#{Recaptcha3PublicKey}',
    releaseVersion: '#{SentryNextJsProject}@#{Octopus.Release.Number}',
    sentryAuthToken: '#{SentryAuthToken}',
    sentryDsn: '#{SentryDsn}',
    url: 'https://#{Hostname}',
    domain: `#{Domain}`,
    usePaymentIFrame: ('#{UsePaymentIFrame}').toLowerCase() === 'true',
    userSessionExpirationMinutes: '#{UserSessionExpirationMinutes}',
  },
};

module.exports = (environment = '') => {
  const currentEnvironment = environment.toLowerCase();

  if (Object.prototype.hasOwnProperty.call(environments, currentEnvironment)) {
    return environments[currentEnvironment];
  }

  return environments.local;
};
