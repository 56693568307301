import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { REACT_QUERY_CACHE_TIME, REACT_QUERY_STALE_TIME } from '../../constants/query-cache-time';
import { LOGIN_ROUTE } from '../../constants/routes';
import getPage from '../../queries/content/page';
import getSite, { SITE_QUERY_KEY } from '../../queries/content/site';
import { Page, Site } from '../../types';
import { getRouteNoQueryString } from '../../utils/routes';
import { HeaderVariant } from '../Header';

interface UseHydratedContentApiDataChildProps {
  pageData?: Page;
  headerVariant: HeaderVariant;
  settings?: Site;
  renderFooter: boolean;
  renderHeader: boolean;
  renderContactBar: boolean;
  renderHeadWithContentAPIData: boolean;
}

interface UseHydratedContentApiDataProps {
  children: (data: UseHydratedContentApiDataChildProps) => JSX.Element;
  ssrResolvedUrl: string;
}

const UseHydratedContentApiData = ({
  children,
  ssrResolvedUrl,
}: UseHydratedContentApiDataProps) => {
  const router = useRouter();
  // Logic to be able to hide header and footer for iframe pages. Purely to mirror hiding in the Website
  const iFrameRoutes = ['submit-payment-form'];
  const inPaymentIFrame = iFrameRoutes.some((segment) => ssrResolvedUrl?.includes(segment));

  // route must match one derived in getDehydratedStateProp
  const routeNoQueryString = getRouteNoQueryString(router.asPath);
  // If we are experiencing a 500 error, we want to avoid attempting to call the Content API.
  const disableIfOn500Page = router.route.indexOf('/500') === 0;

  /**
   * Typically when directly navigating to the page, page and site data will be ready in Next Data
   * ready to hydrated. However if its not it will be called client side here. In addition on the
   * client side Next.js attempts to pre fetch the what is usually called on the server via
   * getStaticProps. Therefore this query will never be called as it already be in the cache.
   */

  const { data: pageData } = useQuery({
    enabled: !disableIfOn500Page,
    queryKey: routeNoQueryString,
    queryFn: getPage(routeNoQueryString),
    staleTime: REACT_QUERY_STALE_TIME,
    cacheTime: REACT_QUERY_CACHE_TIME,
  }) as { data: Page };

  const { data: siteData } = useQuery({
    enabled: true, // !disableIfOn500Page,
    queryKey: SITE_QUERY_KEY,
    queryFn: getSite,
    staleTime: REACT_QUERY_STALE_TIME,
    cacheTime: REACT_QUERY_CACHE_TIME,
  });

  return children({
    pageData,
    headerVariant: pageData?.hasTransparentHeader
      ? HeaderVariant.TRANSPARENT
      : HeaderVariant.OPAQUE,
    settings: siteData?.settings,
    renderFooter: !inPaymentIFrame && (pageData?.showFooter ?? true),
    renderHeader: !inPaymentIFrame && (pageData?.showHeader ?? true),
    renderContactBar: routeNoQueryString.indexOf(LOGIN_ROUTE) !== 0 && !inPaymentIFrame,
    renderHeadWithContentAPIData: !inPaymentIFrame,
  });
};

export default UseHydratedContentApiData;
