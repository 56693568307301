import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import searchSvg from '../../icons/searchSvg';

const defaultButtonStyle = {
  minHeight: '50px',
  backgroundColor: 'white',
  fontSize: 'sm',
  fontWeight: '400',
  color: 'black',
  borderWidth: '1px',
  borderRightWidth: 0,
  borderLeftWidth: 0,
  ':hover': {
    backgroundColor: 'white',
  },
  _before: {
    content: '""',
    position: 'absolute',
    display: 'inline-width',
    width: '1px',
    height: 'calc(100% - 1rem)',
    left: 0,
    top: '0.5rem',
    backgroundColor: 'var(--chakra-colors-chakra-border-color)',
  },
  '&:focus-visible': {
    zIndex: 1,
  },
};

export default {
  parts: ['leftButton', 'centerButton', 'rightButton', 'searchIcon', 'absolute', 'microButton'],
  baseStyle: {
    microButton: {
      ...defaultButtonStyle,
      pl: 4,
      pr: 6,
      overflow: 'hidden',
      justifyContent: 'flex-start',
      borderRadius: '10em',
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      _before: {
        display: 'none',
      },
    },
    microButtonCopy: {
      left: '55px', // spacing + icon
      width: 'calc(100% - (55px + 20px))', // - spacing + icon + right spacing
    },
    leftButton: {
      ...defaultButtonStyle,
      marginLeft: '1px',
      borderRadius: '10em 0 0 10em',
      borderLeftWidth: '1px',
      _before: {
        display: 'none',
      },
    },
    centerButton: {
      ...defaultButtonStyle,
      borderRadius: '0 0 0 0',
    },
    rightButton: {
      ...defaultButtonStyle,
      borderRadius: '0 10em 10em 0',
      borderRightWidth: '1px',
    },
    searchIcon: {
      display: 'inlineBlock',
      flexShrink: 0,
      h: '23px',
      w: '23px',
      mr: '15px',
      bg: ({ colors }: StyleFunctionProps) => svgUrl(searchSvg(colors.primary['500'])),
      backgroundRepeat: 'no-repeat',
    },
    absolute: {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textAlign: 'center',
    },
  },
};
