import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../../utils/graphics';
import buildingSvg from '../../../icons/buildingSvg';
import pinSvg from '../../../icons/pinSvg';
import planeSvg from '../../../icons/planeSvg';

const searchAutoCompleteResultIconStyle = {
  mt: 1,
  w: 5,
  h: 5,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
};

export default {
  parts: ['ResultBox', 'planeIcon', 'pinIcon', 'buildingIcon', 'resultName', 'resultLocation'],
  baseStyle: {
    ResultBox: {
      width: '100%',
      py: 3,
      _focus: { bg: 'primary.100' },
    },
    planeIcon: {
      bg: ({ colors }: StyleFunctionProps) => svgUrl(planeSvg(colors.primary['500'])),
      ...searchAutoCompleteResultIconStyle,
    },
    pinIcon: {
      bg: ({ colors }: StyleFunctionProps) => svgUrl(pinSvg(colors.primary['500'])),
      ...searchAutoCompleteResultIconStyle,
    },
    buildingIcon: {
      mt: 1,
      w: 5,
      h: 5,
      bg: ({ colors }: StyleFunctionProps) => svgUrl(buildingSvg(colors.primary['500'])),
    },
    resultName: {
      fontWeight: 600,
      my: 0,
      textAlign: 'left',
      color: 'primary.500',
    },
    resultLocation: {
      fontWeight: 400,
      my: 0,
      textAlign: 'left',
      color: 'gray.600',
      fontSize: 'sm',
    },
  },
};
