export default {
  baseStyle: {
    contentBlock: {
      lineHeight: '25px',
      h1: {
        fontSize: '2xl',
        fontWeight: 'bold',
        mb: '6',
      },
      h2: {
        fontSize: '2xl',
        fontWeight: 'bold',
        mb: 8,
      },
      h3: {
        fontSize: 'xl',
        fontWeight: 'bold',
        mb: 6,
      },
      p: {
        mb: 6,
      },
      ul: {
        mb: 6,
      },
      ol: {
        mb: 6,
      },
    },
  },
};
