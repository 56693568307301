import { DefaultDictionaryItem } from './useBrochurewareDictionary';

const defaultDictionary: DefaultDictionaryItem = {
  Breadcrumb: {
    labels: {},
  },
  ContactBar: {
    labels: {
      logoutLabel: 'Logout',
      loginLabel: 'Login',
      contactLabel: '',
      contactHours: '',
    },
    validationMessages: {},
    errorMessages: {},
  },
  ErrorBoundary: {
    labels: {
      brokenText: 'Oops! something has broken.',
      redirectButtonText: 'Restart',
      resetButtonText: 'Try Again',
    },
  },
  Footer: {
    labels: {
      manageCookiesLinkText: 'Manage Cookies',
      emailUsText: 'Email us',
    },
  },
  Login: {
    labels: {
      loginTitle: 'Please sign in',
      salesSupportLabel: 'Sales Support',
      salesSupportNumber: '0330 880 0310',
      loginCopyright: '© 2024 Inspiretec. All rights reserved.',
      abtaLabel: 'ABTA',
      abtaPlaceholder: 'ABTA/Agency Number',
      usernameLabel: 'User Ref',
      usernamePlaceholder: 'Agency Username',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Password',
      loginButtonLabel: 'Sign in',
      logoutConfirmationHeading: 'You have safely logged out',
      logoutConfirmationText: '',
      logoutConfirmationCloseButtonLabel: 'Close',
    },
    validationMessages: {
      abtaValidation: 'ABTA/Agency Number is required',
      usernameValidation: 'Agency username is required',
      passwordValidation: 'Password is required',
    },
    errorMessages: {
      unauthorised: 'Your Agency Number, username or password are incorrect',
    },
  },
  NewsletterSubscription: {
    labels: {
      subscribeWarning: 'There was an issue, please try again',
      subscribeSuccess: 'Thanks, you are now subscribed',
      emailPlaceholder: 'youremail@email.com',
    },
    validationMessages: {
      invalidEmail: 'Please enter a valid email',
    },
  },
  UmbracoForm: {
    errorMessages: {
      loadErrorTitle: 'Oops! something has broken',
      loadErrorText: "We can't display the form right now.",
    },
  },
};

export default defaultDictionary;
