import colors from '../../config/colors';

export default {
  parts: [],
  baseStyle: {
    container: {
      borderRadius: '10px',
      border: `1px solid ${colors.primary[100]}`,
    },
    bottomText: {
      fontSize: 'xl',
      color: 'primary.500',
      fontWeight: 600,
    },
    extrasText: {
      fontSize: 'md',
      color: 'secondary.500',
      fontWeight: 600,
    },
    heading: {
      color: 'primary.500',
      fontWeight: 600,
    },
  },
};
