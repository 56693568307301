import { AxiosError } from 'axios';
import { simpleFetch } from 'inspiretec-booking-journey-ui/utils/api';
import getConfig from 'next/config';
import { useQuery } from 'react-query';
import { REACT_QUERY_CACHE_TIME, REACT_QUERY_STALE_TIME } from '../../constants/query-cache-time';
import { Image } from '../../types';
import { getContentApi } from '../../utils/api';

const BOOKING_UI_CONFIG_ID = getConfig().publicRuntimeConfig?.bookingUIConfigId;
if (!BOOKING_UI_CONFIG_ID) {
  throw Error(`Error: No bookingUIConfigId specified within publicRuntimeConfig,
  expecting something like 1234, the Umbraco value that folders node`);
}

export const asyncGetConfig = async () => {
  return await simpleFetch(`${getContentApi()}/settings/bookinguiconfig/${BOOKING_UI_CONFIG_ID}`);
};

export const ProductKeys = {
  ACCOM_ONLY: 'accommodation',
  BEACH_HOLIDAYS: 'beach-holidays',
  CITY_BREAKS: 'city-breaks',
  CAR_HIRE: 'car-hire',
  FLIGHTS: 'flights',
  CRUISE: 'cruise',
  COACH: 'coach',
} as const;
export type ProductKeys = (typeof ProductKeys)[keyof typeof ProductKeys];

export const ProductTypeName = {
  DYNAMIC_PACKAGE: 'DynamicPackage',
  ACCOMMODATION_ONLY: 'AccommodationOnly',
} as const;
export type ProductTypeName = (typeof ProductTypeName)[keyof typeof ProductTypeName];

export interface BookingJourneySearchFormProductItem {
  bookingJourneySearchItemName: string;
  productKey: ProductKeys;
}
export interface BookingJourneySearchFormConfig {
  bookingJourneySearchTitle?: string;
  bookingJourneySearchEnabled?: boolean;
  bookingJourneySearchItems?: BookingJourneySearchFormProductItem[];
}

export interface SearchWait {
  title: string;
  image: Image;
  text: string;
}

interface IQuoteTimeout {
  isEnabled: boolean;
  timeToBook: number;
}

export interface ProductConfig {
  endLocationTypes: Array<string>;
  key: ProductKeys;
  maxBookingDaysInFuture: number;
  maxDepartures?: number;
  quoteTimeout: IQuoteTimeout;
  searchWait: SearchWait;
  sortTags: Array<string>;
  startLocationTypes: Array<string>;
  title: string;
  type: {
    name: ProductTypeName;
  };
}

export interface Config {
  products: Array<ProductConfig>;
  pageDetails: {
    title: string;
    hasRoundPrices: boolean;
  };
  pageExtras: {
    title: string;
    hasRoundPrices: boolean;
  };
  pageSearchResults: {
    title: string;
    hasRoundPrices: boolean;
    isPerPersonPriceDisplayDefault: boolean;
  };
  pagePayment: {
    title: string;
    hasRoundPrices: boolean;
  };
  pageConfirmation: {
    title: string;
    hasRoundPrices: boolean;
  };
  pageBookingError: {
    title: string;
    hasRoundPrices: boolean;
  };
}

const useConfig = () => {
  const onError = (error: AxiosError) => {
    console.error(error);
  };

  const response = useQuery<Config, AxiosError>({
    queryKey: ['config'],
    queryFn: asyncGetConfig,
    staleTime: 1000 * REACT_QUERY_STALE_TIME,
    cacheTime: 1000 * REACT_QUERY_CACHE_TIME,
    onError,
  });

  const { data, refetch, remove, ...responseProps } = response;

  return {
    data,
    ...responseProps,
  };
};

export default useConfig;
