import { theme as base } from '@chakra-ui/react';

export const headingFont = `MuseoSansRounded, ${base.fonts?.heading}`;
export const headingFontColor = 'var(--global-heading-font-color)';
export const bodyFont = `MuseoSansRounded, ${base.fonts?.body}`;

export default {
  fonts: {
    heading: headingFont,
    body: bodyFont,
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 600,
    extrabold: 800,
    black: 900,
  },
};

export const h1 = '4xl';
export const h2 = ['2xl', null, null, null, '3xl'];
export const h3 = ['xl', null, null, null, '2xl'];
export const h4 = ['lg', null, null, null, 'xl'];
export const h5 = ['lg', null, null, null, 'lg'];
export const h6 = ['md', null, null, null, 'md'];
export const bodyLarge = ['md', null, null, null, 'md'];
export const bodyMedium = ['sm', null, null, null, 'sm'];
export const bodySmall = ['xs', null, null, null, 'xs'];
