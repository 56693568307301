import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import alertSvg from '../../icons/alertSvg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(['alertIcon']);

export default defineMultiStyleConfig({
  baseStyle: {
    alertIcon: {
      bg: svgUrl(alertSvg(colors.primary[500])),
    },
  },
});
