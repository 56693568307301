import { Z_INDEX } from '../../../../constants/zIndex';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowLeft from '../../icons/arrowLeft';
import arrowRight from '../../icons/arrowRight';
import galleryWithBackgroundSvg from '../../icons/galleryWithBackgroundSvg';
import markerSvg from '../../icons/marker';
import pinWithBackgroundSvg from '../../icons/pinWithBackgroundSvg';

export default {
  parts: ['selectedMarker', 'wrapper', 'imageContainer', 'flexContainer'],
  baseStyle: {
    wrapper: {
      bg: 'white',
      '.carousel-cell': {
        objectFit: 'cover',
        maxHeight: '100%',
      },
      '.carousel': {
        overflow: 'hidden',
        height: '100%',
        borderRight: 'none',
        ':focus-visible': {
          border: 'none',
        },
      },
      _active: {
        border: 'none',
      },
    },
    accommodationGalleryAndMapGrid: {
      borderRadius: 'lg',
      overflow: 'hidden',
    },
    imageContainer: {
      backgroundColor: 'gray.900',
      height: { base: '365px', md: '525px' },
      width: '100%',
      maxW: '985px',
    },
    carouselNav: {
      backgroundColor: 'white',
      height: '48px',
      borderRadius: '10em',
      width: '48px',
      _hover: {
        backgroundColor: 'primary.400',
        '.prevButton': {
          w: '14px',
          h: '14px',
          bg: svgUrl(arrowLeft('white')),
        },
        '.nextButton': {
          w: '14px',
          h: '14px',
          bg: svgUrl(arrowRight('white')),
        },
      },
      '.prevButton': {
        w: '14px',
        minW: '14px',
        h: '14px',
        bg: svgUrl(arrowLeft()),
      },
      '.nextButton': {
        w: '14px',
        minW: '14px',
        h: '14px',
        bg: svgUrl(arrowRight()),
      },
    },

    paginationWrapper: {
      bottom: { base: '10px', lg: '25px' },
      left: '50%',
      transform: 'translate(-50%, 0%)',
      zIndex: 1,
    },
    paginationBackground: {
      width: '95px',
      height: '42px',
      borderRadius: 'full',
      bg: 'white',
    },
    paginationText: {
      color: 'gray.700',
      fontWeight: 600,
      mt: 0,
    },
    mapPinIcon: {
      bg: svgUrl(pinWithBackgroundSvg(colors.gray[700], 'white')),
      borderRadius: '10px',
      width: '44px',
      height: '44px',
      zIndex: Z_INDEX.iconsAboveFlickityCarousel,
      bottom: '10px',
      left: '10px',
    },
    galleryIcon: {
      bg: svgUrl(galleryWithBackgroundSvg(colors.gray[700], 'white')),
      borderRadius: '10px',
      width: '44px',
      height: '44px',
      zIndex: Z_INDEX.iconsAboveFlickityCarousel,
      bottom: '10px',
      right: '10px',
    },
    mapTitle: {},
    mapBody: {
      padding: 0,
    },
    galleryBody: {
      padding: 0,
    },
    selectedMarker: {
      position: 'absolute',
      top: '-50px',
      left: '-29px',
      height: '75px',
      width: '58px',
      bg: svgUrl(markerSvg(colors.cyan)),
    },
  },
};
