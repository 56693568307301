import { getCookie } from 'inspiretec-booking-journey-ui/utils/cookies';
import cookies from '../../constants/cookies';

function deleteCookie(name: string, path = '/', domain?: string) {
  document.cookie =
    name +
    '=' +
    (path ? ';path=' + path : '') +
    (domain ? ';domain=' + domain : '') +
    ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
}

export function isLoggedIn(userSessionCookie?: string) {
  const userSession = userSessionCookie
    ? userSessionCookie
    : decodeURIComponent(getCookie(cookies.USER_SESSION) || '');
  if (userSession) {
    const { name, sessionId } = JSON.parse(userSession);
    return !!name && !!sessionId;
  }
  return false;
}

export function getUserSession() {
  const userSession = decodeURIComponent(getCookie(cookies.USER_SESSION) || '');
  if (userSession) {
    const { name, sessionId, agencyName } = JSON.parse(userSession);

    return {
      name,
      sessionId,
      agencyName,
    };
  }
  return undefined;
}

export function removeUserSession() {
  deleteCookie(cookies.USER_SESSION, '/');
}
