import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../utils/graphics';
import alertSvg from '../../icons/alertSvg';
import crossSvg from '../../icons/crossSvg';
import plusSvg from '../../icons/plusSvg';
import SearchControl from '../SearchControl';

export default {
  parts: ['addButtonIcon', 'footer', 'error', 'ActiveInput'],
  baseStyle: {
    addButtonIcon: {
      w: 4,
      h: 4,
      bgImage: ({ colors }: StyleFunctionProps) => svgUrl(plusSvg(colors.primary[500])),
    },
    crossIcon: {
      w: 4,
      h: 4,
      transform: 'scale(0.5)',
      bgImage: ({ colors }: StyleFunctionProps) => svgUrl(crossSvg(colors.primary[500])),
    },
    footer: SearchControl.baseStyle?.footer,
    error: {
      fontSize: 'sm',
      _before: {
        content: '""',
        w: 3,
        h: 3,
        mr: 1,
        bgImage: ({ colors }: StyleFunctionProps) => svgUrl(alertSvg(colors.error['500'])),
      },
    },
  },
};
