import { svgUrl } from '../../../../utils/graphics';
import searchSvg from '../../icons/searchSvg';
import SearchControl from '../SearchControl';

export const searchIcon = {
  display: 'inlineBlock',
  flexShrink: 0,
  h: '15px',
  w: '15px',
  mr: '10px',
  bg: svgUrl(searchSvg()),
  backgroundRepeat: 'no-repeat',
};

export const searchButton = {
  flexBasis: '12%',
  mt: { base: 1, md: 5, xl: 0 },
  ml: { base: 4, xl: 0 },
  px: '1rem',
  py: '25px',
  alignSelf: 'center',
  minW: '7rem',
  bg: 'primary.500',
  _hover: {
    bg: 'primary.600',
  },
  borderRadius: 'full',
};

export default {
  parts: [
    'outer',
    'inner',
    'searchIcon',
    'searchButton',
    'destination',
    'date',
    'duration',
    'guests',
  ],
  baseStyle: {
    inner: {
      flexWrap: 'wrap',
    },
    destination: {
      flexBasis: { base: '100%', md: '100%', xl: '25%' },
    },
    date: {
      flexBasis: { base: '50%', md: '33%', xl: '18%' },
    },
    duration: {
      flexBasis: { base: '50%', md: '33%', xl: '19%' },
    },
    guests: {
      flexBasis: { base: '100%', md: '33%', xl: '21%' },
    },
    searchIcon,
    searchButton: {
      ...searchButton,
      flexBasis: { base: '100%', md: '50%', lg: '33%', xl: '12%' },
    },
  },
  variants: {
    bookingJourney: {
      outer: {
        display: 'flex',
        flexDirection: 'column',
        p: { base: 4, lg: 10, xl: 0 },
        pb: { base: 4, lg: 6, xl: 0 }, // less because of footer nav
        pl: { base: 4, lg: 10, xl: 0 },
        h: '100%',
        bg: 'white',
      },
      bookingJourneyMobileFooter: SearchControl.baseStyle?.footer,
    },
  },
};
