export default {
  parts: ['carouselCta', 'carouselItemCta', 'module', 'tag'],
  baseStyle: {
    carouselCta: {},
    carouselItemCta: {
      bg: 'var(--global-generic-carousel-cta-background-color)',
      border: 'var(--global-generic-carousel-cta-border)',
    },
    module: {},
    tag: {
      '&:nth-of-type(odd)': {
        color: 'white',
        bgColor: 'primary.500',
      },
      '&:nth-of-type(even)': {
        color: 'primary.500',
        bgColor: 'white',
      },
    },
  },
};
