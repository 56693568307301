import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import crossSvg from '../../icons/crossSvg';
import flightSvg from '../../icons/flightSvg';
import timeFillSvg from '../../icons/timeFillSvg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'boardOptionsNumber',
  'flightIcon',
  'mapsWrapper',
  'markerCloseButton',
  'markerCloseButton',
  'noResultsContainer',
  'noResultsIcon',
  'noResultsText',
  'noResultsTitle',
  'outer',
  'paginationButton',
  'paginationOuter',
  'resultOuter',
  'resultsGrid',
  'resultsWrapper',
  'roomTypeNumber',
  'timeIcon',
]);

export default defineMultiStyleConfig({
  baseStyle: {
    noResultsIcon: {
      color: 'primary.500',
    },
    resultsWrapper: {
      mb: 'calc(var(--chakra-space-20) * -1)',
    },
    flightIcon: {
      bg: svgUrl(flightSvg(colors.primary['500'])),
      w: '17px',
      h: '17px',
      backgroundRepeat: 'no-repeat',
      transform: 'rotate(45deg) scale(0.9)',
      transformOrigin: 'center',
    },
    timeIcon: {
      bg: svgUrl(timeFillSvg(colors.primary['500'])),
      w: '29px',
      h: '29px',
      backgroundRepeat: 'no-repeat',
    },
    roomTypeNumber: {
      fontWeight: 400,
      color: 'var(--chakra-colors-chakra-body-text)',
    },
    boardOptionsNumber: {
      fontWeight: 400,
      color: 'var(--chakra-colors-chakra-body-text)',
    },
    markerCloseButton: {
      bg: 'white',
      _hover: {
        bg: 'primary.100',
      },
      _before: {
        content: '""',
        display: 'block',
        w: 14,
        h: 14,
        backgroundImage: ({ colors }: StyleFunctionProps) =>
          svgUrl(crossSvg(colors.primary['500'])),
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
  },
});
