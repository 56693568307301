export default {
  parts: ['alertTitle, redirectButton'],
  baseStyle: {
    alertTitle: {},
    redirectButtonVariant: 'outlineDark',
    redirectButtonOverride: {
      h: 9,
    },
  },
};
