import { StyleFunctionProps } from '@chakra-ui/react';
import { svgUrl } from '../../../../utils/graphics';
import crossSvg from '../../icons/crossSvg';

const desktopBreakpoint = 'md';

export default {
  baseStyle: {
    '& svg': {
      opacity: 0, // hide original svg
    },
    _before: {
      content: '""',
      display: 'block',
      w: 14,
      h: 14,
      backgroundImage: {
        base: ({ colors }: StyleFunctionProps) => svgUrl(crossSvg(colors.white)),
        [desktopBreakpoint]: ({ colors }: StyleFunctionProps) =>
          svgUrl(crossSvg(colors.primary['500'])),
      },
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    borderRadius: { base: 'full', [desktopBreakpoint]: 'var(--chakra-radii-md)' },
    bg: {
      base: 'primary.500',
      [desktopBreakpoint]: 'white',
    },
    _hover: {
      bg: {
        base: 'primary.500',
        [desktopBreakpoint]: 'primary.100',
      },
    },
  },
  sizes: {
    lg: {
      w: '44px',
      h: '44px',
    },
  },
  defaultProps: {},
};
