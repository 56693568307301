import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowRight from '../../icons/arrowRight';
export default {
  parts: ['moreRoomOptionsButtonsIcon', 'moreRoomOptionsButtonsIcon'],
  baseStyle: {
    viewAll: {
      w: 3,
      h: 3,
      ml: 3,
      backgroundImage: ({ colors }: StyleFunctionProps) =>
        svgUrl(arrowRight(colors.primary['500'])),
    },
    title: {
      fontWeight: '600',
    },
    rows: {
      base: {},
      md: {
        pt: 8,
        pb: 7,
        mb: 2,
        width: '100%',
        borderBottom: `1px solid ${colors.gray[200]}`,
      },
    },
    rowsNoBorder: {
      pt: 8,
      width: '100%',
    },
    facilitiesGroup: {
      pt: { base: 2, md: 0 },
      mt: { base: 4, md: 8 },
    },
    item: {
      lineHeight: {
        base: 3,
        md: 6,
      },
    },
  },
};
