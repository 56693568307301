import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { createPseudoBackgroundImageElement } from '../../../../utils/chakra-ui';
import globals from '../../config/globals';

const topBackgroundPanel = createPseudoBackgroundImageElement({
  bg: 'primary.50',
  zIndex: -1,
  pointerEvents: 'none',
});

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(['topPanel']);

export default defineMultiStyleConfig({
  baseStyle: {
    topPanel: {
      _after: {
        sm: {
          ...topBackgroundPanel,
          left: 'auto',
          right: `-${globals.gutter.base}`,
          width: '100vw',
        },
        md: {
          borderRadius: 'var(--chakra-radii-xl)',
          boxShadow: 'inset 0px 0px 0px 2px var(--chakra-colors-primary-500)',
          right: 0,
          width: '100%',
        },
      },
    },
  },
});
