import { svgUrl } from '../../../../utils/graphics';
import afternoonSvg from '../../icons/afternoon';
import arrowRight from '../../icons/arrowRight';
import earlyMorningSvg from '../../icons/earlyMorning';
import eveningSvg from '../../icons/evening';
import morningSvg from '../../icons/morning';

const iconStyle = {
  alignSelf: 'center',
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
};

const leftIconStyle = {
  mx: 1,
};

export default {
  parts: ['arrowDownIcon', 'arrowUpIcon', 'morning'],
  baseStyle: {
    openIcon: {
      ...iconStyle,
      w: '14px',
      h: '14px',
      bg: svgUrl(arrowRight()),
    },
    closeIcon: {
      ...iconStyle,
      w: '14px',
      h: '14px',
      bg: svgUrl(arrowRight()),
      transform: 'rotate(180deg)',
    },
    iconContainer: {
      w: '35px',
      mr: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    morning: {
      ...iconStyle,
      ...leftIconStyle,
      w: '26px',
      h: '26px',
      bg: svgUrl(morningSvg()),
    },
    afternoon: {
      ...iconStyle,
      ...leftIconStyle,
      w: '27px',
      h: '17px',
      bg: svgUrl(afternoonSvg()),
    },
    evening: {
      ...iconStyle,
      ...leftIconStyle,
      w: '17px',
      h: '18px',
      bg: svgUrl(eveningSvg()),
    },
    earlyMorning: {
      ...iconStyle,
      ...leftIconStyle,
      w: '27px',
      h: '17px',
      bg: svgUrl(earlyMorningSvg()),
    },
  },
};
