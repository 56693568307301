import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
export default {
  parts: ['arrowUp', 'arrowDown', 'text'],
  baseStyle: {
    arrowUp: {
      h: '6px',
      w: '10px',
      bg: svgUrl(arrowUp(colors.gray[700])),
    },
    arrowDown: {
      h: '6px',
      w: '10px',
      bg: svgUrl(arrowDown(colors.gray[700])),
    },
    text: {
      lineHeight: 6,
    },
    subTitle: {
      fontSize: '24px',
      fontWeight: 600,
    },
    readmore: {
      _focus: {
        boxShadow: 'none',
        outline: 'none',
      },
    },
    readmoreText: {
      fontWeight: 'bold',
      mt: 0,
      mr: 1,
      color: colors.primary[500],
    },
  },
};
