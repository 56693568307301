export default {
  parts: ['wrapper', 'stepper', 'childAgeStack'],
  baseStyle: {
    stepper: {
      borderTopWidth: '1px',
      _first: {
        borderTopWidth: 0,
      },
    },
    childAgeStack: {
      borderTopWidth: '1px',
    },
  },
};
