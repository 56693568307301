import Typography, { h1, h2, h3, headingFontColor } from './config/typography';

export default {
  '.RichText': {
    lineHeight: '25px',
    color: headingFontColor,
    h1: {
      fontSize: h1,
      fontWeight: Typography.fontWeights.semibold,
      '&:not(:last-child)': {
        mb: 7,
      },
    },
    h2: {
      fontSize: h2,
      fontWeight: Typography.fontWeights.semibold,
      '&:not(:last-child)': {
        mb: 6,
      },
    },
    h3: {
      fontSize: h3,
      fontWeight: Typography.fontWeights.semibold,
      '&:not(:last-child)': {
        mb: 6,
      },
    },
    p: {
      '&:not(:last-child)': {
        mb: 6,
      },
    },
    a: {
      textDecoration: 'underline',
      '&:not(:last-child)': {
        mb: 6,
      },
    },
    ul: {
      pl: 4,
      '&:not(:last-child)': {
        mb: 4,
      },
      ul: {
        pl: 8, // Apply additional padding for first level of nested lists
      },
      'ul ul': {
        pl: 12, // Apply additional padding for second level of nested lists
      },
    },
    ol: {
      pl: 4,
      '&:not(:last-child)': {
        mb: 4,
      },
      ol: {
        pl: 8, // Apply additional padding for first level of nested lists
      },
      'ol ol': {
        pl: 12, // Apply additional padding for second level of nested lists
      },
    },
  },
};
