import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { svgUrl } from '../../../../utils/graphics';
import plusSvg from '../../icons/plusSvg';

export default {
  parts: ['wrapper', 'title', 'removeIcon'],
  baseStyle: {
    wrapper: {},
    removeIcon: {
      alignSelf: 'center',
      w: '1em',
      h: '1em',
      mr: '0.5em',
      opacity: 0.75,
      transform: 'rotate(45deg)',
      bg: ({ colors }: StyleFunctionProps) => svgUrl(plusSvg(colors.primary['500'])),
    },
  },
};
