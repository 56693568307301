export default {
  baseStyle: {
    field: {
      _hover: {
        cursor: 'pointer',
      },
    },
    icon: {
      color: 'primary.500',
    },
  },
  variants: {
    leftIcon: {
      color: 'primary.500',
      borderRadius: '5px',
      borderColor: 'primary.500',
      field: {
        paddingInlineStart: 8,
        paddingInlineEnd: 3,
      },
      icon: {
        right: undefined,
        left: 2,
        color: 'primary.500',
      },
    },
  },
};
