import {
  default as selectedTheme,
  font as selectedFont,
  PageTheme as selectedPageTheme,
} from './coop';

export default selectedTheme;

export const font = selectedFont;

export const PageTheme = selectedPageTheme;
export type PageTheme = (typeof PageTheme)[keyof typeof PageTheme];
