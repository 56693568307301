import { svgUrl } from '../../../../utils/graphics';

const arrowSvg = `<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 14L8.4 12.6L3.8 8L20 8L20 6L3.8 6L8.4 1.4L7 -1.1365e-06L6.11959e-07 7L7 14Z" fill="black"/></svg>`;
const chevronSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M10.828 11.9997L15.778 16.9497L14.364 18.3637L8 11.9997L14.364 5.63574L15.778 7.04974L10.828 11.9997Z" fill="black"/></svg>`;

export default {
  baseStyle: {
    position: 'relative',
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      background: 'white',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  variants: {
    arrowLarge: {
      width: '48px',
      height: '48px',
      _after: {
        backgroundImage: svgUrl(arrowSvg),
      },
    },
    arrowSmall: {
      width: '40px',
      height: '40px',
      _after: {
        backgroundImage: svgUrl(arrowSvg),
      },
    },
    chevron: {
      width: '24px',
      height: '24px',
      _after: {
        backgroundImage: svgUrl(chevronSvg),
      },
    },
  },
};
