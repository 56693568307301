import { svgUrl } from '../../../../utils/graphics';
import colors from '../../config/colors';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import infoTooltip from '../../icons/infoFillTooltipSvg';

export default {
  parts: [],
  baseStyle: {
    wrapper: {
      borderColor: 'primary.100',
      borderWidth: 'thin',
      borderRadius: '10px',
    },
    alertBox: {
      bgColor: 'primary.500',
      borderTopRadius: '10px',
    },
    alertDescription: {
      color: 'white',
      fontSize: 'sm',
    },
    errataDescription: {
      color: 'secondary.500',
      lineHeight: 1.5,
      fontSize: 'md',
    },
    confirmMessage: {
      lineHeight: 1.5,
      fontSize: 'md',
      color: 'secondary.500',
    },
    validationText: {
      color: 'error.500',
      fontSize: 'sm',
    },
    infoIcon: {
      h: '23px',
      w: '23px',
      backgroundImage: svgUrl(infoTooltip('white')),
    },
    viewMoreBtn: {
      color: 'primary.500',
    },
    collapseIcon: {
      h: '5px',
      w: '8px',
      backgroundImage: svgUrl(arrowUp(colors.primary[500])),
    },
    expandIcon: {
      h: '5px',
      w: '8px',
      backgroundImage: svgUrl(arrowDown(colors.primary[500])),
    },
    dividerBorderBottom: {
      borderBottomWidth: '1px',
      borderColor: 'primary.100',
    },
  },
};
