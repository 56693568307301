import { ComponentStyleConfig } from '@chakra-ui/react';

const SearchOverlay: ComponentStyleConfig = {
  parts: ['underlayPosition', 'underlayTransition', 'underlay'],
  baseStyle: {
    underlayPosition: {
      position: 'absolute',
      left: 0,
      h: 0,
      maxWidth: '100%',
      zIndex: 'calc(var(--chakra-zIndices-popover) - 1)',
    },
    underlayTransition: {
      opacity: '1',
      transform: 'scale(1)',
    },
    underlay: {
      width: '100vw',
      maxWidth: '100%',
      bg: 'rgba(45,55,72,0.3)', // as per designs
    },
  },
  variants: {
    on: (props) => ({
      underlayTransition: {
        opacity: '1',
        transform: 'scale(1)',
        transition: `opacity ${props.theme.transitions.searchExpand}s ${props.theme.transitions.searchExpandEase}s 0s, transform 0s linear 0s`,
      },
    }),
    off: (props) => ({
      underlayTransition: {
        opacity: '0',
        transform: 'scale(0)',
        transition: `opacity ${props.theme.transitions.searchCollapse}s ${props.theme.transitions.searchCollapseEase}s 0s, transform 0s linear ${props.theme.transitions.searchCollapse}s`,
      },
    }),
    offDelay: (props) => ({
      underlayTransition: {
        opacity: '0',
        transform: 'scale(0)',
        transition: `opacity ${props.theme.transitions.searchCollapse}s ${
          props.theme.transitions.searchCollapseEase
        }s 0.1s, transform 0s linear ${props.theme.transitions.searchCollapse + 0.1}s`,
      },
    }),
  },
};

export default SearchOverlay;
